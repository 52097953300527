import { createContext, useContext, useEffect, useState } from "react";
import { auth, firestore, storage } from "../services/firebase/accounts";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { SettingsContext } from "./Settings";
import { getDownloadURL, ref } from "firebase/storage";
import { adsFirestore } from "../services/firebase/ads";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [ user, setUser ] = useState(null);
    const [ refreshing, setRefreshing ] = useState(true);
    const [ adsLength, setAdsLength ] = useState(null);
    const [ userLoading, setUserLoading ] = useState(false);
    const { notAllowAdult } = useContext(SettingsContext);

    function getAge(date){
        if (date) {
            const today = new Date().getTime();

            const diff = today - date;
            const ageNumber = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));

            return ageNumber;
        }
    }
    
    async function getUserData(uid, userArr) {
        setUserLoading(true);
        const userRef = doc(firestore, `usuarios/${uid}`);
        const userData = await getDoc(userRef);

        const data = userData.data();

        const arr = userArr;

        if (data?.badges) {
            arr.badges = data.badges;
            arr.private = data.badges.includes("private");
        }else {
            arr.badges = [];
            arr.private = true;
        }

        if (data.name) {
            arr.name = data.name[0];
            arr.displayName = data.name[1];
        } else {
            arr.name = null;
            arr.displayName = null;
        }
        

        if (userData.exists()) {
            const userDataRef = doc(firestore, `usuarios/${uid}/settings/account`);
            const userDataSnap = await getDoc(userDataRef);

            if(userDataSnap.exists()){
                const data = userDataSnap.data().data[0];

                const birthday = data.birthday;

                if(birthday){
                    arr.birthday = birthday;
                    arr.age = getAge(birthday);
                    getBackground(arr);
                    if (arr.age < 18) {
                        notAllowAdult();
                    }
                } else {
                    getBackground(arr);
                    notAllowAdult();
                }
            } else {
                getBackground(arr);
                notAllowAdult();
            }
        }

        function getBackground(arr) {
            const newArr = arr;
            if (newArr.badges.includes("premium")) {
                const backgroundOldRef = ref(storage, `usuarios/${uid}/pfb.jpg`);
                const backgroundRef = ref(storage, `usuarios/${uid}/pfb`);
                getDownloadURL(backgroundRef).then((url) => {
                    newArr.pfb = url;
                    setUser(newArr);
                    setUserLoading(false);
                }).catch(() => {
                    getDownloadURL(backgroundOldRef).then((url) => {
                        newArr.pfb = url;
                        setUser(newArr);
                        setUserLoading(false);
                    }).catch(() => {
                        newArr.pfb = null;
                        setUser(newArr);
                        setUserLoading(false);
                    })
                })
            } else {
                newArr.pfb = null;
                setUser(newArr);
                setUserLoading(false);
            }
        }
        setRefreshing(false);
    }

    useEffect(() => {
        async function getAdsLength() {
            if (adsLength) return;
            
            const adsRef = doc(adsFirestore, `ads/settings`);
            const adsSnap = await getDoc(adsRef);

            setAdsLength(adsSnap.data().length);
        }

        onAuthStateChanged(auth, (user) => {
            if (user) {
                getUserData(user.uid, user).catch((err) => {
                    /* if (err.message === "Failed to get document because the client is offline."){

                    } */
                })
                getAdsLength();
            } else {
                setRefreshing(false);
                setUser(undefined);
                getAdsLength();
            }
        })
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                adsLength,
                userLoading,
                setUser,
                getUserData,
                refreshing,
                setRefreshing
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}