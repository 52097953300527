import React, { useContext, useEffect, useState } from 'react';
import { BuyPremiumContext } from '../../../context/BuyPremium';
import AoIconButton from '../../buttons/IconButton';
import { ArrowBack, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AoButton from '../../buttons/Button';
import { Button, CircularProgress } from '@mui/material';
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../../services/firebase/accounts';
import PremiumLogo from '../../../images/Premium';
import {
	PayPalButtons,
	PayPalScriptProvider,
} from "@paypal/react-paypal-js";
import { AuthContext } from '../../../context/Auth';
import i18n from '../../../services/i18n';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { PayPalButtonWrapper } from './methods'

const PaymentMethods = ({ doc, setLoading, setMethod }) => {
    const { t } = useTranslation(); 

    const handlePaymentMethod = (value) => {
        setLoading(true);
        setTimeout(() => {
            setMethod(value);
            setLoading(false);
        }, 500)
    }

    return (
        <div className='payment-methods'>
            <div className='plan-price'>
                <span className="price">{doc.price}</span>
                <span className="currency">{t(`${doc.currency}$`)}</span>
                <span className="period">{`/${t(`premium.plans.period.${doc.period}`)}`}</span>
            </div>
            <div className="divider with-o">
                <span>Método de pago</span>
            </div>
            <div className="methods">
                <Button onClick={() => handlePaymentMethod('paypal')} className='button highlight left'>
                    <div className="button-icon">
                        <img src="https://www.paypalobjects.com/webstatic/icon/pp258.png" />
                    </div>
                    <div className="button-title">PayPal</div>
                </Button>
            </div>
        </div>
    )
}

const Plans = ({ setLoading, selectPlan }) => {
    const { t } = useTranslation();
    const [ plans, setPlans ] = useState([]);

    useEffect(() => {
        let ignore = false;
    
        async function getPlans() {
          const plansRef = doc(firestore, `premium/plans`);
          const plansSnap = await getDoc(plansRef);
    
          setPlans(plansSnap.data().data);
          setLoading(false);
        }
    
        if (!ignore) {
          if (plans.length < 1) {
            getPlans();
          }
        }
    
        return () => ignore = true;
      }, []);

    return(
        <div className='plans'>
            {plans.map((doc, i) => (
              <Button className="premium-plans_card" onClick={() => selectPlan(doc)} key={i}>
                <div className="premium-plans_card-background">
                  <div className="icon">
                    <PremiumLogo />
                  </div>
                  <div className='bg' style={{backgroundImage: `url(${doc.banner})`}}></div>
                </div>
                <div className="premium-plans_card-header">
                  <h3 className="plan-name">{t(`premium.plans.${doc.name}.name`)}</h3>
                  <div className='plan-price'>
                    <span className="price">{doc.price}</span>
                    <span className="currency">{t(`${doc.currency}$`)}</span>
                    <span className="period">{`/${t(`premium.plans.period.${doc.period}`)}`}</span>
                  </div>
                  <div className="premium-plans_card-footer">
                    <span>{t(`premium.plans.${doc.name}.desc`)}</span>
                  </div>
                </div>
              </Button>
            ))}
        </div>
    )
}

export function BuyPremium() {
    const { t } = useTranslation(); 
    const { user, getUserData } = useContext(AuthContext);
    const { plan, loading, setLoading, modalState, selectPlan, closeModal } = useContext(BuyPremiumContext);
    const [ method, setMethod ] = useState('');
    const [ payState, setPayState ] = useState(null);
    const [ expireDate, setExpireDate ] = useState('');

    const setSuccess = (date) => {
        setTimeout(() => {
            setExpireDate(date);
            setPayState(true);
            setLoading(false);
        }, 200)
    }

    const handleSuccessContinue = () => {
        getUserData(user.uid, user);
        closeModal();
    }

    const handleCancelPayment = () => {
        setLoading(true);
        setTimeout(() => {
            setMethod('');
            setPayState(null);
            setExpireDate('');
            setLoading(false);
        }, 500);
    }

    return (
        <div className={`modal ${modalState} ${loading && 'loading'}`}>
            <div className="backdrop true"></div>
            <div className={`modal-layout buy-premium`}>
                <div className="modal-title">
                    <h3>
                        {payState === null && t('modal.premium.title.normal')}
                        {payState === false && t('modal.premium.title.error')}
                        {payState && t('modal.premium.title.success')}
                    </h3>
                    <AoIconButton 
                        action={closeModal}
                        content={<Close />}
                    />
                </div>
                <div className="modal-body">
                    <div className={`loading progress ${loading && 'show'}`}>
                        <CircularProgress className='progress-circular normal' />
                    </div>
                    {payState === null &&
                        <>
                            {method === "" &&
                                <>    
                                    {plan &&
                                        <>
                                            <AoButton 
                                                icon={<ArrowBack />}
                                                title={t(`premium.plans.${plan.name}.name`)}
                                                variant={"left uppercase"}
                                                action={() => selectPlan(null)}
                                                style={{ padding: '.75rem .5rem'}}
                                            />
                                            <div className="divider"></div>
                                        </>
                                    }
                                </>
                            }
                            <div className="modal-body_content">
                                {method !== "" ? 
                                    <div className={`payment-method`}>
                                        <div className="paypal">
                                            {method === "paypal" && 
                                                <PayPalButtonWrapper plan={plan} setPayState={setPayState} setLoading={setLoading} setSuccess={setSuccess} />
                                            }
                                        </div>
                                        <div className="buttons-layout">
                                            <AoButton 
                                                variant={"outline"}
                                                size={"big"}
                                                action={handleCancelPayment}
                                                title={t('words.cancel')}
                                            />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        {plan === false && <Plans setLoading={setLoading} selectPlan={selectPlan} />}
                                        {plan && <PaymentMethods setMethod={setMethod} setLoading={setLoading} doc={plan} />}
                                    </>
                                }
                            </div>
                        </>
                    }
                    {payState === false &&
                        <div className="modal-body_content">
                            <div className="message flex justify-center align-center">
                                <h2 style={{ textAlign: 'center'}}>{t('modal.premium.error.p1')}</h2>
                            </div>
                            <div className="buttons-layout">
                                <AoButton 
                                    variant={"special-2"}
                                    size={"big"}
                                    title={t('modal.premium.error.btn')}
                                    action={handleCancelPayment}
                                />
                            </div>
                        </div>
                    }
                    {payState &&
                        <div className="modal-body_content">
                            <div className="message">
                                <p>{t('modal.premium.success.p1')}</p>
                                <p>{t('modal.premium.success.p2')}</p>
                                <div className='expire-date'>{new Intl.DateTimeFormat(i18n.language, { day: 'numeric', month: 'long', year: 'numeric'}).format(expireDate)}</div>
                                <div className='image'>
                                    <LazyLoadImage 
                                        src='https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fsuccess.webp?alt=media&token=6acbc50a-f687-4ffd-a10d-cf48e84750e2'
                                        effect='blur'
                                    />
                                </div>
                            </div>
                            <div className="buttons-layout">
                                <AoButton 
                                    variant={"special-2"}
                                    size={"big"}
                                    title={t('modal.premium.success.btn')}
                                    action={handleSuccessContinue}
                                />
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
