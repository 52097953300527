import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Collapse, useMediaQuery } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import AoButton from '../buttons/Button';
import Modal from '../modals/Modal';
import { ModalsContext } from '../../context/Modals';

const SelectorBody = ({ 
    data, 
    currentVideo, 
    setCurrentVideo,
    setLoading
 }) => {
    const { t } = useTranslation();
    const { openOnlyPremium } = useContext(ModalsContext);
    const [checked, setChecked] = useState(false);
    const [ modal, setModal ] = useState(false);
    const [ modalState, setModalState ] = useState(null);
    const media = useMediaQuery('(max-width: 50em)');

    function openModal(){
        setModal(true);
        setTimeout(() => {
            setModalState('show');
        }, 100)
    };

    function closeModal(){
        setModalState(null);
        setTimeout(() => {
            setModal(false);
        }, 100)
    };

    const handleChange = () => {
        if (media) {
            openModal();
        } else {
            setChecked((prev) => !prev);
        }
    }

    const handleCurrentVideo = (value) => {
        if (currentVideo === value) {
            return;
        }
        setCurrentVideo(value);
        setLoading(true);
    }

    return (
        <>
            <div className={`selector-layout__header ${checked}`}>
                <Button
                    onClick={handleChange}
                    className='button'
                >
                    <h4>{t(`categories.langs.${data.type}-long`)}</h4>
                    <ChevronRight />
                </Button>                     
            </div>
            {media ? 
            <>
                {modal &&
                    <Modal
                        title={`[${t(`categories.langs.${data.type}`)}] ${t('words.players')}`}
                        visible={modalState}
                        close={closeModal}
                    >
                        {(data.premium || data.premium === null) &&
                            <AoButton 
                                type={"select"}
                                className={`theme ${currentVideo === data.premium && 'active'}`}
                                action={() => {
                                    if (data.premium === null) {
                                        openOnlyPremium();
                                    } else {
                                        handleCurrentVideo(data.premium);
                                    }
                                }}
                                title={t('words.premium')}
                                disabled={data.premium === null ? true : false}
                            />
                        }
                        {data.others &&
                            <>
                                {data.others.map((reproductor, i) => {
                                    const url = reproductor;
                                    const match = reproductor.replace(/.+\/\/|www.|\..+/g, '');
                                    return (
                                        <AoButton 
                                            key={i}
                                            type={"select"}
                                            className={`${currentVideo === url && 'active'}`}
                                            action={() => handleCurrentVideo(url)}
                                            title={match}
                                        />
                                    )
                                })}
                            </>
                        }
                    </Modal>
                }
            </>
            : 
            <Collapse in={checked} className="selector-layout__body">
                {(data.premium || data.premium === null) &&
                    <AoButton 
                        type={"select"}
                        className={`theme ${currentVideo === data.premium && 'active'}`}
                        action={() => {
                            if (data.premium === null) {
                                openOnlyPremium();
                            } else {
                                handleCurrentVideo(data.premium);
                            }
                        }}
                        title={t('words.premium')}
                        disabled={data.premium === null ? true : false}
                    />
                }
                {data.others &&
                    <>
                        {data.others.map((reproductor, i) => {
                            const url = reproductor;
                            const match = reproductor.replace(/.+\/\/|www.|\..+/g, '');
                            return (
                                <AoButton 
                                    key={i}
                                    type={"select"}
                                    className={`${currentVideo === url && 'active'}`}
                                    action={() => handleCurrentVideo(url)}
                                    title={match}
                                />
                            )
                        })}
                    </>
                }
            </Collapse>}
        </>
    ) 
}

export default function ReproductorSelector({ 
    reproductors, 
    currentVideo, 
    setCurrentVideo,
    setLoading
}) {
    const { t } = useTranslation();
    if (reproductors.length < 1) {
        return;
    }

    return (
        <div className='selector-component'>
            <div className="selector-title">
                <h3>{t('words.players')}</h3>
            </div>
            <div className="selector">
                {reproductors.map((doc, i) => (
                    <div key={i}>
                        <div className='selector-layout'>
                            <SelectorBody 
                                currentVideo={currentVideo} 
                                setCurrentVideo={setCurrentVideo} 
                                data={doc} 
                                setLoading={setLoading}    
                            />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
