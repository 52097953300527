import { Bookmarks, BookmarksOutlined, Cottage, CottageOutlined, Explore, ExploreOutlined, Search } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import SearchIcon from '../../images/Search'
import SearchFilledIcon from '../../images/SearchFilled'
import { useTranslation } from 'react-i18next'
import Modal from '../modals/Modal'
import animeGenres from '../../services/animeGenres'
import AoButton from '../buttons/Button'

export default function Tabs() {
  const { t } = useTranslation();

  const [ explore, setExplore ] = useState(false);
  const [ exploreState, setExploreState ] = useState(null);

  const openExplore = () => {
    setExplore(true);
    setTimeout(() => {
      setExploreState('show');
    }, 100)
  }

  const closeExplore =  () => {
    setExploreState(null);
    setTimeout(() => {
      setExplore(false);
    }, 200)
  }

  return (
    <>
      <div className='nav-tabs'>
        <div className="nav-tabs-layout">
          <nav className="nav-tabs-body">
            <IconButton
              component={NavLink}
              to="/"
              className='nav-button'
            >
              <div className="icon">
                <Cottage className='filled' />
                <CottageOutlined className='outlined' />
              </div>
              <div className='name'>{t('header.home')}</div>
            </IconButton>
            <IconButton
              component={NavLink}
              to="/search"
              className='nav-button'
            >
              <div className="icon">
                <SearchFilledIcon className="filled" />
                <SearchIcon className="outlined" />
              </div>
              <div className="name">{t('header.search')}</div>
            </IconButton>
            <IconButton
              className='nav-button'
              onClick={openExplore}
            >
              <div className="icon">
                <Explore className="filled" />
                <ExploreOutlined className="outlined" />
              </div>
              <div className="name">{t('header.explore')}</div>
            </IconButton>
            {/* <IconButton
              component={NavLink}
              to="/anime"
              className='nav-button'
            >
              <div className="icon">
                <Bookmarks className='filled' />
                <BookmarksOutlined className='outlined' />
              </div>
              <div className='name'>Biblioteca</div>
            </IconButton> */}
          </nav>
        </div>
      </div>
      {explore &&
        <Modal
          visible={exploreState}
          close={closeExplore}
          title={t('header.explore')}
        >
          <div
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              gridTemplateRows: `repeat(${Math.round(animeGenres.length / 2)}, 1fr)`
            }}
          >
            {animeGenres.map((genre, i) => (
              <AoButton 
                key={i}
                title={t(`categories.genres.${genre.id}`)}
                icon={genre.icon}
                type={"link"}
                variant={"left"}
                to={`/genres/${genre.id}`}
                alt={t(`categories.genres.${genre.id}`)}
                action={closeExplore}
                style={{
                  width: '100%',
                  padding: '.5rem'
                }}
              />
            ))}
          </div>
        </Modal>
      }
    </>
  )
}
