import { ChevronRight } from '@mui/icons-material';
import { Button, CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function AoButton(
        { 
            title, 
            description, 
            action, 
            className, 
            type, 
            icon, 
            variant, 
            to, 
            alt, 
            style,
            hasRightArrow,
            flag,
            langCode,
            disabled,
            loading,
            size,
            submit,
            premium
        }
    ) {
    const { t } = useTranslation();
    const [ hasIcon, setIcon ] = useState(false);

    useEffect(() => {
        if (icon) {
            setIcon(true);
        } else {
            setIcon(false);
        }
    }, [icon]);

    if (type === "select" || type === "toggle") {
        return(
            <Button 
                className={`button ${variant} size-${size} ${type} ${className} ${description && 'description'} ${hasIcon && 'icon'}`} 
                alt={title}
                title={alt}
                style={style}
                onClick={action}
                component={type === "link" ? Link : null}
                to={to}
                disabled={disabled}
            >
                <div className='content'>
                    {type === "select" &&
                        <>
                            {variant === "lang" &&
                                <div className="toggle">
                                    <div className='circle'></div>
                                </div>
                            }    
                            {!variant &&
                                <div className="toggle">
                                    <div className='circle'></div>
                                </div>
                            }
                        </>
                    }
                    {hasIcon && <div className='button-icon'>{icon}</div>}
                    {title || description ?
                        <div className='button-text'>
                            {title && <span className='title'>{title}</span>}
                            {description && <span className='description'>{description}</span>}
                        </div>:null
                    }
                    {type === "select" && variant === "theme" &&
                        <div className="toggle">
                            <div className='circle'></div>
                        </div>
                    }
                </div>
                {variant === "lang" &&
                    <div className='visual-help'>
                        <span>{t(`lang.${langCode}`)}</span>
                        <img src={flag} alt={t(`lang.${langCode}`)} />
                    </div>
                }
                {type === "toggle" &&
                    <div className="toggle">
                        <div className='circle'></div>
                    </div>
                }
            </Button>
        )
    }

    return (
        <Button 
            className={`button ${variant} size-${size} ${hasRightArrow && 'right-arrow'} ${!loading ? '' : 'with-loading'} ${className} ${description && 'description'} ${hasIcon && 'icon'}`} 
            alt={title}
            title={alt}
            style={style}
            onClick={submit ? undefined : action}
            component={type === "link" ? Link : null}
            to={to}
            disabled={disabled}
            type={submit ? 'submit' : 'button'}
        >
            {hasIcon && <div className='button-icon'>{icon}</div>}
            {loading &&
                <div className="progress">
                    <CircularProgress className='progress-circular' />
                </div>
            }
            
            <>
                {title || description ?
                    <div className='button-text'>
                        {title && <span className='title'>{title}</span>}
                        {description && <span className='description'>{description}</span>}
                    </div> :null
                }
            </>
            {premium && <div className='premium'>{t('words.premium')}</div>}
            {hasRightArrow && <div className="right-arrow"><ChevronRight /></div>}
        </Button>
    )
}
