import React from 'react'
import AoButton from '../../../components/buttons/Button'
import { useTranslation } from 'react-i18next'
import { Flag, HowToVote, TipsAndUpdates } from '@mui/icons-material';
import SEO from '../../../services/seo';

export default function Community() {
    const { t } = useTranslation();
  return (
    <>
        <SEO title={`${t('settings.nav.community')}`} />
        <AoButton 
            variant={"left"}
            icon={<TipsAndUpdates />}
            title={t('settings.nav.suggest')}
            description={t('settings.nav.suggest')}
            type={"link"}
            to="suggest"
            hasRightArrow={true}
        />
        <AoButton 
            variant={"left"}
            icon={<Flag />}
            title={t('settings.nav.report')}
            description={t('settings.nav.report-desc')}
            type={"link"}
            to="report"
            hasRightArrow={true}
        />
        <AoButton 
            variant={"left"}
            icon={<HowToVote />}
            title={t('settings.nav.request')}
            description={t('settings.nav.request-desc')}
            type={"link"}
            to="request"
            hasRightArrow={true}
        />
    </>
  )
}
