import React, { useContext, useEffect, useState } from 'react';
import Reproductor, { Message } from '../../components/reproductor/Reproductor';
import './Reproductor.scss';
import { Menu } from '@mui/material';
import AoButton from '../../components/buttons/Button';
import { Circle, Flag, IosShare, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ModalsContext } from '../../context/Modals';
import i18n from '../../services/i18n';
import { doc, getDoc } from 'firebase/firestore';
import AoIconButton from '../../components/buttons/IconButton';
import Comments from '../../components/actions/Comments';
import AdsTerra from '../../components/ads/AdsTerra';
import ChangeEpisode from '../../components/reproductor/ChangeEpisode';
import ReproductorSelector from '../../components/reproductor/ReproductorSelector';
import Modal from '../../components/modals/Modal';
import ReportEpisode from '../../components/modals/ReportEpisode';
import SEO from '../../services/seo';
import { SettingsContext } from '../../context/Settings';

export default function Watch() {
    const { t } = useTranslation();
    const { theme } = useContext(SettingsContext);
    const { openShare, reportEpisode, reportEpisodeState, openReportEpisode, closeReportEpisode } = useContext(ModalsContext);
    const params = useParams();

    const [ viewed, setViewed ] = useState(false);

    const [ serie, setSerie ] = useState(params.id.split("-")[0]);
    const [ season, setSeason ] = useState(params.id.split("-")[1]);
    const [ episode, setEpisode ] = useState(params.id.split("-")[2]);
    const [ seasonData, setSeasonData ] = useState([]);
    const [ seasonInfo, setSeasonInfo ] = useState([]);
    const [ episodeData, setEpisodeData ] = useState([]);
    const [ reproductors, setReproductors ] = useState([]);
    const [ currentVideo, setCurrentVideo ] = useState("");
    const [ loading, setLoading ] = useState(true);
    const [ loadingData, setLoadingData ] = useState(true);
    const [ loadingCaps, setLoadingCaps ] = useState(true);

    const [ contextMenu, setContextMenu ] = useState(null);
    const [ loadingComments, setLoadingComments ] = useState(false);

    const [ error, setError ] = useState(false);


    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :null
        );
    }

    const handleClose = () => {
        setContextMenu(null);
    }

    const handleViewed = () => {
        const storage = localStorage.getItem(`watch-list-${serie}`);
        if (viewed === 'true') {
            const arr = JSON.parse(storage);
            const storageSize = Object.keys(JSON.parse(storage)).length;
            if (storageSize > 1) {
                let newArr = arr;
                delete newArr[`${season}-${episode}`];
                
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(newArr));
                setViewed('false');
            } else {
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify({}));
                setViewed('false');
            }
        } else {
            if (storage) {
                let arr = JSON.parse(storage);
                arr[`${season}-${episode}`] = 'true';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr));
                setViewed('true');
            } else {
                setViewed('true');
                let arr = {};
                arr[`${season}-${episode}`] = 'true';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr))
            }
        }
    }

    function getCapsList(withoutCache) {
        let lang = i18n.language;

        const now = new Date();

        function isValid(response) {
            if (!response) return false;
            var fetched = response.headers.get('expire');
            if (fetched > now.getTime()) return true;
        }

        async function getCaps(db, cache) {
            const animeRef = doc(db.animeFirestore, `animes/${serie}/seasons/${season}`);
            const animeSnap = await getDoc(animeRef);

            if (animeSnap.exists()) {
                const data = animeSnap.data().episodes_list;
                const sorted = data.sort(function(a, b) {
                    return a.id - b.id
                })
                
                setEpisodeData(sorted);
                setLoadingCaps(false);
                
                if (cache) {
                    const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 7));

                    var headers = new Headers();
                    headers.append('expire', expirationTime.getTime());
    
                    cache.put(`/${serie}/seasons/${season}`, new Response (JSON.stringify(data), {
                        headers: headers
                    }));
                }
            } else {
                setError(true);
                setLoadingData(false);
            }

        }
                
        if (i18n.language === "es" || i18n.language === "en") {
            lang = i18n.language;
        } else {
            lang = "en";
        }

        if ('caches' in window) {
            caches.open(`animes_${lang}`)
            .then((cache) => {
                cache.match(`/${serie}/seasons/${season}`)
                .then(async (response) => {
                    if (withoutCache) {
                        import(`../../services/firebase/anime_${lang}`)
                        .then((db) => {
                            getCaps(db, cache);
                        })
                    } else {
                        if (isValid(response)) {
                            var data = await response.json();
                            setEpisodeData(data);
                            setLoadingCaps(false);
                        } else {
                            import(`../../services/firebase/anime_${lang}`)
                            .then((db) => {
                                getCaps(db, cache);
                            })
                        }
                    }
                })
            })
        } else {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                getCaps(db);
            })
        }
    }

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            const now = new Date();

            function isValid(response) {
                if (!response) return false;
                var fetched = response.headers.get('expire');
                if (fetched > now.getTime()) return true;
            }

            async function getAnime(db, cache) {
                const animeRef = doc(db.animeFirestore, `animes/${serie}/seasons/info`);
                const animeSnap = await getDoc(animeRef);

                if (animeSnap.exists()) {
                    const data = animeSnap.data().data;
                    const sorted = data.sort((a,b) => a.id > b.id ? 1 : -1);
                    
                    setSeasonData(sorted);
                    setSeasonInfo(sorted.find(x => x.id === season));
                    setLoadingData(false);
                    
                    if (cache) {
                        const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 6, now.getUTCDate()));

                        var headers = new Headers();
                        headers.append('expire', expirationTime.getTime());
        
                        cache.put(`/${serie}/seasons`, new Response (JSON.stringify(data), {
                            headers: headers
                        }));
                    }
                
                }

            }

            if (loadingData) {
                let lang = i18n.language;
                
                if (i18n.language === "es" || i18n.language === "en") {
                    lang = i18n.language;
                } else {
                    lang = "en";
                }

                if ('caches' in window) {
                    caches.open(`animes_${lang}`)
                    .then((cache) => {
                        cache.match(`/${serie}/seasons`)
                        .then(async (response) => {
                            if (isValid(response)) {
                                var data = await response.json();
                                const sorted = data.sort((a,b) => a.id > b.id ? 1 : -1);
                                setSeasonData(sorted);
                                setSeasonInfo(sorted.find(x => x.id === season));
                                setLoadingData(false);
                            } else {
                                import(`../../services/firebase/anime_${lang}`)
                                .then((db) => {
                                    getAnime(db, cache);
                                })
                            }
                        })
                    })
                } else {
                    import(`../../services/firebase/anime_${lang}`)
                    .then((db) => {
                        getAnime(db);
                    })
                }
            }
        }
        
        return () => { ignore = true }
    }, [loadingData, season]);

    useEffect(() => {
        if (loadingData) {
            const storage = localStorage.getItem(`watch-list-${serie}`);

            if (storage) {
                setViewed(JSON.parse(storage)[`${season}-${episode}`])
            }
        }
    }, [loadingData])

    useEffect(() => {
        setSerie(params.id.split("-")[0]);
        setSeason(params.id.split("-")[1]);
        setEpisode(params.id.split("-")[2]);
        setLoadingCaps(true);
        setLoadingData(true);
    }, [params.id]);

    useEffect(() => {
        setLoadingComments(true);
        setTimeout(() => {
            setLoadingComments(false);
        }, 100)
    }, [theme]);

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (loadingData || loadingCaps) {
                getCapsList();
            }
        }
        
        return () => { ignore = true }
    }, [loadingData, loadingCaps]);

    const episodeInfo = episodeData.find(x => x.id === episode);

    /* Handle Automatic View */
    useEffect(() => {
        const storage = localStorage.getItem(`watch-list-${serie}`);

        if (!episodeInfo) return;

        const watched = setTimeout(() => {
            if (viewed) return;

            if (storage) {
                let arr = JSON.parse(storage);
                arr[`${season}-${episode}`] = 'true';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr));
                setViewed(true);
            } else {
                setViewed(true);
                let arr = {};
                arr[`${season}-${episode}`] = 'true';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr))
            } 

        }, episodeInfo?.duration * 1000 / 1.2)

        return () => clearTimeout(watched);
    }, [episodeInfo, viewed])

    /* Handle Partial View */
    useEffect(() => {
        const storage = localStorage.getItem(`watch-list-${serie}`);

        if (!episodeInfo) return;

        const watched = setTimeout(() => {
            if (viewed) return;

            if (storage) {
                let arr = JSON.parse(storage);
                arr[`${season}-${episode}`] = 'partial';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr));
                setViewed(true);
            } else {
                setViewed(true);
                let arr = {};
                arr[`${season}-${episode}`] = 'partial';
                localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr))
            }   
        }, episodeInfo?.duration * 1000 / 2)

        return () => clearTimeout(watched);
    }, [episodeInfo, viewed])

    if (loadingData) {
        return (
            <div className="reproductor-layout lazy">
                <div className="video-layout">
                    <div className="reproductor"></div>
                </div>
                <div className="data-reproductor-layout">
                    <div className="data-content">
                        <div className="data-content-title">
                            <div className="upper">
                                <div className="title"></div>
                                <div className="options">
                                    <div className="IconButton"></div>
                                    <div className="IconButton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (error) {
        return (
            <div className="reproductor-layout">
                <div className="video-layout">
                    <div className="reproductor">
                        <div className="reproductor-msg">
                            <div className="reproductor-background" style={{backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/aodesu-en.appspot.com/o/animes%2F404-background.webp?alt=media&token=9baed4ea-36a1-4aeb-b385-8bea8ba0ea24")`}}></div>
                            <Message restriction="404" />
                        </div>
                    </div>
                </div>
                <div className="data-reproductor-layout">
                    <div className="data-content">
                        <div className="data-content-title">
                            <div className="upper">
                                <div className="title"></div>
                                <div className="options">
                                    <div className="IconButton"></div>
                                    <div className="IconButton"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <SEO title={`[${t('words.shorts.season')}${season} ${t('words.shorts.episode')}${episode}] ${seasonInfo?.name} - ${episodeInfo?.name}`} />
            <div className='reproductor-layout'>
                <div className="bg" onContextMenu={handleContextMenu}></div>
                <Reproductor 
                    rating={seasonInfo?.rating} 
                    loading={loading} 
                    setLoading={setLoading} 
                    serie={serie}
                    getCapsList={getCapsList}
                    setReproductors={setReproductors}
                    bg={episodeInfo?.cover}
                    seasonInfo={seasonInfo}
                    episode={episode}
                    episodeInfo={episodeInfo}
                    currentVideo={currentVideo}
                    setCurrentVideo={setCurrentVideo}
                />
                <div className="data-reproductor-layout">
                    <div className="data-content">
                        <div className="data-content-title" onContextMenu={handleContextMenu}>
                            <div className="upper">
                                <div className="title"><Link to={`/anime/${serie}-${season}`}><h1>{seasonInfo?.name}</h1></Link></div>
                            </div>
                            <h2>{`${t('words.shorts.episode')}${episode} - ${episodeInfo?.name}`}</h2>
                            <div className="tags-and-rating">
                                {seasonInfo?.rating &&
                                    <>
                                        <span className={`rating ${seasonInfo?.rating}`}>{t(`words.ratings.${seasonInfo?.rating}`)}</span>
                                        <Circle sx={{fontSize: '.3rem'}} />
                                    </>
                                }
                                {episodeInfo?.tags.map((tag, i) => (
                                    <span key={i}>{t(`categories.langs.${tag}`)}</span>
                                ))}
                            </div>
                        </div>
                        <div className="options">
                            <AoButton 
                                icon={viewed === 'true' ?  <Visibility /> : <VisibilityOff />}
                                action={handleViewed}
                                variant={`rounded ${viewed === 'true' && 'special-2'}`}
                                title={t(`${viewed === 'true' ? 'words.watched' : 'modal.episode.viewed.false'}`)}
                                size={"small"}
                                style={{
                                    height: 'max-content'
                                }}
                            />
                            <AoIconButton 
                                content={<IosShare />}
                                action={() => {openShare(`/watch/${params.id}/share`)}}
                                title={t('words.share episode')}
                            />
                            <AoIconButton 
                                content={<Flag />}
                                action={() => openReportEpisode()}
                                title={t('words.report episode')}
                            />
                        </div>
                        <ReproductorSelector 
                            reproductors={reproductors} 
                            setLoading={setLoading}
                            currentVideo={currentVideo}
                            setCurrentVideo={setCurrentVideo}
                        />
                        <div className="data-content-body">
                            <div className="comments">
                                {!loadingComments &&
                                    <Comments 
                                        id={params.id}
                                        name={`${t('words.shorts.season')}${season} ${t('words.shorts.episode')}${episode} - ${seasonInfo?.name}`}
                                    />
                                }
                            </div>
                            <AdsTerra />
                        </div>
                    </div>
                    {!loadingCaps && !loadingData &&
                        <ChangeEpisode 
                            serie={serie}
                            episodes={episodeData}
                            currentIndex={episodeData.findIndex(x => x.id === episode.toString())}
                            seasons={seasonData}
                            currentSeason={seasonData.findIndex(x => x.id === season.toString())}
                        />
                    }
                </div>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference='anchorPosition'
                    className='lf-menu'
                    transitionDuration={0}
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <AoButton 
                        icon={<IosShare />}
                        title={t('words.share episode')}
                        action={() => {openShare(`/watch/${params.id}/share`);handleClose()}}
                    />
                    <AoButton 
                        icon={<Flag />}
                        title={t('words.report episode')}
                        action={() => {openReportEpisode();handleClose()}}
                    />
                </Menu>
            </div>
            {reportEpisode &&
                <Modal
                    title={"Reportar capitulo"}
                    close={closeReportEpisode}
                    visible={reportEpisodeState}
                >
                    <ReportEpisode 
                        serie={serie}
                        episodeInfo={episodeInfo} 
                        seasonInfo={seasonInfo} 
                    />
                </Modal>
            }
        </>
    )
}
