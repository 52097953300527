import { createBrowserRouter } from "react-router-dom";
import Layout from "../page/Layout";
import Home from "../page";
import Login from "../page/auth/Login";
import Register from "../page/auth/Register";
import Settings, { SettingsIndex } from "../page/settings";
import Preferences from "../page/settings/preferences";
import Language from "../page/settings/preferences/Language";
import Account from "../page/settings/account";
import Privacy from "../page/settings/privacy";
import Ads from "../page/settings/ads";
import { Information as AccInformation } from "../page/settings/account/Information";
import CompleteAuth from "../page/auth/CompleteAuth";
import User from "../page/user";
import PremiumInfo from "../page/premium";
import Community from "../page/settings/community";
import Suggest from "../page/settings/community/Suggest";
import Report from "../page/settings/community/Report";
import Request from "../page/settings/community/Request";
import Appearance from "../page/settings/preferences/Appearance";
import Anime from "../page/anime";
import AnimeId from "../page/anime/AnimeId";
import Watch from "../page/watch";
import Search from "../page/search";
import Premium from "../page/settings/premium";
import History from "../page/settings/premium/History";
import Policy from "../page/settings/privacy/Policy";
import Terms from "../page/settings/privacy/Terms";
import Genres from "../page/genres/Genres";

export const AoRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: "search",
                element: <Search />,
                children: [
                    {
                        path: ":search",
                        element: <Search />
                    }
                ]
            },
            {
                path: "auth",
                children: [
                    {
                        path: "login",
                        element: <Login />
                    },
                    {
                        path: "register",
                        element: <Register />
                    },
                    {
                        path: "complete-auth",
                        element: <CompleteAuth />

                    }
                ]
            },
            {
                path: "anime",
                children: [
                    {
                        index: true,
                        element: <Anime />,
                    },
                    {
                        path: ":id",
                        element: <AnimeId />,
                        children: [
                            {
                                path: ":action"
                            }
                        ]
                    }
                ]
            },
            {
                path: "watch/:id",
                element: <Watch />
            },
            {
                path: "genres/:genre",
                element: <Genres />
            },
            {
                path: "u/:uid",
                children: [
                    {
                        index: true,
                        element: <User />
                    }
                ]
            },
            {
                path: "premium",
                element: <PremiumInfo />
            },
            {
                path: "ad",
                children: [
                    {
                        path: ":ad",
                        element: <div></div>
                    }
                ]
            },
            {
                path: "settings",
                element: <Settings />,
                children: [
                    {
                        index: true,
                        element: <SettingsIndex />
                    },
                    {
                        path: "preferences",
                        children: [
                            {
                                index: true,
                                element: <Preferences />,
                            },
                            {
                                path: "appearance",
                                element: <Appearance />
                            },
                            {
                                path: "language",
                                element: <Language />
                            }
                        ]
                    },
                    {
                        path: "account",
                        children: [
                            {
                                index: true,
                                element: <Account />
                            },
                            {
                                path: "information",
                                element: <AccInformation />
                            }
                        ]
                    },
                    {
                        path: "privacy",
                        children: [
                            {
                                index: true,
                                element: <Privacy />
                            },
                            {
                                path: "policy",
                                element: <Policy />
                            },
                            {
                                path: "terms",
                                element: <Terms />
                            }
                        ]
                    },
                    {
                        path: "ads",
                        children: [
                            {
                                index: true,
                                element: <Ads />
                            }
                        ]
                    },
                    {
                        path: "premium",
                        children: [
                            {
                                index: true,
                                element: <Premium />
                            },
                            {
                                path: "information",
                                element: <PremiumInfo />
                            },
                            {
                                path: "history",
                                element: <History />
                            },
                            {
                                path: "gift",
                                element: <div>Reclamar regalo</div>
                            }
                        ]
                    },
                    {
                        path: "community",
                        children: [
                            {
                                index: true,
                                element: <Community />
                            },
                            {
                                path: "suggest",
                                element: <Suggest />
                            },
                            {
                                path: "report",
                                element: <Report />
                            },
                            {
                                path: "request",
                                element: <Request />
                            }
                        ]
                    }
                ]
            }
        ]
    }
])