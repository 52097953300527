import { createContext, useState } from "react";

export const ModalsContext = createContext();

export const ModalsProvider = ({ children }) => {
    const [ customizeSelector, setCustomizeSelector ] = useState(false);
    const [ customizeSelectorState, setCustomizeSelectorState ] = useState(null);
    const [ customizeSelectorType, setCustomizeSelectorType ] = useState('');

    const [ customizePfpType, setCustomizePfpType ] = useState('');

    const [ changePfp, setChangePfp ] = useState(false);
    const [ changePfpState, setChangePfpState ] = useState(null);
    const [ changePfpImage, setChangePfpImage ] = useState(null);

    const [ share, setShare ] = useState(false);
    const [ shareState, setShareState ] = useState(null);
    const [ shareLink, setShareLink ] = useState('');

    const [ embed, setEmbed ] = useState(false);
    const [ embedState, setEmbedState ] = useState(null);
    const [ embedURL, setEmbedURL ] = useState('');

    const [ changeAccount, setChangeAccount ] = useState(false);
    const [ changeAccountState, setChangeAccountState ] = useState(null);

    const [ reportEpisode, setReportEpisode ] = useState(false);
    const [ reportEpisodeState, setReportEpisodeState ] = useState(null);

    const [ onlyPremium, setOnlyPremium ] = useState(false);
    const [ onlyPremiumState, setOnlyPremiumState ] = useState(null);

    const [ serieCard, setSerieCard ] = useState(false);
    const [ serieCardState, setSerieCardState ] = useState(false);
    const [ serieCardType, setSerieCardType ] = useState('');
    const [ serieCardData, setSerieCardData ] = useState([]); 

    const [ adModal, setAdModal ] = useState(false);
    const [ adModalState, setAdModalState ] = useState(null);
    const [ adModalData, setAdModalData ] = useState(null);

    function openCustomizeSelector(type) {
        setCustomizeSelector(true);
        setCustomizeSelectorType(type);
        setTimeout(() => {
            setCustomizeSelectorState('show');
        }, 100);
    }

    function closeCustomizeSelector() {
        setCustomizeSelectorState(null);
        setTimeout(() => {
            setCustomizeSelector(false);
        }, 100);
    }

    function openChangePfp(type, image) {
        setChangePfp(true);
        setCustomizePfpType(type);
        if (type === "upload") {
            setChangePfpImage(image);
        }
        setTimeout(() => {
            setCustomizeSelectorState(null);
            setChangePfpState('show');
        }, 100);
    }

    function closeChangePfp() {
        setChangePfpState(null);
        setCustomizeSelectorState("show");
        setTimeout(() => {
            setCustomizePfpType(null);
            setChangePfp(false);
        }, 100);
    }

    function openShare(link) {
        setShare(true);
        setShareLink(link);
        setTimeout(() => {
            setShareState('show');
        }, 100);
    }

    function closeShare() {
        setShareState(null);
        setTimeout(() => {
            setShare(false);
            setShareLink("");
        }, 100);
    }

    function openEmbed(url) {
        setEmbed(true);
        setEmbedURL(url);
        setTimeout(() => {
            setEmbedState('show');
        }, 100);
    }

    function closeEmbed() {
        setEmbedState(null);
        setTimeout(() => {
            setEmbed(false);
            setEmbedURL("");
        }, 100);
    }

    function openChangeAccount() {
        setChangeAccount(true);
        setTimeout(() => {
            setChangeAccountState('show');
        }, 100);
    }

    function closeChangeAccount() {
        setChangeAccountState(null);
        setTimeout(() => {
            setChangeAccount(false);
        }, 100);
    }

    function openReportEpisode() {
        setReportEpisode(true);
        setTimeout(() => {
            setReportEpisodeState('show');
        }, 100);
    }

    function closeReportEpisode() {
        setReportEpisodeState(null);
        setTimeout(() => {
            setReportEpisode(false);
        }, 100);
    }

    function openOnlyPremium() {
        setOnlyPremium(true);
        setTimeout(() => {
            setOnlyPremiumState('show');
        }, 100);
    }

    function closeOnlyPremium() {
        setOnlyPremiumState(null);
        setTimeout(() => {
            setOnlyPremium(false);
        }, 100);
    }

    function openSerieCard(data, type) {
        setSerieCard(true);
        setSerieCardType(type);
        setSerieCardData(data);
        setTimeout(() => {
            setSerieCardState('show');
        }, 100);
    }

    function closeSerieCard() {
        setSerieCardState(null);
        setTimeout(() => {
            setSerieCard(false);
            setSerieCardData([]);
            setSerieCardType('');
        }, 100);
    }

    function openAdModal(data) {
        setAdModal(true);
        setAdModalData(data);
        setTimeout(() => {
            setAdModalState('show');
        }, 100);
    }

    function closeAdModal() {
        setAdModalState(null);
        setTimeout(() => {
            setAdModal(false);
            setAdModalData(null);
        }, 100);
    }

    return (
        <ModalsContext.Provider
            value={{
                customizeSelector,
                customizeSelectorState,
                customizeSelectorType,
                openCustomizeSelector,
                closeCustomizeSelector,
                changePfpImage,
                changePfp,
                customizePfpType,
                changePfpState,
                openChangePfp,
                closeChangePfp,
                share,
                shareState,
                shareLink,
                openShare,
                closeShare,
                embed,
                embedState,
                embedURL,
                openEmbed,
                closeEmbed,
                changeAccount,
                changeAccountState,
                openChangeAccount,
                closeChangeAccount,
                reportEpisode,
                reportEpisodeState,
                openReportEpisode,
                closeReportEpisode,
                onlyPremium,
                onlyPremiumState,
                openOnlyPremium,
                closeOnlyPremium,
                serieCard,
                serieCardState,
                serieCardData,
                serieCardType,
                openSerieCard,
                closeSerieCard,
                openAdModal,
                closeAdModal,
                adModal,
                adModalData,
                adModalState
            }}
        >
            {children}
        </ModalsContext.Provider>
    )
}