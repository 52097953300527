import React, { useContext, useRef, useState } from 'react'
import AoButton from '../buttons/Button'
import { Collections, Upload } from '@mui/icons-material'
import { AuthContext } from '../../context/Auth'
import Modal from './Modal';
import { ModalsContext } from '../../context/Modals';
import AoAvatarEditor from '../form/AvatarEditor';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { auth, storage } from '../../services/firebase/accounts';
import { updateProfile } from 'firebase/auth';
import SwiperAvatars from '../swiper/SwiperAvatars';

export function CustomizeAvatar({ title }) {
    const { changePfp, changePfpState, changePfpImage, customizePfpType, closeChangePfp, closeCustomizeSelector } = useContext(ModalsContext);
    const [ uploading, setUploading ] = useState(false);

    const uploadPhoto = (image) => {
        setUploading(true);
        const user = auth.currentUser;

        const userRef = ref(storage, `usuarios/${user.uid}/pfp`);
        uploadBytes(userRef, image)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        updateProfile(user, {
                            photoURL: url
                        }).then(() => {
                            closeChangePfp();
                            closeCustomizeSelector();
                        })
                    })
            })
    }

    if (changePfp) {
        return ( 
            <Modal
                title={title}
                loading={uploading}
                variant={"avatar default-mw"}
                close={closeChangePfp}
                visible={changePfpState}
            >
                {customizePfpType === "collection" && <SwiperAvatars type="avatar" />}
                {customizePfpType === "upload" && 
                    <AoAvatarEditor 
                        photo={changePfpImage} 
                        type="avatar"
                        close={closeChangePfp}
                        setSelectedPhoto={uploadPhoto}
                        loading={uploading}
                    />
                }
            </Modal>
        )
    }

    return null;
}

export function CustomizeBanner({ title }) {
    const { user, setUser } = useContext(AuthContext);
    const { changePfp, changePfpState, changePfpImage, customizePfpType, closeChangePfp, closeCustomizeSelector } = useContext(ModalsContext);
    const [ uploading, setUploading ] = useState(false);

    const uploadPhoto = (image) => {
        setUploading(true);

        const userRef = ref(storage, `usuarios/${user.uid}/pfb`);
        uploadBytes(userRef, image)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        const arr = user;
                        arr.pfb = url;
                        setUser(arr);
                        closeChangePfp();
                        closeCustomizeSelector();
                    })
            })
    }
 
    if (changePfp) {
        return (
            <Modal
                title={title}
                loading={uploading}
                variant={"banner"}
                close={closeChangePfp}
                visible={changePfpState}
            >
                {customizePfpType === "collection" && <SwiperAvatars type="banner" />}
                {customizePfpType === "upload" && 
                    <AoAvatarEditor 
                        photo={changePfpImage} 
                        close={closeChangePfp}
                        type="banner"
                        setSelectedPhoto={uploadPhoto}
                        loading={uploading}
                    />
                }
            </Modal>
        )
    }

    return null;

}

export default function CustomizeSelector() {
    const { user } = useContext(AuthContext);
    const { openChangePfp, openOnlyPremium } = useContext(ModalsContext);
    const pfpInputRef = useRef(null);

    const handleImageChange = (e) => {
        openChangePfp("upload", e.target.files[0]);
        setTimeout(() => {
            pfpInputRef.current.value = "";
        }, 100);
    }

    return (
        <div className='customize-selector'>
            <AoButton 
                icon={<Collections />}
                variant={"outline"}
                title={"Catalogo"}
                description={"Selecciona una imagen de nuestro catálogo ;)"}
                action={() => openChangePfp("collection")}  
            />
            <AoButton 
                icon={<Upload />}
                variant={"outline"}
                title={"Subir"}
                description={"¡Sube la imagen que quieras!"}
                premium={!user.badges.includes("premium")}
                action={() => {
                    if (user.badges.includes("premium")) {
                        pfpInputRef.current.click();
                    } else {
                        openOnlyPremium();
                    }
                }}
            />
            <input 
                type='file'
                accept="image/*"
                id="input-avatar"
                className='hide'
                onChange={handleImageChange}
                ref={pfpInputRef}
            />
        </div>
  )
}
