import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay, Pagination, Navigation } from 'swiper/modules';
import { ArrowBackIos, ArrowForwardIos, BookmarkAddOutlined, PlayArrow } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import AoButton from '../buttons/Button';
import AoIconButton from '../buttons/IconButton';
import './Swiper.scss';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import { Skeleton, useMediaQuery } from '@mui/material';
import i18n from '../../services/i18n';
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import getDBLang from '../hooks/getDBLang';

const SwiperSkeleton = () => {
    return (
        <div className='swiper-home lazy'>
            <div className="swiper swiper-container">
                <div className="swiper-wrapper">
                    <div className="swiper-slide">
                        <div className="container">
                            <div className="layout">
                                <div className="layout-grid">
                                    <div className="background">
                                        <Skeleton 
                                            animation="wave" 
                                            variant="rectangular"
                                            style={{
                                                width: '100%', 
                                                height: '100%',
                                            }} 
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function SwiperHome() {
    const [ selectedLanguage ] = useState(i18n.language);
    const [ data, setData ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const matched = useMediaQuery('(min-width: 50em)');

    

    useEffect(() => {
        const now = new Date();

        async function getData(list) {
            const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));

            const animeRef = doc(list.animeFirestore, `home/swiper`);
            const animeSnap = await getDoc(animeRef);

            const data = animeSnap.data().data;

            setData(data.reverse());
            localStorage.setItem(`expire-${selectedLanguage}-home-banner`, expirationTime.getTime());
            localStorage.setItem(`${selectedLanguage}-home-banner`, JSON.stringify(data))
            setLoading(false);
        }

        if (loading) {
            const expireTime = localStorage.getItem(`expire-${selectedLanguage}-home-banner`);
            const localData = localStorage.getItem(`${selectedLanguage}-home-banner`);
            let lang = getDBLang();

            import(`../../services/firebase/anime_${lang}`)
            .then((list) => {
                if (localData) {
                    if (expireTime) {
                        if (now.getTime() > expireTime) {
                            getData(list);
                        } else {
                            setData(JSON.parse(localData));
                            setLoading(false);
                        }
                    } else {
                        getData(list);
                    }
                } else {
                    getData(list);
                }
            });
        }
    }, []);

    if (loading) {
        return (
            <SwiperSkeleton />
        )
    }

    return (
        <div className="swiper-home">
            {matched ? 
                <SwiperContainer data={data} />
            :
                <LandingMobile data={data} />
            }
        </div>
    )
}


const SwiperContainer = ({ data }) => {
    const onAutoplayTimeLeft = (s, time, progress) => {
        const pagination = document.querySelector('.swiper-pagination');
        pagination.style.setProperty('--progress', (1 - progress) * 100);
      };
    
      const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
          return '<div class="' + className +'"><span></span></div>';
        },
    };

    return(
        <>
            <div className="swiper-button button-next">
                <AoButton 
                    variant="primary"
                    icon={<ArrowForwardIos />}
                />
            </div>
            <div className="swiper-button button-prev">
                <AoButton 
                    icon={<ArrowBackIos />}
                />
            </div>
            <Swiper
                spaceBetween={0}
                slidesPerView={1}
                effect={'fade'}
                loop={true}
                centeredSlides={true}
                pagination={pagination}
                navigation={{
                    nextEl: '.button-next',
                    prevEl: '.button-prev',
                    disabledClass: 'disabled',
                    clickable: true,
                }}
                autoplay={{
                    delay: 12000,
                    disableOnInteraction: true,
                }}
                modules={[EffectFade, Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                observeParents={true}
                className='swiper-container'
            >
                {data.map((doc, i) => (
                    <SwiperSlide key={i}>
                        <SlideSwiper data={doc} i={i} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </>
    )
}

const LandingMobile = ({ data }) => {
    const [ currentData, setCurrentData ] = useState(null);

    useEffect(() => {
        function getRandom(x) {
            return Math.floor(Math.random() * x)
        }

        function getCurrent() {
            const int = getRandom(data.length);
            setCurrentData(data[int]);
        }

        getCurrent();
    }, []);

    if (currentData === null) {
        return (
            <SwiperSkeleton />
        )
    }

    return (
        <div className="swiper swiper-container">
            <div className="swiper-wrapper">
                <div className="swiper-slide">
                    <SlideIndividual data={currentData} />
                </div>
            </div>
        </div>
    )
}

const SlideIndividual = ({ data }) => {
    const { t } = useTranslation();

    const serie = data.id.split("-")[0];
    const season = data.id.split("-")[1];

    return (
        <div className={`container active`}>
            <div className="layout">
                <div className="layout-grid">
                    <div className="background">
                        <picture>
                            <source 
                                srcSet={data.images.mv} 
                                type='image/webp'
                            />
                            <LazyLoadImage 
                                src={data.images.mv} 
                                className='tall-variant'
                                effect='blur'
                                alt={`${data.name} - aodesu`} 
                            />
                        </picture>
                    </div>
                    <div className="layout-logo">
                        <Link to={`/anime/${data.id}`} className='logo-link'>
                            <picture>
                                <source 
                                    srcSet={data.images.logo} 
                                    type='image/webp'
                                />
                                <LazyLoadImage 
                                    effect='blur'
                                    src={data.images.logo}
                                    alt={`${data.name} - aodesu`}
                                />
                            </picture>
                        </Link>
                    </div>
                    <div className="layout-body">
                        <h2 className='seo-title'>{data.name}</h2>
                        <div className='tags-and-rating' style={{margin: '.5rem 0 .25rem'}}>
                            {data.tags.map((tag, i) => (
                                <span key={i}>{t(`categories.langs.${tag}`)}</span>
                            ))}
                        </div>
                        <p className='description'>{data.synopsis}</p>
                        <div className="buttons-group">
                            {data.episode ?
                                <AoButton 
                                    title={`Comenzar a ver T1 E1`}
                                    icon={<PlayArrow />}
                                    variant="special-2"
                                    type={"link"}
                                    to={`/watch/${serie}-${season}-${data.episode}`}
                                    style={{padding: '.6rem'}}
                                />
                                :
                                <AoButton 
                                    title={`Ir a ${data.name}`}
                                    icon={<PlayArrow />}
                                    variant="special-2"
                                    type={"link"}
                                    to={`/anime/${serie}-${season}`}
                                    style={{padding: '.6rem'}}
                                    alt={`Ir a ${data.name}`}
                                />
                            }
                            {/* <AoIconButton 
                                title={"Guardar"}
                                content={<BookmarkAddOutlined />}
                                variant="outlined square"
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const SlideSwiper = ({data, i}) => {
    const swiper = useSwiperSlide();
    const { t } = useTranslation();
    const [ fetched, setFetched ] = useState(false);

    const serie = data.id.split("-")[0];
    const season = data.id.split("-")[1];

    useEffect(() => {
        if (swiper.isActive) {
            setFetched(true);
        }
    }, [swiper.isActive]);

    return (
        <div className={`container ${swiper.isActive && 'active'}`}>
            <div className="layout">
                <div className="layout-grid">
                    <div className="background">
                        {fetched &&
                            <picture>
                                <source 
                                    srcSet={data.images.pc} 
                                    type='image/webp'
                                    media='(max-width: 1920px)'
                                />
                                <LazyLoadImage 
                                    src={data.images.pc} 
                                    className='wide-variant'
                                    effect='blur'
                                    alt={`${data.name} - aodesu`} 
                                />
                            </picture>
                        }
                    </div>
                    <div className="layout-logo">
                        <Link to={`/anime/${data.id}`} className='logo-link'>
                            {fetched &&
                                <picture>
                                    <source 
                                        srcSet={data.images.logo} 
                                        type='image/webp'
                                    />
                                    <LazyLoadImage 
                                        effect='blur'
                                        src={data.images.logo}
                                        alt={`${data.name} - aodesu`}
                                    />
                                </picture>
                            }
                        </Link>
                    </div>
                    <div className="layout-body">
                        <h2 className='seo-title'>{data.name}</h2>
                        <div className='tags-and-rating' style={{margin: '.5rem 0 .25rem'}}>
                            {data.tags.map((tag, i) => (
                                <span key={i}>{t(`categories.langs.${tag}`)}</span>
                            ))}
                        </div>
                        <p className='description'>{data.synopsis}</p>
                        <div className="buttons-group">
                            {data.episode ?
                                <AoButton 
                                    title={`Comenzar a ver T1 E1`}
                                    icon={<PlayArrow />}
                                    variant="special-2"
                                    type={"link"}
                                    to={`/watch/${serie}-${season}-${data.episode}`}
                                    style={{padding: '.6rem'}}
                                />
                                :
                                <AoButton 
                                    title={`Ir a ${data.name}`}
                                    icon={<PlayArrow />}
                                    variant="special-2"
                                    type={"link"}
                                    to={`/anime/${serie}-${season}`}
                                    style={{padding: '.6rem'}}
                                    alt={`Ir a ${data.name}`}
                                />
                            }
                            {/* <AoIconButton 
                                title={"Guardar"}
                                content={<BookmarkAddOutlined />}
                                variant="outlined square"
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}