import React from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import AoButton from '../../components/buttons/Button';
import { ArrowBack } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';

export default function SettingsHeader() {
    const { t } = useTranslation();
    const location = useLocation();
    const match = useMediaQuery('(max-width: 50em)');

    if (match) {
        return (
            <AoButton 
                type="link"
                to={location.pathname.split("/")[3] ? `/settings/${location.pathname.split("/")[2]}` : `/settings/`}
                icon={<ArrowBack />}
                title={<h1>{t(`settings.nav.${location.pathname.split("/").pop()}`)}</h1>}
                className='settings-title'
            />
        )
    }

    return (
        <AoButton 
            type="link"
            to={`/settings/${location.pathname.split("/")[2]}`}
            icon={location.pathname.split("/").length > 3 ? <ArrowBack /> : null}
            title={<h1>{t(`settings.nav.${location.pathname.split("/").pop()}`)}</h1>}
            className='settings-title'
        />
    )
}
