import React from 'react'
import AoButton from '../../../components/buttons/Button'
import { Help, History } from '@mui/icons-material'
import GiftIcon from '../../../images/Gift'
import { useTranslation } from 'react-i18next'

export default function Premium() {
  const { t } = useTranslation();
  return (
    <>
        <AoButton 
            variant={"left"}
            icon={<Help />}
            title={t('settings.nav.premium-information')}
            description={t('settings.nav.premium-information-desc')}
            type={"link"}
            to={"information"}
            hasRightArrow={true}
        />
        <AoButton 
            variant={"left"}
            icon={<History />}
            title={t('settings.nav.history')}
            description={t('settings.nav.history-desc')}
            type={"link"}
            to={"history"}
            hasRightArrow={true}
        />
        {/* <AoButton 
            variant={"left"}
            icon={<GiftIcon />}
            title={t('settings.nav.gift')}
            description={t('settings.nav.gift-desc')}
            type={"link"}
            to={"gift"}
            hasRightArrow={true}
        /> */}
    </>
  )
}
