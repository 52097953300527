import React from 'react'
import Logo from '../../images/Logo'
import AoIconButton from '../buttons/IconButton'
import { Close, FavoriteBorder, HowToVoteOutlined, PausePresentationOutlined, RemoveRedEyeOutlined, Shuffle } from '@mui/icons-material'
import AoButton from '../buttons/Button'
import { useTranslation } from 'react-i18next'

export default function Sidebar({ state, change }) {
  const { t } = useTranslation();
  return (
    <>
      <div className={`backdrop ${state}`} style={{ zIndex: 99}} onClick={change}></div>
      <div className={`sidebar ${state}`}>
        <div className='top'>
          <div className='logo'>
            <Logo />
          </div>
          <AoIconButton 
            content={<Close />}
            title="close"
            action={change}
          />
        </div>
        <nav>
          <div>
            {/* <AoButton 
              title={t('sidebar.random')}
              variant="btn-sidebar"
              icon={<Shuffle />}
            /> */}
            <AoButton 
              title={t('sidebar.requests')}
              variant="btn-sidebar"
              icon={<HowToVoteOutlined />}
              type={"link"}
              action={change}
              to={"/settings/community/request"}
            />
          </div>
          {/* <div>
            <span>Listas</span>
            <AoButton 
              title={t('sidebar.favorite')}
              variant="btn-sidebar"
              type="link"
              to="/home"
              icon={<FavoriteBorder />}
            />
            <AoButton 
              title={t('sidebar.pending')}
              variant="btn-sidebar"
              icon={<PausePresentationOutlined />}
            />
            <AoButton 
              title={t('sidebar.watched')}
              variant="btn-sidebar"
              icon={<RemoveRedEyeOutlined />}
            />
          </div> */}
        </nav>
      </div>
    </>
  )
}
