import React, { useContext, useRef, useState } from 'react'
import { Avatar, Button, ClickAwayListener, Grow, Popper, useMediaQuery } from '@mui/material';
import { ArrowDropDown, Menu, BookmarkBorder, Settings, Login, HowToVote, History, Logout, AutoAwesome, Cached, Close, AccountCircleOutlined, DarkMode, Language as LanguageIcon, ArrowBack, Search, Bookmark } from '@mui/icons-material';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../images/Logo';
import { useTranslation } from 'react-i18next';
import AoIconButton from '../buttons/IconButton';
import AoButton from '../buttons/Button';
import { AuthContext } from '../../context/Auth';
import GiftIcon from '../../images/Gift';
import { signOut } from 'firebase/auth';
import { auth } from '../../services/firebase/accounts';
import { AlertsContext } from '../../context/Alerts';
import PremiumLogo from '../../images/Premium';
import { ModalsContext } from '../../context/Modals';
import Appearance from '../../page/settings/preferences/Appearance';
import Language from '../../page/settings/preferences/Language';
import Modal from '../modals/Modal';
import SearchIcon from '../../images/Search';
import SearchFilledIcon from '../../images/SearchFilled';
import animeGenres from '../../services/animeGenres';
import { t } from 'i18next';
import Logotipo from '../../images/Logotipo';

export default function Header({ change, refresh }) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const match = useMediaQuery('(min-width: 30em)');

  if (location.pathname.split("/")[1] === "premium") {
    return (
      <header className='header'>
        <div className="section">
          <div className="logo">
            <Link to="/" title={t('header.principal page')}>
              <Logo />
            </Link>
          </div>
        </div>
        <section className='section' style={{ gap: '.5rem'}}>
          {user ?
            <UserMenu refresh={refresh} />
          :
            <nav className="nav no-hide" style={{ padding: '.6rem 0'}}>
              <AoButton 
                variant={"outline uppercase"}
                action={() => {
                  navigate('/auth/login');
                  sessionStorage.setItem('back-path', location.pathname);
                }}
                title={t('header.user-menu.login.title')}
              />
            </nav>
          }
        </section>
      </header>
    )
  }
  return (
    <header className='header'>
      <div className='section'>
        <div>
          <AoIconButton 
            content={<Menu />}
            title={"Menu"}
            action={change}
          />
        </div>
        <div className="logo">
          <Link to="/" title={t('header.principal page')}>
            {match ? 
              <Logo />
              :
              <Logotipo />
            }
          </Link>
        </div>
        <HeaderNav />
      </div>
      <div className='section' style={{ gap: '.5rem'}}>
        <div className="options">
          {!user?.badges.includes("premium") &&
            <AoButton 
              title={t('header.buy premium')}
              alt={t('header.buy premium')}
              icon={<PremiumLogo />}
              variant="premium"
              type={"link"}
              to={"/premium"}
            />
          }
          <Button
            className='button'
            component={NavLink}
            to="/search"
            alt={t('header.search')}
            title={t('header.search')}
          >
            <div className="button-icon">
              <SearchIcon className="outlined" />
              <SearchFilledIcon className="filled" />
            </div>
          </Button>
          {/* {match &&
            <Button
              className='button'
              component={NavLink}
              to="/lists"
              alt={t('header.lists')}
              title={t('header.lists')}
            >
              <div className="button-icon">
                <BookmarkBorder className="outlined" />
                <Bookmark className="filled" />
              </div>
            </Button>
          } */}
          <UserMenu refresh={refresh} />
        </div>
      </div>
    </header>
  )
}

const HeaderNav = () => {
  const anchorRef = useRef(null);
  const [ openGenres, setOpenGenres ] = useState(false);

  const handleCloseGenres = (event) => {
    if (event) {
      if(anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }
    }
    setOpenGenres(false);
  }

  const handleToggle = () => {
    setOpenGenres((prevOpen) => !prevOpen);
  };

  return (
    <>
      <nav className="nav" >
        <button ref={anchorRef} onClick={handleToggle}>{t('header.genres')} <ArrowDropDown /></button>
      </nav>
      <Popper
        open={openGenres}
        anchorEl={anchorRef.current}
        placement='bottom-start'
        disablePortal
      >
        <div>
          <ClickAwayListener onClickAway={handleCloseGenres}>
            <div className="header-menu genres">
              <div className="header-menu_title">{t('header.genres')}</div>
              <div className="header-menu_body">
                {animeGenres.map((genre, i) => (
                  <AoButton 
                    key={i}
                    title={t(`categories.genres.${genre.id}`)}
                    icon={genre.icon}
                    type={"link"}
                    to={`/genres/${genre.id}`}
                    alt={t(`categories.genres.${genre.id}`)}
                    action={handleCloseGenres}
                  />
                ))}
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </Popper>
    </>
  )
}

const HeaderMenu = ({ 
  user, 
  handleClose, 
  t,
  handleSubMenu,
  setOpen,
  openChangeAccount,
  addAlert,
  subMenu,
  subMenuType,
  handleCloseSubMenu,
  refresh
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <>
      {user ? 
        <div className="user">
          <div className="photo">
            <Avatar className='avatar image' src={user.photoURL} alt={user.name} sx={{ width: 54, height: 54}} />
          </div>
          <div className="data">
            <span className='name'>{user.displayName ? user.displayName : 'Sin nombre.'}</span>
            <span className='premium'>
              {user.badges.includes("premium") ?
              <>
                <div className="icon">
                  <PremiumLogo style={{ width: '1rem', height: '1rem'}} />
                </div>
                <div className="text">{t('header.user-menu.premium.yes')}</div>
              </>
              :
              <>
                <div className="icon font-style color-negative">
                  <Close />
                </div>
                <div className="text font-style color-negative">{t('header.user-menu.premium.no')}</div>
              </>
              }
            </span>
          </div>
        </div>
      :
      <div className='no-logged'>
        <AoButton 
          icon={<AutoAwesome />}
          action={() => {
            navigate('/auth/register');
            sessionStorage.setItem('back-path', location.pathname);
            handleClose();
          }}
          title={t('header.user-menu.register.title')}
          description={t('header.user-menu.register.desc')}
        />
        <AoButton 
          icon={<Login />}
          action={() => {
            navigate('/auth/login');
            sessionStorage.setItem('back-path', location.pathname);
            handleClose();
          }}
          title={t('header.user-menu.login.title')}
          description={t('header.user-menu.login.desc')}
        />
        {/* <AoButton 
          icon={<GiftIcon />}
          title="Reclamar Regalo"
          description={"¿Conseguiste un regalito? Canjealo aquí."}
        /> */}
      </div>
      }
      <div className="nav">
        {user &&
          <div>
            <AoButton 
              icon={<Settings />}
              action={() => {
                handleClose();
                sessionStorage.setItem('back-path', location.pathname);
              }}
              title={t('settings.title')}
              type="link"
              to="/settings/"
            />
            {/* <AoButton 
              icon={<GiftIcon />}
              title="Reclamar Regalo"
            />
            <AoButton 
              icon={<History />}
              title={"Historial"}
            /> */}
          </div>
        }
      </div>
      <div className="options">
        <AoButton 
          icon={<DarkMode />}
          title={t('settings.nav.appearance')}
          hasRightArrow={true}
          variant={"left"}
          action={() => handleSubMenu('theme')}
        />
        <AoButton 
          icon={<LanguageIcon />}
          title={t('settings.nav.language')}
          hasRightArrow={true}
          variant={"left"}
          action={() => handleSubMenu('lang')}
        />
      </div>
      <div className="divider"></div>
      <div className="nav">
        <div>
            <AoButton 
              icon={<HowToVote />}
              type={"link"}
              to={"/settings/community/request"}
              action={() => {
                navigate('/settings/community/request');
                sessionStorage.setItem('back-path', location.pathname);
                handleClose();
              }}
              title={t('settings.nav.request')}
            />
          </div>
      </div>
      <div className="options">
        {user &&
          <>
          <AoButton 
            icon={<Cached />}
            action={() => {
              setOpen(false);
              openChangeAccount();
            }}
            title={t('header.user-menu.change-account')}
          />
          <AoButton 
            icon={<Logout />}
            title={t('header.user-menu.logout')}
            action={() => {
              signOut(auth)
                .then(() => {
                  handleClose();
                  addAlert("Nos vemos...", "branch", "filled", "auto");
                })
              }}
          />
          </>
        }
      </div>
      {subMenu &&
        <div className={`sub-menu ${subMenuType !== "" && 'active'}`}>
          <div className="sub-menu__title">
            <AoButton 
              title={t('words.back')}
              icon={<ArrowBack />}
              className="title"
              action={handleCloseSubMenu}
            />
          </div>
          <div className="options">
            {subMenuType === "lang" && <Language zone="user-menu" refresh={refresh} />}
            {subMenuType === "theme" && <Appearance zone="user-menu" />}
            
          </div>
        </div>
      }
    </>
  )
}

function UserMenu({ refresh }) {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { addAlert } = useContext(AlertsContext);
  const { openChangeAccount } = useContext(ModalsContext);
  const [ open, setOpen ] = useState(false);
  const [ openState, setOpenState ] = useState(null);

  const matched = useMediaQuery('(max-width: 808px)');

  const [ subMenu, setSubMenu ] = useState(false);
  const [ subMenuType, setSubMenuType ] = useState('');
  
  const anchorRef = useRef(null);

  const handleSubMenu = (value)  => {
    setSubMenu(true);
    setTimeout(() => {
      setSubMenuType(value);
    }, 100);
  }

  const handleCloseSubMenu = ()  => {
    setSubMenuType('');
    setTimeout(() => {
      setSubMenu(false);
    }, 100);
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTimeout(() => {
      setOpenState('show');
    }, 100);
  };

  const handleClose = (event) => {
    function Close() {
      if (event) {
        if(anchorRef.current && anchorRef.current.contains(event.target)){
          return;
        }
      }
      setOpenState(null);
      setTimeout(() => {
        setOpen(false);
        handleCloseSubMenu();
      }, 100);
    }

    if (matched) {
      if (subMenu) {
        handleCloseSubMenu();
      } else {
        Close();
      }
    } else {
      Close();
    }
  }

  return(
    <>
      <Button 
        title={t('header.avatar')}
        onClick={handleToggle}
        ref={anchorRef}
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className='button icon'
        style={{ height: '100%'}}
      >
        {user ?
          <Avatar className='avatar image' alt={user.name} src={user.photoURL} />
          :
          <Avatar className='avatar image'>
            <AccountCircleOutlined sx={{ width: 32, height: 32}} />
          </Avatar>
        }
      </Button>
      {matched ?
        <>
          {open &&
            <Modal
              variant={"user-menu"}
              close={handleClose}
              visible={openState}
            >
              <div className="header-menu">
                <HeaderMenu 
                  user={user}
                  handleClose={handleClose}
                  t={t}
                  handleSubMenu={handleSubMenu}
                  setOpen={setOpen}
                  openChangeAccount={openChangeAccount}
                  addAlert={addAlert}
                  subMenu={subMenu}
                  subMenuType={subMenuType}
                  handleCloseSubMenu={handleCloseSubMenu}
                  refresh={refresh}
                />
              </div>
            </Modal>
          }
        </>
      :
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement='bottom-end'
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: 'right top',
              }}
            >
              <div>
                <ClickAwayListener onClickAway={handleClose}>
                  <div className="header-menu">
                    <HeaderMenu 
                      user={user}
                      handleClose={handleClose}
                      t={t}
                      handleSubMenu={handleSubMenu}
                      setOpen={setOpen}
                      openChangeAccount={openChangeAccount}
                      addAlert={addAlert}
                      subMenu={subMenu}
                      subMenuType={subMenuType}
                      handleCloseSubMenu={handleCloseSubMenu}
                      refresh={refresh}
                    />
                  </div>
                </ClickAwayListener>
              </div>
            </Grow>
          )}
        </Popper>
      }
    </>
  )
}