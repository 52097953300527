import React, { useContext, useEffect, useRef, useState } from 'react'
import AoAvatarEditor from '../../components/form/AvatarEditor'
import Form from '../../components/form/Form';
import AoIconButton from '../../components/buttons/IconButton';
import Modal from '../../components/modals/Modal';
import Input from '../../components/form/Input';
import { useTranslation } from 'react-i18next';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { auth, firestore, storage } from '../../services/firebase/accounts';
import AoButton from '../../components/buttons/Button';
import { onAuthStateChanged, updateProfile } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';

export default function CompleteAuth() {
    const inputRef = useRef(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { getUserData } = useContext(AuthContext);
    
    const [ photo, setPhoto ] =  useState(null);
    const [ selectedPhoto, setSelectedPhoto ] = useState(null);
    
    const [ avaibleUsername, setAvaibleUsername ] = useState("");
    const [ nickname, setNickname ] = useState("");

    const [ delayHandler, setDelayHandler ] = useState(null);
    const [ username, setUsername ] = useState('');
    const [ searchAlert, setSearchAlert ] = useState('info');
    const [ searchAlertType, setSearchAlertType ] = useState("info");
    const [ disabled, setDisabled ] = useState(true);

    const [ uploading, setUploading ] = useState(false);

    const handleInputClick = (e) => {
        e.preventDefault();
        inputRef.current.click();
    }
    const handleImgChange = (e) => {
        setPhoto(URL.createObjectURL(e.target.files[0]));
    }

    const handleClose = () => {
        setPhoto(null);
        document.getElementById("input-avatar").value = "";
    }

    const handleSearchUsername = async (username) => {
        const nameRef = collection(firestore, "usuarios");
        const q = query(nameRef, where("name", "array-contains", username.toLowerCase()));
        const nameSnap = await getDocs(q);

        if (nameSnap.docs.length > 0) {
            setSearchAlert("already");
            setSearchAlertType("negative");
        } else {
            setSearchAlert("success");
            setSearchAlertType("positive");
            setAvaibleUsername(username.toLowerCase());
        }
    }

    const handleUsername = (value) => {
        const pattern = new RegExp(/^[0-9a-zA-Z.áéíñóúüÁÉÍÑÓÚÜ_-]+$/g);
        setUsername(value.toLowerCase());
        clearTimeout(delayHandler);

        if (value.length === 0) {
            setSearchAlert("info");
            setSearchAlertType("info");
        } else {
            if (!pattern.test(value)) {
                setSearchAlert("no-specials");
                setSearchAlertType("negative");
            } else {
                if (value.length > 3 && value.length < 16) {
                    setSearchAlert("searching");
                    setSearchAlertType("loading");
                    setDelayHandler(setTimeout(() => {
                        handleSearchUsername(value);
                    }, 1000))
                } else {
                    if (value.length < 4) {
                        setSearchAlert("too-less");
                        setSearchAlertType("negative");
                    }
                    if (value.length > 15) {
                        setSearchAlert("too-much");
                        setSearchAlertType("negative");
                    }
                }
            }
        }
    }

    const handleUpload = () => {
        setUploading(true);
        const user = auth.currentUser;
        
        const userRef = doc(firestore, `usuarios/${user.uid}`);
        updateDoc(userRef, {
            name: [avaibleUsername, nickname]
        }).then(() => {
            const userPhoto = ref(storage, `usuarios/${user.uid}/pfp`);
            uploadBytes(userPhoto, selectedPhoto)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        updateProfile(user, {
                            displayName: nickname,
                            name: avaibleUsername,
                            photoURL: url
                        }).then(() => {
                           setUploading(false);
                           getUserData(user.uid, user);
                           navigate("/");
                        })
                    })
            })
        })
    }

    useEffect(() => {
        if (avaibleUsername !== "" && nickname !== "" && selectedPhoto) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [avaibleUsername, nickname, selectedPhoto]);

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/auth/register')
            }
        })
    }, [navigate])

    return (
        <>
            <Form
                type="auth"
                action={handleUpload}
                loading={uploading}
                bg="."
            >
                <div className="avatar-layout">
                    <AoIconButton 
                        className={"avatar-body"}
                        type="change-image"
                        style={{position: 'absolute', bottom: '0'}}
                        avatar={selectedPhoto && URL.createObjectURL(selectedPhoto)}
                        action={handleInputClick}
                    />
                    <input 
                        type='file'
                        accept="image/*"
                        id="input-avatar"
                        ref={inputRef}
                        onChange={handleImgChange}
                    />
                </div>
                <div className="flex flow-column gap-big">
                    <Input 
                        type="text"
                        variant={1}
                        name="username"
                        value={username}
                        onChange={(e) => handleUsername(e.target.value)}
                        label={true}
                        labelText={`*${t('words.username')}`}
                        description={searchAlertType}
                        descriptionText={t(`alerts.username.auth/${searchAlert}`)}
                        required={true}
                    />
                    <Input 
                        type="text"
                        variant={1}
                        name="nickname"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        label={true}
                        labelText={`*${t('words.nickname')}`}
                        required={true}
                    />
                </div>
                <div className="buttons">
                    <AoButton 
                        title={t('words.save')}
                        variant={"special-2 size-big"}
                        style={{width: '100%'}}
                        disabled={disabled}
                        submit={true}
                        loading={uploading}
                    />
                </div>
            </Form>
            {photo &&
                <Modal
                    title="Editar imagen"
                    variant="avatar"
                    visible={"show"}
                    close={handleClose}
                >
                    <AoAvatarEditor 
                        photo={photo}
                        close={handleClose}
                        type={"avatar"}
                        setSelectedPhoto={(value) => {
                            setSelectedPhoto(value);
                            handleClose();
                        }}
                    />
                </Modal>
            }
        </>
    )
}
