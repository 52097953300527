import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { RouterProvider } from 'react-router-dom';
import { AoRouter } from './services/router';
import './styles/index.scss';
import './services/i18n';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <App>
        <RouterProvider router={AoRouter} />
      </App>
    </HelmetProvider>
  </React.StrictMode>
);

serviceWorkerRegistration.register();