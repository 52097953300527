import { doc, getDoc } from 'firebase/firestore';
import React, { useContext, useEffect, useState } from 'react'
import { firestore } from '../../../services/firebase/accounts';
import { AuthContext } from '../../../context/Auth';
import i18n from '../../../services/i18n';
import { CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GiftIcon from '../../../images/Gift';

export default function History() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [ list, setList ] = useState([]);

    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        let ignore = false;

        async function getHistory() {
            const historyRef = doc(firestore, `usuarios/${user?.uid}/settings/premium-history`);
            const historySnap = await getDoc(historyRef);

            if (historySnap.exists()) {
                setList(historySnap.data().history);
                setLoading(false);
            } else {
                setList(null);
                setLoading(false);
            }
        }

        if (!ignore) {
            if (user) {
                if (loading) {
                    getHistory();
                }
            }
        }

        return () => ignore = true;
    }, [user]);

    console.log(list)

    if (loading) {
        return (
            <div className='progress'>
                <CircularProgress className='progress-circular normal' />
            </div>
        )
    }

    return (
        <div>
            {list ?
                <div>
                    {list.map((doc, i) => (
                        <div className='button description' key={i}>
                            {doc.pay_method === "gift" ? 
                            <>
                                <div className="button-icon">
                                    <strong>{i + 1}</strong>
                                </div>
                                <div className="button-text">
                                    <span className='title flex gap-normal'>
                                        <span className='font-style color-positive uppercase'>
                                            <GiftIcon 
                                                style={{
                                                    width: '1.3em',
                                                    height: '1.3em',
                                                    fill: 'currentColor'
                                                }}
                                            />
                                            {`${t('words.from')} ${doc.currency} - ${t(`premium.plans.${doc.name}.name`)} - ${doc.price}$`}
                                        </span>
                                    </span>
                                    <span className="description">
                                        {new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeStyle: 'long'}).format((doc.startAt))}
                                    </span>
                                </div>
                            </>
                            :
                            <>
                                <div className="button-icon">
                                    <strong>{i + 1}</strong>
                                </div>
                                <div className="button-text">
                                    <span className='title flex gap-normal'>
                                        <span className='font-style color-positive uppercase'>
                                            {`${t(`premium.plans.${doc.name}.name`)} - ${doc.price} ${doc.currency}$`}
                                        </span>
                                    </span>
                                    <span className="description">
                                        {new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeStyle: 'long'}).format((doc.startAt))}
                                    </span>
                                </div>
                            </>
                            }
                        </div>
                    ))}
                </div>
            :
                <div className='clean-alert'>
                    <img src="https://i.ibb.co/fVRLKj9/clean.webp" alt="" />
                    <h3>Esto esta muy vacio.</h3>
                </div> 
            }
        </div>
    )
}
