import { 
    AutoAwesomeOutlined,
    AutoStoriesOutlined,
    ExploreOutlined,
    FavoriteBorderRounded,
    LocalFireDepartmentOutlined,
    PetsOutlined,
    RocketLaunchOutlined,
    SentimentVerySatisfiedOutlined,
    TheaterComedyOutlined, 
    LocalDining, 
    MusicNote, 
    Explicit, 
    Female, 
    Male, 
    SportsBaseball 
} from "@mui/icons-material";

const animeGenres = [
    {
        id: 'action',
        icon: <LocalFireDepartmentOutlined />
    },
    {
        id: 'adventure',
        icon: <ExploreOutlined />
    },
    {
        id: 'comedy',
        icon: <SentimentVerySatisfiedOutlined />
    },
    {
        id: 'drama',
        icon: <TheaterComedyOutlined />
    },
    {
        id: 'fantasy',
        icon: <AutoAwesomeOutlined />
    },
    {
        id: 'music',
        icon: <MusicNote />
    },
    {
        id: 'romance',
        icon: <FavoriteBorderRounded />
    },
    {
        id: 'sci-fi',
        icon: <RocketLaunchOutlined />
    },
    {
        id: 'seinen',
        icon: <Explicit />
    },
    {
        id: 'shojo',
        icon: <Female />
    },
    {
        id: 'shonen',
        icon: <Male />
    },
    {
        id: 'slice of life',
        icon: <AutoStoriesOutlined />
    },
    {
        id: "sports",
        icon: <SportsBaseball />
    },
    {
        id: 'supernatural',
        icon: <PetsOutlined />
    },
    {
        id: 'thriller',
        icon: <LocalDining />
    }
]
export default animeGenres;