import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next';
import Form from '../form/Form';
import Input from '../form/Input';
import AoButton from '../buttons/Button';
import { ModalsContext } from '../../context/Modals';
import { AuthContext } from '../../context/Auth';
import i18n from '../../services/i18n';

export default function ReportEpisode({ episodeInfo, seasonInfo, serie }) {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { closeReportEpisode } = useContext(ModalsContext);
    const [ reason, setReason ] = useState(t('reasons.1'));
    const [ description, setDescription ] = useState('');

    const [ sending, setSending ] = useState(false);
    const [ send, setSend ] = useState(false);
 
    const handleChange = (event) => {
        setReason(event.target.value);
    }

    const handleSend = () => {
        setSending(true);
        const content = {
            "embeds": [{
              "title": `¡Capitulo reportado!`,
              "description": `## ${seasonInfo.name} \n ${description} \n ## [Visitar Sitio](${window.location.href})`,
              "color": 0xff5441,
              "fields": [
                  {
                      "name": "Anime (uid)",
                      "value": serie,
                      "inline": true
                  },
                  {
                      "name": "Temporada",
                      "value": seasonInfo.id,
                      "inline": true
                  },
                  {
                      "name": "Idioma",
                      "value": i18n.language,
                      "inline": true
                  },
                  {
                      "name": "",
                      "value": "",
                      "inline": false
                  },
                  {
                      "name": "Episodio",
                      "value": episodeInfo.id,
                      "inline": true
                  },
                  {
                      "name": "Nombre del episodio",
                      "value": episodeInfo.name,
                      "inline": true
                  },
                  {
                    "name": "Razón",
                    "value": reason,
                    "inline": false
                  },
                  {
                    "name": "Descripción",
                    "value": description,
                    "inline": false
                  },
                  {
                      "name": "Usuario",
                      "value": user ? user.name : 'Sin usuario',
                      "inline": true
                  },
                  {
                      "name": "Correo",
                      "value": user ? user.email : 'Sin usuario',
                      "inline": true
                  },
                  {
                      "name": "Usuario (uid)",
                      "value": user ? user.uid : 'Sin usuario',
                      "inline": false
                  }
              ],
              "thumbnail": {
                "url": user ? user.photoURL : 'https://c.disquscdn.com/uploads/forums/742/6587/avatar92.jpg',
                "height": 0,
                "width": 0
              }
            }]
          }

        fetch(process.env.REACT_APP_DISCORD_REPORT_EPISODE_WEBHOOK, {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSending(false);
            setSend(true);
            setReason(t('reasons.1'));
            setDescription('');
        }).catch((err) => {
            setSending(false);
            setSend(false);
        })
    }
        
    return (
        <div>
            {send ?
                <div className="flex justify-center align-center" style={{
                    minHeight: '10rem',
                    padding: '1rem'
                }}>
                    <h2 className='font-branch'>{t('words.thanks')}</h2>
                </div>
                :
                <Form
                    loading={sending}
                    type={"send"}
                    action={handleSend}
                >
                    <div className="flex flow-column gap-big">
                        <FormControl size='small'>
                            <Select
                                className='select'
                                value={reason}
                                name="reason"
                                onChange={handleChange}
                                required
                            >
                                <MenuItem value={t('reasons.1')} >{t('reasons.1')}</MenuItem>
                                <MenuItem value={t('reasons.2')} >{t('reasons.2')}</MenuItem>
                                <MenuItem value={t('reasons.3')} >{t('reasons.3')}</MenuItem>
                                <MenuItem value={t('reasons.4')} >{t('reasons.4')}</MenuItem>
                                <MenuItem value={t('reasons.5')} >{t('reasons.5')}</MenuItem>
                            </Select>
                        </FormControl>
                        <Input 
                            type="text-area"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            variant={1}
                            label={true}
                            labelText={"Descripción (Opcional)"}
                        />
                    </div>
                    <div className='buttons-group flex justify-end gap-normal' style={{
                        marginTop: '1rem',
                    }}>
                        <AoButton 
                            title={t('words.cancel')}
                            variant={"outline size-bigger"}
                            action={closeReportEpisode}
                        />
                        <AoButton 
                            title={t('words.send')}
                            variant={"special-2"}
                            loading={sending}
                            submit={true}
                            style={{
                                minWidth: '150px',
                                flex: 'inherit'
                            }}
                        />
                    </div>
                </Form>
            }
        </div>
    )
}
