import { createContext, useState } from "react";

export const AlertsContext = createContext();

export const AlertsProvider = ({ children }) => {
    const [ alerts, setAlerts ] = useState([]);

    function addAlert(title, type, variant, close, link, linkText) {
        let id;
        if (alerts.length > 0) {
            id = alerts.slice(-1)[0].id + 1;
        } else {
            id = 1;
        }
        
        setAlerts([...alerts, {
            title: title,
            id: id,
            type: type,
            variant: variant,
            close: close,
            link: link,
            linkText: linkText
        }])
    }

    return (
        <AlertsContext.Provider
            value={{
                alerts,
                setAlerts,
                addAlert
            }}
        >
            {children}
        </AlertsContext.Provider>
    )
}