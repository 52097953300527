import React, { useContext, useState } from 'react'
import Form from '../../../components/form/Form'
import Input from '../../../components/form/Input'
import { AuthContext } from '../../../context/Auth'
import AoButton from '../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';

export default function Report() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    const [ title, setTitle ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ image, setImage ] = useState('');
    
    const [ sending, setSending ] = useState(false);
    const [ send, setSend ] = useState(false);

    const handleSend = () => {
        setSending(true);
        const content = {
            "embeds": [{
              "title": `¡Nuevo reporte!`,
              "description": `## ${title} \n ${description}`,
              "color": 0xff5441,
              "fields": [
                  {
                      "name": "Usuario",
                      "value": user.name,
                      "inline": true
                  },
                  {
                      "name": "Correo",
                      "value": user.email,
                      "inline": true
                  },
                  {
                      "name": "uid",
                      "value": user.uid,
                      "inline": false
                  }
              ],
              "thumbnail": {
                "url": `${user.photoURL}`,
                "height": 0,
                "width": 0
              }
            }]
          }

        fetch(process.env.REACT_APP_DISCORD_REPORT_WEBHOOK, {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSending(false);
            setSend(true);
            setTitle('');
            setDescription('');
        }).catch((err) => {
            setSending(false);
        })
    }

    if (send){
        return(
            <div className='settings-message'>
                <h2>{t('words.thanks')}</h2>
                <div className="buttons">
                    <AoButton 
                        title={"Enviar otra"}
                        variant={"outline"}
                        action={() => {
                            setSend(false);
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <>
            <SEO title={`${t('settings.nav.community')} - ${t('settings.nav.report')}`} />
            <Form
                loading={sending}
                type={"send"}
                action={handleSend}
                bg={"https://i.ibb.co/6n9C60M/report-banner.png"}
            >
                <div className="flex flow-column gap-big" style={{marginBottom: '1rem'}}>
                    <Input 
                        type="text"
                        value={title}
                        variant={1}
                        label={true}
                        onChange={(e) => setTitle(e.target.value)}
                        labelText={"*Reporte"}
                    />
                    <Input 
                        type="text-area"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant={1}
                        label={true}
                        labelText={"Descripción (Opcional)"}
                    />
                </div>
                <AoButton 
                    title={t('words.send')}
                    variant={"special-2"}
                    loading={sending}
                    submit={true}
                />
            </Form>
        </>
    )
}
