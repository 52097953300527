import { Avatar, Button, IconButton } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Navigation, Pagination } from 'swiper/modules';
import AoButton from '../buttons/Button';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../context/Auth';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../services/firebase/accounts';
import { updateProfile } from 'firebase/auth';
import { ModalsContext } from '../../context/Modals';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const data = [
    {
        "name": "Oshi no Ko",
        "images":[
            {
                "ch": "Ai",
                "url": "https://i.ibb.co/vBnPzLt/Ai.jpg"
            },
            {
                "ch": "Aquamarine",
                "url": "https://i.ibb.co/ng4jQ0c/Aquamarine.jpg"
            },
            {
                "ch": "Ruby",
                "url": "https://i.ibb.co/vVytLMK/Ruby.jpg"
            }
        ]
    },
    {
        "name": "Demon Slayer: Kimetsu no Yaiba",
        "images":[
            {
                "ch": "Nezuko",
                "url": "https://i.ibb.co/jDSsrtT/Nezuko.jpg"
            },
            {
                "ch": "Tanjiro",
                "url": "https://i.ibb.co/Y0HrzCg/Tanjiro.jpg"
            },
            {
                "ch": "Inosuke",
                "url": "https://i.ibb.co/vc7rN6G/Inosuke.jpg"
            },
            {
                "ch": "Zenitsu",
                "url": "https://i.ibb.co/6FNc6YT/Zenitsu.jpg"
            },
            {
                "ch": "Tomioka",
                "url": "https://i.ibb.co/qd4hdy6/Tomioka.jpg"
            },
            {
                "ch": "Rengoku",
                "url": "https://i.ibb.co/dQq9Dy4/Rengoku.jpg"
            },
            {
                "ch": "Shinobu",
                "url": "https://i.ibb.co/xmZz39t/Shinobu.jpg"
            },
            {
                "ch": "Uzui",
                "url": "https://i.ibb.co/xMBryDJ/Uzui.jpg"
            },
            {
                "ch": "Mitsuri",
                "url": "https://i.ibb.co/3YLvbDZ/Mitsuri.jpg"
            },
            {
                "ch": "Tokito",
                "url": "https://i.ibb.co/HYXQd1S/Tokito.jpg"
            },
            {
                "ch": "El patron",
                "url": "https://i.ibb.co/LtPSBD7/El-patron.jpg"
            },
            {
                "ch": "Kibutsuji",
                "url": "https://i.ibb.co/v4pFkLN/Kibutsuji.jpg"
            },
            {
                "ch": "Doma",
                "url": "https://i.ibb.co/LZWrwgj/Doma.jpg"
            }
        ]
    },
    {
        "name": "Chainsaw Man",
        "images":[
            {
                "ch": "Power",
                "url": "https://i.ibb.co/6wvSpMF/Power.jpg"
            },
            {
                "ch": "Makima",
                "url": "https://i.ibb.co/W3LxzbM/Makima.jpg"
            },
            {
                "ch": "Kobeni",
                "url": "https://i.ibb.co/6XwHSGQ/Kobeni.jpg"
            },
            {
                "ch": "Himeno",
                "url": "https://i.ibb.co/Yd48cc2/Himeno.jpg"
            },
            {
                "ch": "Denji",
                "url": "https://i.ibb.co/WGSrQr7/Denji.jpg"
            },
            {
                "ch": "Aki",
                "url": "https://i.ibb.co/yPVSffC/Aki.jpg"
            },
            {
                "ch": "Angel Devil",
                "url": "https://i.ibb.co/bJ4v2KM/Angel-Devil.jpg"
            }
        ]
    },
    {
        "name": "Bocchi the Rock!",
        "images":[
            {
                "ch": "Kita",
                "url": "https://i.ibb.co/SP8c4rd/Kita.jpg"
            },
            {
                "ch": "Hirori",
                "url": "https://i.ibb.co/WFBG1Vx/Hitori.jpg"
            },
            {
                "ch": "Nijika",
                "url": "https://i.ibb.co/Zzdb4zD/Nijika.jpg"
            },
            {
                "ch": "Ryo",
                "url": "https://i.ibb.co/9bxFRv4/Ryo.jpg"
            }
        ]
    },
    {
        "name": "Kono Suba!",
        "images":[
            {
                "ch": "Megumin",
                "url": "https://i.ibb.co/yntyQpJ/Megumin.jpg"
            },
            {
                "ch": "Aqua",
                "url": "https://i.ibb.co/Y8F89nJ/Aqua.jpg"
            },
            {
                "ch": "Darkness",
                "url": "https://i.ibb.co/DbPQCCB/Darkness.jpg"
            },
            {
                "ch": "Kazuma",
                "url": "https://i.ibb.co/YhSndB2/Kazuma.jpg"
            }
        ]
    },
    {
        "name": "Spy X family",
        "images":[
            {
                "ch": "Yor",
                "url": "https://i.ibb.co/VCF7wcj/Yor.jpg"
            },
            {
                "ch": "Loid",
                "url": "https://i.ibb.co/JkTr7Sk/Loid.jpg"
            },
            {
                "ch": "Anya",
                "url": "https://i.ibb.co/1qVzz76/Anya.jpg"
            }
        ]
    }
]

const dataBanner = [
    {
        "name": "Jujutsu Kaisen",
        "images": ["https://i.ibb.co/mrsfmGg/e86d9228af15-1.webp", "https://i.ibb.co/nzJKp5Q/e86d9228af15-2.webp", "https://i.ibb.co/16BL1NR/e86d9228af15-3.webp"]
    },
    {
        "name": "Chainsaw Man",
        "images":["https://i.ibb.co/j5tfF2y/e7914a9e4913-1.webp", "https://i.ibb.co/tYNTYrm/e7914a9e4913-3.webp", "https://i.ibb.co/5KD12w6/e7914a9e4913-4.webp", "https://i.ibb.co/QCBBZ9C/e7914a9e4913-2.webp"]
    },
    {
        "name": "Attack on Titan",
        "images": ["https://i.ibb.co/jMph6Zv/8b31408d473c-1.webp", "https://i.ibb.co/2Sb4StY/8b31408d473c-2.webp", "https://i.ibb.co/fSWXqQ6/8b31408d473c-3.webp"]
    }
]

export default function SwiperAvatars({ type }) {
    const { t } = useTranslation();
    const { user, setUser } = useContext(AuthContext);
    const { closeChangePfp, closeCustomizeSelector } = useContext(ModalsContext);
    const [ image, setImage ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(false);

    const handleImage = (url) => {
        setImage(url);
    }

    const handleSave = async() => {
        setLoading(true);
        let variant;
        if (type === "avatar"){
            variant = "pfp";
        } else {
            variant = "pfb";
        }
        const response = await fetch(image);
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', {type: blob.type});

        const userRef = ref(storage, `usuarios/${user.uid}/${variant}`);
        uploadBytes(userRef, file)
            .then((snapshot) => {
                getDownloadURL(snapshot.ref)
                    .then((url) => {
                        if (variant === "pfp") {
                            updateProfile(user, {
                                photoURL: url
                            }).then(() => {
                                closeChangePfp();
                                closeCustomizeSelector();
                                setLoading(false);
                            })
                        } else if (variant === "pfb") {
                            const arr = user;
                            arr.pfb = url;
                            setUser(arr);
                            closeChangePfp();
                            closeCustomizeSelector();
                            setLoading(false);
                        }
                    })
            })
    }

    useEffect(() => {
        if (type === "avatar") {
            setImage(user.photoURL);
        } else if (type === "banner") {
            setImage(user.pfb);
        }
    }, []);

    useEffect(() => {
        if (type === "avatar") {
            setDisabled(user.photoURL === image);
        } else if (type === "banner") {
            setDisabled(user.pfb === image)
        }
    })

    if (type === "avatar") {
        return (
            <div className={`swiper-customize ${loading && 'loading'}`}>
                <div className="preview-layout">
                    <div className="avatar-layout">
                        <Avatar className='avatar image' src={image} />
                    </div>
                    <div className='avatar-buttons'>
                        <AoButton 
                            title={t('words.cancel')}
                            variant={"outline"}
                            className={"size-big"}
                            action={() => setImage(user.photoURL)}
                        />
                        <AoButton 
                            title={t('words.save')}
                            className={"size-big"}
                            variant={"special-2"}
                            disabled={user.photoURL === image}
                            loading={loading}
                            action={handleSave}
                        />
                    </div>
                </div>
                {data.map((doc, i) => (
                    <div key={i} className='section'>
                        <h3>{doc.name}</h3>
                        <div className="swiper-layout">
                            <div className={`swiper-button button-prev-${i}`}>
                                <AoButton 
                                    icon={<ArrowForwardIos style={{rotate: '180deg'}} />}
                                />
                            </div>
                            <Swiper
                                slidesPerView={"auto"}
                                className='swiper'
                                navigation={{
                                    nextEl: `.button-next-${i}`,
                                    prevEl: `.button-prev-${i}`,
                                    disabledClass: 'disabled',
                                    clickable: true,
                                }}
                                modules={[Pagination, Navigation]}
                            >
                                {doc.images.map((doc, i) => (
                                    <SwiperSlide key={i}>
                                        <Button className='button' onClick={() => handleImage(doc.url)}>
                                            <div className="img">
                                                <LazyLoadImage 
                                                    src={doc.url}
                                                    width={76}
                                                    height={76}
                                                    effect='blur'
                                                    alt={`Aodesu | ${doc.ch}`}
                                                />
                                            </div>
                                            <span>{doc.ch}</span>
                                        </Button>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className={`swiper-button button-next-${i}`}>
                                <AoButton 
                                    variant="primary"
                                    icon={<ArrowForwardIos />}
                                />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

  
    return (
        <div className={`swiper-customize banner ${loading && 'loading'}`}>
            <div className="preview-layout">
                <div className="avatar-layout">
                    <div className="banner" style={{ backgroundImage: `url("${image}")`}}></div>
                </div>
                <div className='avatar-buttons'>
                    <AoButton 
                        title={t('words.cancel')}
                        variant={"outline"}
                        className={"size-big"}
                        action={() => {
                            if (type === "avatar") {
                                setImage(user.photoURL);
                            } else if (type === "banner"){
                                setImage(user.pfb);
                            }
                        }}
                    />
                    <AoButton 
                        title={t('words.save')}
                        className={"size-big"}
                        variant={"special-2"}
                        disabled={disabled}
                        loading={loading}
                        action={handleSave}
                    />
                </div>
            </div>
            {dataBanner.map((doc, i) => (
                <div key={i} className='section'>
                    <h3>{doc.name}</h3>
                    <div className="swiper-layout">
                        <div className={`swiper-button button-prev-${i}`}>
                            <AoButton 
                                icon={<ArrowForwardIos style={{rotate: '180deg'}} />}
                            />
                        </div>
                        <Swiper
                            slidesPerView={2}
                            className='swiper'
                            navigation={{
                                nextEl: `.button-next-${i}`,
                                prevEl: `.button-prev-${i}`,
                                disabledClass: 'disabled',
                                clickable: true,
                            }}
                            modules={[Pagination, Navigation]}
                        >
                            {doc.images.map((image, i) => (
                                <SwiperSlide key={i}>
                                    <Button className='button' onClick={() => handleImage(image)}>
                                        <div className="img">
                                            <LazyLoadImage 
                                                src={image}
                                                effect='blur'
                                                alt={`Aodesu banner - ${doc.name}`}
                                            />
                                        </div>
                                    </Button>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={`swiper-button button-next-${i}`}>
                            <AoButton 
                                variant="primary"
                                icon={<ArrowForwardIos />}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}
