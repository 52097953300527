import { AdsClick, ArrowDownward, DarkMode, Edit, History, IosShare, Lock, ManageAccounts, People, PrivacyTip, Shield, WavingHand } from '@mui/icons-material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom';
import './Settings.scss';
import { Avatar, Button, CircularProgress, ClickAwayListener, IconButton, Tooltip } from '@mui/material';
import { AuthContext } from '../../context/Auth';
import AoButton from '../../components/buttons/Button';
import { auth } from '../../services/firebase/accounts';
import { onAuthStateChanged } from 'firebase/auth';
import GiftIcon from '../../images/Gift';
import { useTranslation } from 'react-i18next';
import SettingsHeader from './SettingsHeader';
import { ModalsContext } from '../../context/Modals';
import AoIconButton from '../../components/buttons/IconButton';
import PremiumLogo from '../../images/Premium';
import PullToRefresh from 'react-simple-pull-to-refresh';

export const SettingsIndex = () => {
    return(
        <div className='settings-home flex justify-center align-center' style={{ flex: 1, height: '100%'}}>
            <WavingHand sx={{ fontSize: '3.5rem'}} />
        </div>
    )
}

export default function Settings() {
    const location = useLocation();
    const navigate = useNavigate();
    const pfpInputRef = useRef(null);
    const { t } = useTranslation();
    const { user, userLoading, getUserData } = useContext(AuthContext);
    const { openChangePfp, openCustomizeSelector, openShare, openOnlyPremium } = useContext(ModalsContext);

    const [ tooltip, setTooltip ] = useState(false);

    const handleTooltipClose = () => {
        setTooltip(false);
    }
    const handleTooltipOpen = () => {
        setTooltip(true);
    }

    const handlePfpChange = (e) => {
        openChangePfp(URL.createObjectURL(e.target.files[0]));
    }

    const handleRefresh = () => {
        return new Promise(res => {
            getUserData(auth.currentUser.uid, auth.currentUser);
            setTimeout(() => {
                res();
            }, 500)
        })
    }

    /* useEffect(() => {
        if (location.pathname === '/settings' || location.pathname === '/settings/') {
            navigate('/settings/preferences');
        }
    }, [location, navigate]); */

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/auth/login')
            }
        })
    }, [navigate]);

    return (
        <PullToRefresh
            pullingContent={<div className='pull-to-refresh body'><ArrowDownward /></div>}
            refreshingContent={<div className='pull-to-refresh body'><CircularProgress /></div>}
            onRefresh={handleRefresh}
            pullDownThreshold={80}
            maxPullDownDistance={80}
            resistance={4}
        >
            <div className="settings-account">
                {userLoading ?
                    <>
                        <div className="account-banner">
                            <div className="account-banner-sizer">
                                <div className="account-banner-image"></div>
                            </div>
                        </div>
                        <div className="account-user">
                            <AoIconButton 
                                className={"avatar-body"}
                                type={"change-image"}
                                content={<div className="edit-icon"><Edit /></div>}
                            />
                        </div>
                    </>
                :
                    <>
                        <Button 
                            className="account-banner" 
                            onClick={() => { 
                                if(user?.badges.includes('premium')) {
                                    openCustomizeSelector('banner');
                                } else {
                                    openOnlyPremium()}
                                }}>
                            <div className="account-banner-sizer">
                                <div className="account-banner-badges">
                                    {user?.badges.includes('private') && 
                                        <Tooltip title="Esta cuenta es privada.">
                                            <div className='badge'>
                                                <Lock />
                                            </div>
                                        </Tooltip>
                                    }
                                    {user?.badges.includes('admin') && 
                                    <Tooltip title="Admin">
                                        <div className='badge'>
                                            <Shield />
                                        </div>
                                    </Tooltip>
                                    }
                                    {user?.badges.includes('premium') && 
                                        <Tooltip title="Premium">
                                            <div className='badge'>
                                                <PremiumLogo />
                                            </div>
                                        </Tooltip>
                                    }
                                </div>
                                <div className="account-banner-image" style={{ backgroundImage: `url("${user?.pfb}")`}}></div>
                                <div className="edit-icon">
                                    <Edit />
                                </div>
                            </div>
                        </Button>
                        <div className="account-user">
                            <AoIconButton 
                                className={"avatar-body"}
                                type={"change-image"}
                                avatar={user?.photoURL}
                                alt={user?.name}
                                action={() => openCustomizeSelector('avatar')}
                                content={<div className="edit-icon"><Edit /></div>}
                            />
                            
                            <input 
                                type='file'
                                accept="image/*"
                                id="input-avatar"
                                ref={pfpInputRef}
                                onChange={handlePfpChange}
                            />
                            <div className="data">
                                <div className="text">
                                    <div className="upper">
                                        {user?.private && 
                                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                                <div>
                                                    <Tooltip 
                                                        title="Esta cuenta es privada."
                                                        PopperProps={{
                                                            disablePortal: true,
                                                        }}
                                                        onClose={handleTooltipClose}
                                                        open={tooltip}
                                                        disableFocusListener
                                                        disableHoverListener
                                                        disableTouchListener
                                                    >
                                                        <IconButton className='IconButton' onClick={handleTooltipOpen}>
                                                            <Lock />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </ClickAwayListener>
                                        }
                                        <h2>{user?.displayName ? user?.displayName : 'Sin nombre.'}</h2>
                                    </div>
                                    <span>{`@${user?.name}`}</span>
                                </div>
                                <div className="buttons">
                                    {user?.name &&
                                        <>
                                            <AoButton 
                                                title="Ver perfil"
                                                style={{
                                                    padding: '0 .5rem 0 .25rem'
                                                }}
                                                type={"link"}
                                                to={`/u/${user?.name}`}
                                                icon={<Avatar className='avatar image' src={user?.photoURL} alt={user?.name} sx={{ width: 27, height: 27}} />}
                                                variant="rounded highlight"
                                            />
                                            <AoButton 
                                                action={() => openShare(`/u/${user?.name}`)}
                                                title={t('words.share')}
                                                icon={<IosShare />}
                                                variant="rounded highlight"
                                            />
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                }
                <div className="settings-zone">
                    <nav className='settings-navigation'>
                        <div className="section">
                            <h3>{t('settings.nav.general')}</h3>
                            <SettingsNavigation 
                                to="/settings/preferences"
                                icon={<DarkMode />}
                                title={t('settings.nav.preferences')}
                            />
                            <SettingsNavigation 
                                to="/settings/account"
                                icon={<ManageAccounts />}
                                title={t('settings.nav.account')}
                            />
                            <SettingsNavigation 
                                to="/settings/privacy"
                                icon={<PrivacyTip />}
                                title={t('settings.nav.privacy')}
                            />
                            {/* <SettingsNavigation 
                                to="/settings/ads"
                                icon={<AdsClick />}
                                title={t('settings.nav.ads')}
                            /> */}
                            <div className="divider"></div>
                            <SettingsNavigation 
                                to="/settings/premium"
                                exact={true}
                                icon={<PremiumLogo style={{color: '#6a8fff'}} />}
                                title={t('settings.nav.premium')}
                            />
                        </div>
                        <div className="section">
                            <SettingsNavigation 
                                to="/settings/community"
                                exact={true}
                                icon={<People />}
                                title={t('settings.nav.community')}
                            />
                        </div>
                        <div className="divider"></div>
                        <div className="section">
                            <div style={{ padding: '.5rem'}}><span>{`© aodesu ${new Date().getFullYear()}`}</span></div>
                        </div>
                    </nav>
                    <div className={`settings-content ${location.pathname.split('/')[2] && 'opened'}`}>
                        {location.pathname.split('/')[2] && <SettingsHeader />}
                        <div className="settings-content-layout">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </PullToRefresh>
    )
}

const SettingsNavigation = ({ to, icon, title, exact }) => {
    return(
        <NavLink
            to={to}
            className="button big navigation"
            end={exact}
        >
            <div className="button-icon">
                {icon}
            </div>
            <span className="button-text">{title}</span>
        </NavLink>
    )
}