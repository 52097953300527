import { Avatar, ClickAwayListener, IconButton, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth'
import AoButton from '../../components/buttons/Button';
import { useLocation, useParams } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore, storage } from '../../services/firebase/accounts';
import { Edit, FavoriteBorder, IosShare, Lock, Shield } from '@mui/icons-material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { getDownloadURL, ref } from 'firebase/storage';
import { ModalsContext } from '../../context/Modals';
import { t } from 'i18next';
import SEO from '../../services/seo';
import PremiumLogo from '../../images/Premium';

export default function User() {
    const { user } = useContext(AuthContext);
    const { openShare } = useContext(ModalsContext);
    const location = useLocation();
    const params = useParams();

    const [ currentUser, setCurrentUser ] = useState("loading");
    const [ userData, setUserData ] = useState(null);

    const [ tooltip, setTooltip ] = useState(false);

    const handleTooltipClose = () => {
        setTooltip(false);
    }
    const handleTooltipOpen = () => {
        setTooltip(true);
    }

    async function getUserData() {
        const usersRef = collection(firestore, `usuarios`);
        const q = query(usersRef, where("name", "array-contains", params.uid));

        const querySnapshot = await getDocs(q);
        const queryList = querySnapshot.docs[0].data()
        const arr = queryList;

        arr.displayName = arr.name[1];
        arr.name = arr.name[0];
        arr.private = arr.badges.includes("private");

        const pfpdOldRef = ref(storage, `usuarios/${querySnapshot.docs[0].id}/pfp.jpg`);
        const pfpRef = ref(storage, `usuarios/${querySnapshot.docs[0].id}/pfp`);
        getDownloadURL(pfpRef).then((url) => {
            arr.photoURL = url;
            getBg(arr);
        }).catch(() => {
            getDownloadURL(pfpdOldRef).then((url) => {
                arr.photoURL = url;
                getBg(arr);
            }).catch(() => {
                arr.photoURL = null;
                getBg(arr);
            })
        })

        function getBg(arr) {
            const newArr = arr;

            if (newArr.badges.includes("premium")) {
                const pfbdOldRef = ref(storage, `usuarios/${querySnapshot.docs[0].id}/pfb.jpg`);
                const pfbRef = ref(storage, `usuarios/${querySnapshot.docs[0].id}/pfb`);
                getDownloadURL(pfbRef).then((url) => {
                    newArr.pfb = url;
                    setData(newArr);
                }).catch(() => {
                    getDownloadURL(pfbdOldRef).then((url) => {
                        newArr.pfb = url;
                        setData(newArr);
                    }).catch(() => {
                        newArr.pfb = null;
                        setData(newArr);
                    })
                })
            } else {
                setData(newArr);
            }
        }

        function setData(arr) {
            setUserData(arr);
            setCurrentUser("other");
        }
    }

    useEffect(() => {
        onAuthStateChanged(auth, (actualSession) => {
            if (!actualSession) {
                getUserData();
            } else {
                if (user) {
                    if (user?.name === params.uid) {
                        console.log(1)
                        setCurrentUser("actual");
                        setUserData(user)
                    } else {
                        getUserData();
                        console.log(2)
                    }
                }
            }
        })

        return() => {
            setCurrentUser("loading");
            setUserData(null);
        }
    }, [user, params.uid])

    if (currentUser === "loading") {
        return (
            <div className='settings-account'>
                <div className="account-banner"></div>
                <div className="account-banner-sizer">
                    <div className="account-banner-image"></div>
                </div>
                <div className="account-user">
                    <div>
                        <div className="avatar image" style={{ width: 120, height: 120}}></div>
                    </div>
                    <div className="data">
                        <div className="text">
                            <div className="upper">
                                <div className="h2"></div>
                            </div>
                            <span className='span'></span>
                        </div>
                        <div className="buttons">
                            <div className="button rounded"></div>
                            <div className="button rounded"></div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (currentUser === "actual" || currentUser === "other") {
        return(
            <>
                <SEO title={`@${userData?.name}`} />
                <div className='settings-account'>
                    {userData?.badges &&
                        <>
                            {userData?.badges.includes("premium") &&
                                <>
                                    {userData.pfb !== "" && 
                                        <div className="account-banner">
                                            <div className="account-banner-sizer">
                                                <div className="account-banner-badges">
                                                    {userData?.badges.includes('private') && 
                                                        <Tooltip title="Esta cuenta es privada.">
                                                            <div className='badge'>
                                                                <Lock />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    {userData?.badges.includes('admin') && 
                                                        <Tooltip title="Admin">
                                                            <div className='badge'>
                                                                <Shield />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                    {userData?.badges.includes('premium') && 
                                                        <Tooltip title="Premium">
                                                            <div className='badge'>
                                                                <PremiumLogo />
                                                            </div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                                <div className="account-banner-image" style={{ backgroundImage: `url("${userData.pfb}")`}}></div>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                    <div className="account-user">
                        <div>
                            <Avatar src={userData.photoURL} alt={userData.name} className='avatar image' sx={{ width: 120, height: 120, fontSize: '3rem'}} />
                        </div>
                        <div className="data">
                            <div className="text">
                                <div className="upper">
                                    {userData.private && 
                                        <ClickAwayListener onClickAway={handleTooltipClose}>
                                            <div>
                                                <Tooltip 
                                                    title="Esta cuenta es privada."
                                                    PopperProps={{
                                                        disablePortal: true,
                                                    }}
                                                    onClose={handleTooltipClose}
                                                    open={tooltip}
                                                    disableFocusListener
                                                    disableHoverListener
                                                    disableTouchListener
                                                >
                                                    <IconButton className='IconButton' onClick={handleTooltipOpen}>
                                                        <Lock />
                                                    </IconButton>
                                                </Tooltip>
                                            </div>
                                        </ClickAwayListener>
                                    }
                                    <h2>{userData.displayName ? userData.displayName : t('error.user/no-name')}</h2>
                                </div>
                                <span>{`@${userData.name}`}</span>
                            </div>
                            <div className="buttons">
                                {currentUser === "actual" ?
                                    <>
                                        <AoButton 
                                            title={t('words.customize')}
                                            icon={<Edit />}
                                            type={"link"}
                                            to={"/settings"}
                                            variant="rounded highlight"
                                        />
                                    </>
                                    :
                                    <>
                                        <AoButton
                                            title={t('words.follow')}
                                            icon={<FavoriteBorder />}
                                            variant="rounded highlight-2"
                                        />
                                    </>
                                }
                                <AoButton 
                                    title={t('words.share')}
                                    action={() => openShare(location.pathname)}
                                    icon={<IosShare />}
                                    variant="rounded highlight"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
    
    return (
        <div>
            Error
        </div>
    )
}
