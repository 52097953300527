import React, { useContext, useState } from 'react'
import { ModalsContext } from '../../context/Modals'
import AoButton from '../buttons/Button';
import { useTranslation } from 'react-i18next';
import { OpenInNew } from '@mui/icons-material';
import { adsFirestore } from '../../services/firebase/ads';
import { doc, increment, updateDoc } from 'firebase/firestore';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function AdsModal() {
    const { t } = useTranslation();
    const { closeAdModal, adModalData } = useContext(ModalsContext);

    const [ loading, setLoading ] = useState(false);

    function openLink() {
        setLoading(true);

        const statisticsRef = doc(adsFirestore, `ads/${adModalData.id}/statistics/interactions`);

        updateDoc(statisticsRef, {
            link_clicks: increment(1)
        }).then(() => {
            window.open(adModalData.link, '_blank', 'noopener,noreferrer');
            setLoading(false);
        })

    }

    const banners = adModalData.images.banners;

    return (
        <>
            <div className="ad-body">
                <p>{adModalData.description}</p>
                <div className="banner">
                    {banners['1'] &&
                        <LazyLoadImage 
                            src={banners['1']} 
                            effect='blur'    
                        />
                    }
                </div>
            </div>
            <div className="buttons-layout">
                <AoButton 
                    title={t('words.close')}
                    variant={"outlined"}
                    size={"big"}
                    action={closeAdModal}
                />
                <AoButton 
                    title={t('modal.ad.go')}
                    variant={"special-2"}
                    size={"big"}
                    icon={<OpenInNew />}
                    action={openLink}
                    loading={loading}
                />
            </div>
        </>
    )
}
