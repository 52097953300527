import React, { useContext, useEffect, useState } from 'react'
import AoButton from '../../../components/buttons/Button';
import { DarkMode, Explicit, Language, Speed } from '@mui/icons-material';
import LazySettings from '../LazySettings';
import { AuthContext } from '../../../context/Auth';
import { SettingsContext } from '../../../context/Settings';
import { AlertsContext } from '../../../context/Alerts';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';

export default function Preferences() {
  const { t } = useTranslation();
  const [ isLoading, setLoading ] = useState(true);
  const { user } = useContext(AuthContext);
  const { R17, Rmd, Rx, handleR17, handleRmd, handleRx } = useContext(SettingsContext);
  const { addAlert } = useContext(AlertsContext);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [])

  console.log(user?.age)

  if (isLoading) {
    return (
      <LazySettings />
    )
  }

  return (
    <>
      <SEO title={t('settings.nav.preferences')} />
      <AoButton 
        variant={"left"}
        icon={<DarkMode />}
        title={t('settings.nav.appearance')}
        description={t('settings.nav.appearance-desc')}
        type={"link"}
        to="appearance"
        hasRightArrow={true}
      />    
      <AoButton 
        variant={"left"}
        icon={<Language />}
        title={t('settings.nav.language')}
        description={t('settings.nav.language-desc')}
        type={"link"}
        to="language"
        hasRightArrow={true}
      />  {/*   
      <AoButton 
        variant={"left"}
        icon={<Speed />}
        title={t('settings.nav.performance')}
        description={t('settings.nav.performance-desc')}
        type={"link"}
        to="language"
        hasRightArrow={true}
      />    */}
      <div className="divider"></div>
      <AoButton 
        variant={"left"}
        icon={<Explicit />}
        title={`R-17+ - ${t('settings.nav.ratings.R-17+')}`}
        description={t('settings.nav.ratings.R-17+-desc')}
        type={"toggle"}
        action={() => {
          if (!user?.age || user?.age < 18) {
            addAlert('No tienes +18 años de edad.', "negative", "filled", "auto")
          } else {
            handleR17();
          }
        }}
        className={R17 && 'active'}
        disabled={!user?.age || user?.age < 18}
        
      />   
      <AoButton 
        variant={"left"}
        icon={<Explicit />}
        title={`R+ - ${t('settings.nav.ratings.R+')}`}
        description={t('settings.nav.ratings.R+-desc')}
        type={"toggle"}
        action={() => {
          if (!user?.age || user?.age < 18) {
            addAlert('No tienes +18 años de edad.', "negative", "filled", "auto")
          } else {
            handleRmd();
          }
        }}
        className={Rmd && 'active'}
        disabled={!user?.age || user?.age < 18}
        
      />   
      <AoButton 
        variant={"left"}
        icon={<Explicit />}
        title={`Rx - ${t('settings.nav.ratings.Rx')}`}
        description={t('settings.nav.ratings.Rx-desc')}
        type={"toggle"}
        action={() => {
          if (!user?.age || user?.age < 18) {
            addAlert('No tienes +18 años de edad.', "negative", "filled", "auto")
          } else {
            handleRx();
          }
        }}
        className={Rx &&'active'}
        disabled={!user?.age || user?.age < 18}
        
      />   
    </>
  )
}
