import React, { useContext } from 'react';
import SwiperHome from '../components/swiper/SwiperHome';
import './index.scss';
import SEO from '../services/seo';
import { useTranslation } from 'react-i18next';
import HomeInfiniteScroll from '../components/home/HomeInfiniteScroll';
import Modal from '../components/modals/Modal';
import { ModalsContext } from '../context/Modals';
import { useMediaQuery } from '@mui/material';
import AoButton from '../components/buttons/Button';
import { IosShare } from '@mui/icons-material';

export default function Home() {
  const { t } = useTranslation();
  const { serieCard, serieCardState, serieCardData, closeSerieCard, serieCardType, openShare } = useContext(ModalsContext);
  const media = useMediaQuery('(max-width: 50em)');
  
  return (
    <>
      <SEO title={t('SEO.home.title')} description={t('SEO.home.desc')} />
      <SwiperHome />
      <HomeInfiniteScroll />
      {media &&
        <>
          {serieCard &&
            <Modal 
              variant={'user-menu'} 
              title={serieCardType === 'episode'
                      ? `${t('words.shorts.season')}${serieCardData.info.season.id} ${t('words.shorts.episode')}${serieCardData.id} - ${serieCardData.info.season.name}` 
                      : `${serieCardData.name}`} 
              close={closeSerieCard} 
              visible={serieCardState}
            >
              <div className='header-menu' style={{ padding: '.5rem'}}>
                {serieCardType === '' &&
                  <>
                    <AoButton 
                      icon={<IosShare />}
                      title={t('words.share')}
                      size={"big"}
                      action={() => {
                        closeSerieCard();
                        openShare(`/anime/${serieCardData.id}`);
                      }}
                    />
                  </>
                }
                {serieCardType === 'episode' &&
                  <div className='flex flow-column gap-normal'>
                    <AoButton 
                      icon={<IosShare />}
                      title={t('words.share episode')}
                      size={"big"}
                      action={() => {
                        closeSerieCard();
                        openShare(`/watch/${serieCardData.info.serie.id}-${serieCardData.info.season.id}-${serieCardData.id}`);
                      }}
                    />
                    <AoButton 
                      icon={<IosShare />}
                      title={t('words.share anime')}
                      size={"big"}
                      action={() => {
                        closeSerieCard();
                        openShare(`/anime/${serieCardData.info.serie.id}-${serieCardData.info.season.id}`);
                      }}
                    />
                  </div>
                }
              </div>
            </Modal>
          }
        </>
      }
    </>
  )
}
