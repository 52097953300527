import React, { useState, useEffect, useContext } from 'react';
import AoButton from '../../../components/buttons/Button';
import { AutoDelete, DeleteForever, Google, Key, Person } from '@mui/icons-material';
import LazySettings from '../LazySettings';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';
import Modal from '../../../components/modals/Modal';
import DeleteAccount from '../../../components/modals/DeleteAccount';

export default function Account() {
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState(true);


    const [ modal, setModal ] = useState(false);
    const [ modalState, setModalState ] = useState(null);
    const [ modalType, setModalType ] = useState('');

    function openModal(type) {
        setModal(true);
        setModalType(type);

        setTimeout(() => {
            setModalState('show');
        }, 100)
    }

    function closeModal() {
        setModalState(null);
        setTimeout(() => {
            setModal(false);
            setModalType('');
        }, 100)
    }

    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
    }, []);


    if (isLoading) {
        return (
        <LazySettings />
        )
    }

    return (
        <>
            <SEO title={`${t('settings.nav.account')}`} />
            <AoButton 
                variant={"left"}
                icon={<Person />}
                title={t('settings.nav.info')}
                description={t('settings.nav.info-desc')}
                type={"link"}
                to={"information"}
                hasRightArrow={true}
            />
            <div className="divider"></div>
            {/* <AoButton 
                variant={"left"}
                icon={<Key />}
                title={t('settings.nav.password')}
                description={t('settings.nav.password-desc')}
                type={"link"}
                to={"information"}
                hasRightArrow={true}
            /> */}
            <div className="divider"></div>
            {/* <AoButton 
                variant={"left negative"}
                icon={<AutoDelete />}
                title={t('settings.nav.disable')}
                description={t('settings.nav.disable-desc')}
                hasRightArrow={true}
            /> */}
            <AoButton 
                variant={"left negative"}
                action={() => openModal('delete')}
                icon={<DeleteForever />}
                title={t('settings.nav.delete')}
                description={t('settings.nav.delete-desc')}
                hasRightArrow={true}
            />
            {modal &&
                <Modal
                    title={t('modal.delete-account.title')}
                    visible={modalState}
                    close={closeModal}
                    variant={"small"}
                >
                    
                    {modalType === 'delete' && <DeleteAccount close={closeModal} />}
                </Modal>
            }
        </>
    )
}
