import React from 'react'
import i18n from '../../services/i18n'

export default function getDBLang() {
    if (i18n.language === 'es' || i18n.language === "en") {
        return i18n.language;
    } else {
        return 'en';
    }
}
