import { ButtonGroup, Slider } from '@mui/material';
import React, { useRef, useState } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Compressor from 'compressorjs';
import { Image, Rotate90DegreesCcw, Rotate90DegreesCw } from '@mui/icons-material';
import AoButton from '../buttons/Button';
import { useTranslation } from 'react-i18next';

export default function AoAvatarEditor({ close, photo, setSelectedPhoto, loading, type }) {
    const { t } = useTranslation();
    const cropRef = useRef(null);
    const [ scale, setScale ] = useState(10);
    const [ rotate, setRotate ] = useState(0);

    const handleSave = async () => {
        if (type === "banner") {
            if (cropRef) {
                const dataUrl = cropRef.current.getImageScaledToCanvas().toDataURL();
                const result = await fetch(dataUrl);
                const blob = await result.blob();
                new Compressor(blob, {
                    quality: .7,
                    width: 1200,
                    height: 300,
                    resize: 'cover',
                    convertTypes: "image/png",
                    convertSize: 50000,
                    success: (compressedResult) => {
                        setSelectedPhoto(compressedResult);
                    }
                })
            }
        }

        if (type === "avatar") {
            if (cropRef) {
                const dataUrl = cropRef.current.getImageScaledToCanvas().toDataURL();
                const result = await fetch(dataUrl);
                const blob = await result.blob();
                new Compressor(blob, {
                    quality: .8,
                    width: 250,
                    height: 250,
                    resize: 'cover',
                    convertTypes: "image/png",
                    convertSize: 50000,
                    success: (compressedResult) => {
                        setSelectedPhoto(compressedResult);
                    }
                })
            }
        }
    }

    return (
        <>
            <div className={`avatar-editor ${type}`}>
                {type === "banner" &&
                    <AvatarEditor 
                        ref={cropRef}
                        image={photo}
                        style={{ width: "100%", height: "100%" }}
                        scale={scale / 10}
                        rotate={rotate}
                        width={1200}
                        height={300}
                        border={[0, 100]}
                        borderRadius={0}
                        className='canvas banner'
                    />
                }
                {type === "avatar" && 
                    <AvatarEditor 
                        ref={cropRef}
                        image={photo}
                        style={{ width: "100%", height: "100%" }}
                        scale={scale / 10}
                        rotate={rotate}
                        width={700}
                        height={700}
                        border={[200, 0]}
                        borderRadius={1000}
                        className='canvas'
                    />
                }
            </div>
            <div className="avatar-controls">
                <div className="slider">
                    <Image />
                    <Slider
                        min={10}
                        max={50}
                        size='small'
                        defaultValue={scale}
                        value={scale}
                        onChange={(e) => setScale(e.target.value)}
                        valueLabelDisplay='off'
                    />
                    <Image style={{ fontSize: '2rem'}} />
                </div>
                <div
                    className='buttons-group'
                >
                    <AoButton 
                        icon={<Rotate90DegreesCcw />} 
                        action={() => setRotate(rotate + -90)}
                    />
                    <AoButton 
                        icon={<Rotate90DegreesCw />} 
                        action={() => setRotate(rotate + 90)}
                    />
                </div>
            </div>
            <div className="buttons-layout">
                <AoButton 
                    title={t('words.cancel')}
                    variant={"outline size-bigger"}
                    action={close}
                />
                <AoButton 
                    title={t('words.save')}
                    style={{ flex: 'inherit'}}
                    loading={loading}
                    variant={"special-2 size-bigger"}
                    action={handleSave}
                />
            </div>
        </>
    )
}
