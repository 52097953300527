import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth';
import { CircularProgress } from '@mui/material';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../services/firebase/accounts';
import { BlockOutlined, DangerousOutlined, HeartBroken, WarningOutlined } from '@mui/icons-material';
import AoButton from '../buttons/Button';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import i18n from '../../services/i18n';
import { doc, getDoc } from 'firebase/firestore';
import PremiumLogo from '../../images/Premium';
import AngerIcon from '../../images/Anger';

export const Message = ({ restriction, handleAllow }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const Allow = () => {
        return (
            <AoButton 
                title={t('words.allow')}
                action={handleAllow}
                variant={"special-2 font-branch"}
            />
        )
    }

    const LoginBtn = () => {
        return (
            <AoButton 
                title={t('auth.login')}
                variant={"special-2 font-branch"}
                action={() => {
                    sessionStorage.setItem('back-path', location.pathname);
                    navigate('/auth/login')
                }}
            />
        )
    }

    if (restriction === "404") {
        return (
            <div className="msg">
                <div className="text">
                    <HeartBroken className='icon' />
                    <h3>{t('error.reproductor/404')}</h3>
                </div>
            </div>
        )
    }

    if (restriction[0] === 'no-videos') {
        return (
            <div className="msg">
                <div className="text">
                    <AngerIcon className="icon" />
                    <h3>{t('words.no-videos')}</h3>
                </div>
            </div>
        )
    }

    if (restriction[0] === "only-premium") {
        return (
            <div className="msg">
                <div className="text">
                    <PremiumLogo className="icon premium" />
                    <h3>{t('words.only premium')}</h3>
                    <p>{t(`error.auth/${restriction[0]}`)}</p>
                </div>
                <div className="buttons">
                    <AoButton 
                        title={t('words.buy premium')}
                        variant={"special-2"}
                    />
                </div>
            </div>
        )
    }

    if (restriction[1] === false) {
        if (restriction[0] === "Rx" || restriction[0] === "R+") {
            return (
                <div className="msg">
                    <div className="text">
                        <DangerousOutlined className='icon' />
                            <h3>{t('words.for adults')}</h3>
                        <p>{t(`error.rating/${restriction[0]}`)}</p>
                    </div>
                    <div className="buttons">
                        <LoginBtn />
                    </div>
                </div>
            )
        }
        if (restriction[0] === "R-17+") {
            return (
                <div className="msg">
                    <div className="text">
                        <DangerousOutlined className='icon' />
                        <h3>{t('words.be careful')}</h3>
                        <p>{t(`error.rating/${restriction[0]}`)}</p>
                    </div>
                    <div className="buttons">
                        <LoginBtn />
                    </div>
                </div>
            )
        }
    }

    if (restriction[1] === true) {
        if (restriction[0] === "R-17+") {
            return (
                <div className="msg">
                    <div className="text">
                        <WarningOutlined className='icon' />
                        <h3>{t('words.be careful')}</h3>
                        <p>{t(`error.rating/${restriction[0]}-logged`)}</p>
                    </div>
                    <div className="buttons">
                        <Allow />
                    </div>
                </div>
            )
        } else {
            if (restriction[2] === "no") {
                return (
                    <div className="msg">
                        <div className="text">
                            <BlockOutlined className='icon' />
                            <h3>{t('words.for adults')}</h3>
                        </div>
                    </div>
                )
            } else {
                if (restriction[0] === "Rx" || restriction[0] === "R+") {
                    return (
                        <div className="msg">
                            <div className="text">
                                <BlockOutlined className='icon' />
                                <h3>{t('words.for adults')}</h3>
                                <p>{t(`error.rating/${restriction[0]}-logged`)}</p>
                            </div>
                            <div className="buttons">
                                <Allow />
                            </div>
                        </div>
                    )
                }
            }
        }
    }

    return;
}

export default function Reproductor({ 
    rating, 
    loading, 
    setLoading, 
    bg, 
    serie,
    episodeInfo,
    setReproductors,
    currentVideo,
    seasonInfo,
    setCurrentVideo,
    getCapsList,
    episode
}) {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const params = useParams();
    const [ restriction, setRestriction ] = useState(null);
    const [ localLoading, setLocalLoading ] = useState(true);

    const handleAllow = () => {
        sessionStorage.setItem(`${serie}-preferences`, true);
        setRestriction(undefined);
        getReproductor();
    }

    function autoReproductor(data) {
        const type = localStorage.getItem('reproductor-type');

        function defaultConfig() {
            if (data.length > 0) {
                if (user !== null) {
                    if (user) {
                        if (user?.badges.includes("premium")) {
                            if (data[0].premium === null) {
                                getReproductor(false);
                            } else if (data[0].premium) {
                                setCurrentVideo(data[0].premium);
                                setLocalLoading(false);
                            } else {
                                setCurrentVideo(data[0].others[0]);
                                setLocalLoading(false);
                            }
                        } else {
                            setCurrentVideo(data[0].others[0]);
                            setLocalLoading(false);
                        }
                    } else if (user === undefined) {
                        setCurrentVideo(data[0].others[0]);
                        setLocalLoading(false);
                    }
                }
            } else {
                setRestriction(["no-videos"]);
            }
        }

        if (type) {
            if (data.includes(type)) {
                if (user?.badges.includes("premium")) {
                    setCurrentVideo(data.includes(type).premium);
                    setLocalLoading(false);
                } else {
                    setCurrentVideo(data.includes(type).others[0]);
                    setLocalLoading(false);
                }
            } else {
                defaultConfig();
            }
        } else {
            defaultConfig();
        }
    }

    function getReproductor(withCache) {
        const id = params.id.split('-')[0];
        const season = params.id.split('-')[1];
        const episode = params.id.split('-')[2];
        const now = new Date();

        function isValid(response) {
            if (!response) return false;
            var fetched = response.headers.get('expire');
            if (fetched > now.getTime()) return true;
        }

        async function getData(db, cache) {
            const reproductorRef = doc(db.animeFirestore, `animes/${id}/seasons/${season}/episodes/${episode}`);
            const reproductorSnap = await getDoc(reproductorRef);

            if (reproductorSnap.exists()){
                let data = reproductorSnap.data().videos;

                if (episodeInfo === null) {
                    getCapsList(true);
                }

                if (user?.badges.includes('premium')) {
                    setReproductors(data);
                    autoReproductor(data);
                } else {
                    data.forEach((doc) => {
                        let dataRef = doc;
                        if (dataRef.premium || dataRef.premium === null) {
                            dataRef.premium = null;
                        } else {
                            dataRef.premium = undefined;
                        }
                    })
                    setReproductors(data);
                    autoReproductor(data);
                }

                if (cache) {
                    const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 14));
                    
                    var headers = new Headers();
                    headers.append('expire', expirationTime.getTime());

                    cache.put(`/${serie}/seasons/${season}/episode/${episode}`, new Response (JSON.stringify(data), {
                        headers: headers
                    }))
                }
            } else {
                setRestriction(["no-videos"]);
            }
        }

        let lang = i18n.language;
                
        if (i18n.language === "es" || i18n.language === "en") {
            lang = i18n.language;
        } else {
            lang = "en";
        }

        if ('caches' in window) {
            caches.open(`animes_${lang}`)
            .then((cache) => {
                cache.match(`/${id}/seasons/${season}/episode/${episode}`)
                .then(async (response) => {
                    if (withCache === false) {
                        import(`../../services/firebase/anime_${lang}`)
                        .then((db) => {
                            getData(db, cache);
                        })
                    } else {
                        if (isValid(response)) {
                            var data = await response.json();
                            if (user?.badges.includes('premium')) {
                                setReproductors(data);
                                autoReproductor(data);
                            } else {
                                data.forEach((doc) => {
                                    let dataRef = doc;
                                    if (dataRef.premium || dataRef.premium === null) {
                                        dataRef.premium = null;
                                    } else {
                                        dataRef.premium = undefined;
                                    }
                                })
                                setReproductors(data);
                                autoReproductor(data);
                            };
                        } else {
                            import(`../../services/firebase/anime_${lang}`)
                            .then((db) => {
                                getData(db, cache);
                            })
                        }
                    }
                })
            })
        } else {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                getData(db);
            })
        }
    }
    
    useEffect(() => {
        onAuthStateChanged(auth, () => {
            if (user !== null) {
                if (user) {
                    if (rating === "Rx" || rating === "R+" || rating === "R-17+") {
                        if (rating === "R-17+") {
                            if (sessionStorage.getItem(`${serie}-preferences`) === "true" || localStorage.getItem('R-17+') === 'true') {
                                setRestriction(undefined);
                                getReproductor();
                            } else {
                                setRestriction([rating, true, "no"])
                            }
                        } else {
                            if (user.age < 18) {
                                setRestriction([rating, true, "no"])
                            } else {
                                if (sessionStorage.getItem(`${serie}-preferences`) === "true" || localStorage.getItem(rating) === "true") {
                                    setRestriction(undefined);
                                    getReproductor();
                                } else {
                                    setRestriction([rating, true]);
                                }
                            }
                        }
                    } else {
                        setRestriction(undefined);
                        getReproductor();
                    }
                } else if (user === undefined) {
                    if (rating === "Rx" || rating === "R+" || rating === "R-17+") {
                        setRestriction([rating, false]);
                    } else {
                        getReproductor();
                        setRestriction(undefined);
                    }

                }
            } else {
                if (rating === "Rx" || rating === "R+" || rating === "R-17+") {
                    if (rating === "R-17+") {
                        if (sessionStorage.getItem(`${serie}-preferences`) === "true") {
                            setRestriction(undefined);
                            getReproductor();
                        } else {
                            setRestriction([rating, true, "no"])
                        }
                    } else {
                        setRestriction([rating, true, "no"]);
                    }
                } else {
                    setRestriction(undefined);
                    getReproductor();
                }
            }
        })
        
    }, [localStorage.getItem(rating), user]);

    useEffect(() => {
        const video = document.getElementById('reproductor');
        
        if (video) {
            if (loading) {
                video.style.display = 'none';
            } else {
                video.style.display = 'flex';
            }
        }
    }, [loading]);

    useEffect(() => {
        setLoading(true);
        getReproductor();
    }, [params.id])

    const handleOnLoad = (event) => {
        event.preventDefault();
        const reproductor = event.target.src;

        if (user?.badges.includes("premium")) {
            setLoading(false);
        } else {
            const match = reproductor.replace(/.+\/\/|www.|\..+/g, '');

            if (match === "drive") {
                setLoading(true);
                setLocalLoading(true);
                setRestriction(['only-premium'])
            } else {
                setLoading(false);
            }
        }
    }

    if (restriction) {
        return (
            <div className="video-layout">
                <div className="reproductor">
                    <div className="reproductor-msg">
                        <div className="reproductor-background" style={{backgroundImage: `url("${bg}")`}}></div>
                        <Message restriction={restriction} handleAllow={handleAllow} />
                    </div>
                </div>
            </div> 
        )
    }

    if (localLoading) {
        return (
            <div className="video-layout">
                <div className="reproductor">
                    <div className="reproductor-msg">
                        <div className="loading progress"><CircularProgress className='progress-circular' /></div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className='video-layout'>
            {loading && 
                <div className="reproductor">
                    <div className="reproductor-msg">
                        <div className="loading progress"><CircularProgress className='progress-circular' /></div>
                    </div>
                </div>
            }
            {currentVideo !== "" &&
                <iframe 
                    className='reproductor' 
                    src={currentVideo}
                    id='reproductor'
                    allow="autoplay"
                    style={{display: 'none'}}
                    allowFullScreen
                    onLoad={handleOnLoad}
                    title={`[${t('words.shorts.season')}${seasonInfo.id} ${t('words.shorts.episode')}${episode}] ${seasonInfo?.name} - ${episodeInfo?.name} - aodesu`}
                />
            }
            
        </div>
    )
}