import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './BrowseCard.scss';
import { Link } from 'react-router-dom';
import { ModalsContext } from '../../context/Modals';
import { Menu, useMediaQuery } from '@mui/material';
import AoButton from '../buttons/Button';
import { IosShare, PlayArrow, Replay } from '@mui/icons-material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function BrowseCard({ data, type, episodes }) {
    const { t } = useTranslation();
    const { openShare, openSerieCard } = useContext(ModalsContext);

    const [ imageLoaded, setImageLoaded ] = useState(false);
    const [ contextMenu, setContextMenu ] = useState(null);
    const [ viewed, setViewed ] = useState(null);

    const media = useMediaQuery('(max-width: 50em)');

    const handleContextMenu = (event) => {
        event.preventDefault();
        if (media) {
            if (episodes === true) {
                openSerieCard(data, 'episode');
            } else {
                openSerieCard(data, '');
            }
        } else {
            setContextMenu(
                contextMenu === null
                    ? {
                        mouseX: event.clientX + 2,
                        mouseY: event.clientY - 6,
                    }
                    :null
            );
        }
    }

    const handleClose = () => {
        if (!media) {
            setContextMenu(null);
        }
    }

    const handleViewed = () => {
        const storage = localStorage.getItem(`watch-list-${data.info.serie.id}`);
        if (viewed) {
            const arr = JSON.parse(storage);
            if (arr.length > 1) {
                const x = arr.filter(id => id !== `${data.info.season.id}-${data.id}`);
                
                localStorage.setItem(`watch-list-${data.info.serie.id}`, JSON.stringify(x));
                setViewed(false);
            } else {
                localStorage.setItem(`watch-list-${data.info.serie.id}`, JSON.stringify([]));
                setViewed(false);
            }
        } else {
            if (storage) {
                let arr = JSON.parse(storage);
                arr.push(`${data.info.season.id}-${data.id}`);
                localStorage.setItem(`watch-list-${data.info.serie.id}`, JSON.stringify(arr));
                setViewed(true);
            } else {
                setViewed(true);
                localStorage.setItem(`watch-list-${data.info.serie.id}`, JSON.stringify([`${data.info.season.id}-${data.id}`]))
            }
        }
    }

    useEffect(() => {
        if (episodes) {
            if (data) {
                const storage = localStorage.getItem(`watch-list-${data.info.serie.id}`);
                if (storage) {
                    setViewed(JSON.parse(storage)[`${data.info.season.id}-${data.id}`])
                }
            }
        }
    }, [])

    if (type === "lazy") {
        if (episodes) {
            return (
                <div className="browse-card wide lazy">
                    <div className="browse-card__poster">
                        <div className="figure-wrapper">
                            <div className="figure-wrapper__figure-sizer">
                                <div className="figure episode"></div>
                            </div>
                        </div>
                    </div>
                    <div className="browse-card__body">
                        <div className="browse-card__title"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='browse-card lazy'>
                    <div className='browse-card__poster-wrapper'>
                        <div className="browse-card__poster">
                            <div className="figure-wrapper"></div>
                        </div>
                    </div>
                    <div className="browse-card__body">
                        <div className='browse-card__title'></div>
                    </div>
                </div>
            )
        }
    }

    if (episodes === true) {
        return(
            <>
                <div className='browse-card wide' onContextMenu={handleContextMenu}>
                    <Link to={`/watch/${data.info.serie.id}-${data.info.season.id}-${data.id}`} title={`${t('words.shorts.season')}${data.info.season.id} ${t('words.shorts.episode')}${data.id} - ${data.name}`} className='hover-link'></Link>
                    <Link to={`/watch/${data.info.serie.id}-${data.info.season.id}-${data.id}`} title={`${t('words.shorts.season')}${data.info.season.id} ${t('words.shorts.episode')}${data.id} - ${data.name}`}>
                        <div className="browse-card__poster">
                            <div className="figure-wrapper">
                                <div className={`figure-wrapper__figure-sizer ${imageLoaded && 'loaded'}`}>
                                    <figure className="figure episode">
                                        <picture>
                                            <source srcSet={data.cover} type='image/webp' />
                                            <LazyLoadImage
                                                src={data.cover} 
                                                alt={data.name}
                                                onLoad={() => setImageLoaded(true)}
                                            />
                                        </picture>
                                    </figure>
                                    <figure className="figure serie">
                                        <picture>
                                            <source srcSet={data.info.serie.cover} type='image/webp' />
                                            <LazyLoadImage
                                                src={data.info.serie.cover} 
                                                alt={data.info.season.name}
                                            />
                                        </picture>
                                    </figure>
                                </div>
                                {viewed && 
                                    <div className={`watched ${viewed}`}>
                                        {viewed === 'partial' && 
                                        <>
                                            <PlayArrow />
                                            <div className="border"></div>
                                        </>
                                        }
                                        {viewed === 'true' && <Replay />}
                                    </div>
                                }
                            </div>
                        </div>
                    </Link>
                    <div className="browse-card__body">
                        <Link className='browse-card__serie-name' title={`${t('words.shorts.season')}${data.info.season.id} ${t('words.shorts.episode')}${data.id} - ${data.name}`} to={`/anime/${data.info.serie.id}-${data.info.season.id}`}>
                            {data.info.season.name}
                        </Link>
                        <h4 className='browse-card__title'>
                            <Link className='browse-card__title-link' title={`${t('words.shorts.season')}${data.info.season.id} ${t('words.shorts.episode')}${data.id} - ${data.name}`} to={`/watch/${data.info.serie.id}-${data.info.season.id}-${data.id}`}>
                            {`${t('words.shorts.season')}${data.info.season.id} ${t('words.shorts.episode')}${data.id} - ${data.name}`}
                            </Link>
                        </h4>
                        <div className="browse-card__footer">
                            <div className="tags-and-rating">
                                {data.tags.map((tag, i) => (
                                    <span key={i}>{t(`categories.langs.${tag}`)}</span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {!media &&
                    <Menu
                        open={contextMenu !== null}
                        onClose={handleClose}
                        anchorReference='anchorPosition'
                        transitionDuration={0}
                        className='lf-menu'
                        anchorPosition={
                            contextMenu !== null
                                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                                : undefined
                        }
                    >
                        <AoButton 
                            icon={<IosShare />}
                            title={t('words.share episode')}
                            action={() => {
                                openShare(`/watch/${data.info.serie.id}-${data.info.season.id}-${data.id}`);
                                handleClose();
                            }}
                        />
                        <AoButton 
                            icon={<IosShare />}
                            title={t('words.share anime')}
                            action={() => {
                                openShare(`/anime/${data.info.serie.id}-${data.info.season.id}`);
                                handleClose();
                            }}
                        />
                    </Menu>
                }
            </>
        )
    }

    return (
        <>
        <div className='browse-card' onContextMenu={handleContextMenu}>
            <Link to={`/anime/${data.id}`} title={data.name} className='hover-link'></Link>
            <Link  to={`/anime/${data.id}`} title={data.name} className='browse-card__poster-wrapper'>
                <div className="browse-card__poster">
                    <div className="figure-wrapper">
                        <div className={`figure-wrapper__figure-sizer ${imageLoaded && 'loaded'}`}>
                            <figure className="figure">
                                <picture>
                                    <source srcSet={data.cover} type='image/webp' />
                                    <LazyLoadImage
                                        src={data.cover} 
                                        alt={data.name}
                                        onLoad={() => setImageLoaded(true)}
                                    />
                                </picture>
                            </figure>
                        </div>
                    </div>
                </div>
            </Link>
            <div className="browse-card__body">
                <h4 className='browse-card__title'>
                    <Link to={`/anime/${data.id}`} title={data.name} className='browse-card__title-link'>
                        {data.name}
                    </Link>
                </h4>
                <div className="browse-card__footer">
                    <div className="tags-and-rating">
                        {data.tags.map((tag, i) => (
                            <span key={i}>{t(`categories.langs.${tag}`)}</span>
                        ))}
                    </div>
                </div>
            </div>
        </div>
        {!media &&
            <Menu
                open={contextMenu !== null}
                onClose={handleClose}
                anchorReference='anchorPosition'
                transitionDuration={0}
                className='lf-menu'
                anchorPosition={
                    contextMenu !== null
                        ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                        : undefined
                }
            >
                <AoButton 
                    icon={<IosShare />}
                    title={t('words.share anime')}
                    action={() => {
                        openShare(`/anime/${data.id}`);
                        handleClose();
                    }}
                />
            </Menu>
        }
        </>
    )
}
