import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/Logo'
import { useTranslation } from 'react-i18next'

export default function AuthHeader() {
    const { t } = useTranslation();
    return (
        <header className="header">
            <div className="section">
                <div>
                    <div className="logo">
                        <Link to="/" title={t('header.principal page')}>
                            <Logo />
                        </Link>
                    </div>
                </div>
            </div>
        </header>
    )
}
