import React from 'react';
import './Modals.scss';
import AoIconButton from '../buttons/IconButton';
import { Close } from '@mui/icons-material';

export default function Modal({ 
    title, 
    variant, 
    close,
    children, 
    visible, 
    modals,
    loading,
    style
  }) {
  return (
    <div className={`modal ${visible} ${loading && 'loading'}`} style={style}>
      <div className="backdrop true" onClick={close}></div>
      <div className={`modal-layout ${variant}`}>
        {title &&
          <div className="modal-title">
            <h3>{title}</h3>
            <AoIconButton 
              action={close}
              content={<Close />}
            />
          </div>
        }
        <div className="modal-body">
          {children}
        </div>
      </div>
      {modals}
    </div>
  )
}
