import React, { useContext, useEffect, useState } from 'react';
import './Alerts.scss';
import { Box, Collapse } from '@mui/material';
import { AlertsContext } from '../../context/Alerts';
import AoIconButton from '../buttons/IconButton';
import { Close, Pending, TaskAlt } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/Auth';
import { auth } from '../../services/firebase/accounts';

export default function HeaderAlert({ 
        title, 
        id, 
        type, 
        variant, 
        close, 
        link, 
        linkText,
        identifier    
    }) {
    const { alerts, setAlerts } = useContext(AlertsContext);
    const [ open, setOpen ] = useState(false);

    const closeAlert = () => {
        setOpen(false);
        setTimeout(() => {
            setAlerts(alerts.filter(obj => obj.id !== id))
        }, 300)
    }

    useEffect(() => {
        setTimeout(() => {
            setOpen(true);
        }, 100);
    }, []);

    useEffect(() => {
        if (identifier === "verify-email") {
            const interval = setInterval(() => {
                const user = auth.currentUser;
                user?.reload()
                    .then(() => {
                        if (user.emailVerified) {
                            clearInterval(interval);
                            closeAlert();
                        }
                    })
            }, 1000);
        }
    }, []);

    useEffect(() => {
        if (close === "auto"){
            setTimeout(() => {
                closeAlert();
            }, 3000);
        }
    })

  return (
    <Box sx={{ width: '100%'}}>
        <Collapse in={open}>
            <div className={`alert ${type} ${variant}`}>
                <div className="icon">
                    {type === "success" && <TaskAlt />}
                    {type === "branch" && <TaskAlt />}
                    {type === "pending" && <Pending />}
                </div>
                <span>{title}</span>
                {link &&
                    <Link to={link}>
                        {linkText}
                    </Link>
                }
                {close === true &&
                    <AoIconButton 
                        content={<Close />}
                        action={closeAlert}
                    />
                }
            </div>
        </Collapse>
    </Box>
  )
}
