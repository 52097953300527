export default function AngerIcon({ className }) {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" aria-hidden="true" role="img" preserveAspectRatio="xMidYMid meet">
            <g fill="currentColor">
                <path d="M55.6 12.7c-.5-.7-1.4-1.1-2.3-.9c-2.8.7-5.2 1-7.5 1s-4.5-.4-6.4-1.1c-3.8-1.4-7.3-4.3-10.7-8.9c-.1-.1-.2-.3-.3-.4l-.1-.1c-.8-.7-2.2-.6-2.8.2c-.5.6-.6 1.2-.5 1.8c.6 6.2 5.4 12 11.9 14.3c2.1.8 4.4 1.2 6.6 1.2c4.6 0 8.9-1.7 11.8-4.6c.7-.7.8-1.7.3-2.5"></path>
                <path d="M27.1 45.4c-2.1-.8-4.4-1.2-6.6-1.2c-4.6 0-8.9 1.7-11.8 4.6c-.7.7-.8 1.7-.3 2.5c.5.7 1.4 1.1 2.3.9c2.8-.7 5.2-1 7.5-1s4.5.4 6.4 1.1c3.8 1.4 7.3 4.3 10.7 8.9c.1.1.2.3.4.4c.4.3.8.5 1.3.5c.6 0 1.1-.2 1.5-.7c.4-.5.5-1.1.4-1.6c-.5-6.3-5.3-12-11.8-14.4"></path>
                <path d="M61.3 25.4c-.3-.3-.8-.5-1.3-.5h-.3c-6.2.6-12 5.4-14.3 11.9c-2.4 6.5-1.1 13.9 3.4 18.4c.4.4.9.6 1.4.6c.4 0 .7-.1 1.1-.3c.8-.5 1.1-1.4.9-2.3c-1.4-5.5-1.4-10.1 0-13.9c1.4-3.8 4.3-7.3 8.9-10.7c.1-.1.3-.2.4-.4c.7-.8.6-2.1-.2-2.8"></path>
                <path d="M18.6 27.1c2.4-6.5 1.1-13.9-3.4-18.4c-.6-.7-1.7-.8-2.5-.3s-1.1 1.4-.9 2.3c1.4 5.5 1.4 10.1 0 13.9c-1.4 3.8-4.3 7.3-8.9 10.7c-.1.1-.3.2-.4.4c-.7.9-.6 2.1.2 2.8c.3.4.8.5 1.2.5h.3c6.3-.6 12-5.4 14.4-11.9"></path>
            </g>
        </svg>
    )
}