import React, { useContext, useEffect, useState } from 'react'
import AoButton from '../buttons/Button'
import Input from '../form/Input'
import { auth, firestore, storage } from '../../services/firebase/accounts';
import { EmailAuthProvider, OAuthProvider, deleteUser, reauthenticateWithPopup } from 'firebase/auth';
import { deleteDoc, doc } from 'firebase/firestore';
import { deleteObject, ref } from 'firebase/storage';
import { useTranslation } from 'react-i18next';
import { Google } from '@mui/icons-material';
import { AuthContext } from '../../context/Auth';
import { useNavigate } from 'react-router-dom';

export default function DeleteAccount({ close }) {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const [ image, setImage ] = useState('');

    const [ password, setPassword ] = useState('');
    const [msg, setMsg] = useState(null);

    const [ deleteTimer, setDeleteTimer ] = useState(false);
    const [ deleteAccount, setDeleteAccount ] = useState(false);

    const [ deleting, setDeleting ] = useState(false);
    const [ verifyType, setVerifyType ] = useState('');
    const [ verified, setVerified ] = useState(false);

    const [ disabled, setDisabled ] = useState(true);

    const googleProvider = new OAuthProvider('google.com');

    function googleReauthenticated(){
        reauthenticateWithPopup(auth.currentUser, googleProvider)
        .then(async () => {
            setDisabled(false);
            setVerifyType('google');
            setVerified(true);
        })
        .catch(() => {
            setDisabled(true);
            setVerifyType('');
            setDeleteAccount(false);
        })
    }

    async function passwordReauthenticated(){
        setDeleteTimer(true);

        if (verifyType === 'google') {
            setDeleteAccount(true);
        } else {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                password
            );
    
            await reauthenticateWithPopup(auth.currentUser, credential)
                .then(async () => {
                    setDeleteAccount(true);
                    setVerified(true);
                })
                .catch((err) => {
                    if(err.code === 'auth/wrong-password'){
                        setMsg('noPassword');
                    }
                    setDeleteAccount(false);
                    setDisabled(true);
                    setVerifyType('');
                })
        }

    }

    useEffect(() => {
        function deleteAccountFn() {
            setDeleting(true);
            const userRef = doc(firestore, `usuarios/${user.uid}`);
            const userPhoto = ref(storage, `usuarios/${user.uid}/pfp`);
            const userBanner = ref(storage, `usuarios/${user.uid}/pfb`);

            deleteObject(userPhoto)
            .then(() => {
                deletePfb();
            })
            .catch((err) => {
                if(err.code === 'storage/object-not-found'){
                    deletePfb();
                }
            })

            function deletePfb() {
                deleteObject(userBanner)
                .then(() => {
                    deleteData();
                })
                .catch((err) => {
                    if(err.code === 'storage/object-not-found'){
                        deleteData();
                    }
                })
            }

            function deleteData() {
                deleteDoc(userRef)
                .then(() => {
                    deleteUser(auth.currentUser)
                    .then(() => {
                        navigate('/');
                    })
                })
            }
        }

        if (!deleteAccount) {
            setDeleting(false);
            setDeleteTimer(false);
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            deleteAccountFn();
        }, 4000);

        return () => clearTimeout(delayDebounceFn);
    }, [deleteAccount]);

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (image === '') {
                fetch('https://api.waifu.pics/sfw/cry')
                .then((response) => response.json())
                .then((image) => setImage(image.url))
            }
        }

        return () => ignore = true;
    }, [])
    
    useEffect(() => {
        if (password.length > 7) {
            setDisabled(false);
            setVerifyType('password');
        } else {
            setDisabled(true);
            setVerifyType('');
        }
    }, [password]);

    return (
        <>
            <div style={{padding: '.5rem'}}>
                
                {image !== '' &&
                    <div style={{
                        maxWidth: '100%',
                        borderRadius: '5px',
                        overflow: 'hidden',
                        display: 'flex',
                        margin: '1rem auto 0'
                        }}
                        className={deleting ? '' : 'hide'}
                    >
                        <img style={{ width: '100%'}} src={image} />
                    </div>
                }
                {!deleting &&
                    <div className='flex flow-column'>
                        <span>{t('modal.delete-account.desc.1')}</span>
                        <span>{t('modal.delete-account.desc.2')}</span> 
                    </div>
                }
            </div>
            <div className="divider" style={{ margin: '.5rem 0'}}></div>
            {verified ?
                <div className='flex justify-center align-center' style={{ padding: '.5rem .5rem 1rem'}}>
                    <h3 className='font-style color-positive' style={{ fontWeight: 500}}>{t('modal.delete-account.verified')}</h3>
                </div>
                :
                <div className='flex flow-column gap-normal' style={{ padding: '.5rem'}}>
                    <h3 style={{ fontWeight: 500}}>{t('modal.delete-account.verify-with')}</h3>
                    {auth.currentUser.providerData.map((prov, i) => {
                        if(prov.providerId==="google.com"){
                            return(
                                <AoButton 
                                    key={i}
                                    variant={'highlight'}
                                    action={googleReauthenticated}
                                    icon={<Google />}
                                    title={t('modal.delete-account.verify-with-google')}
                                />
                            )
                        }
    
                        if(prov.providerId==="password"){
                            return(
                                <Input 
                                    key={i}
                                    label
                                    labelText={t('modal.delete-account.verify-with-password')}
                                    type="password"
                                    value={password}
                                    variant={1}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            )
                        }
                        return null
                    })}
                </div>
            }
            {msg === 'noPassword' && <span style={{ padding: '.5rem'}} className='font-style color-negative'>{t('error.auth/password-dont-match')}</span>}
            <div className="buttons-layout">
                <AoButton 
                    title={t('words.cancel')}
                    variant={"special-2 size-big"}
                    disabled={deleting}
                    action={() => {
                        if (deleteAccount) {
                            setDeleteAccount(false);
                        } else {
                            close();
                        }
                    }}
                />
                <AoButton 
                    title={t('words.delete')}
                    variant={"danger size-big"}
                    loading={deleteTimer}
                    disabled={disabled}
                    action={passwordReauthenticated}
                />
            </div>
        </>
    )
}
