function Logo(){
    return(
        <svg viewBox="0 0 1814 302" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M213.79 106.67L157.684 128.835L101.579 106.67C101.579 106.67 105.042 76.8853 122.012 47.7936C138.983 18.7018 157.684 0 157.684 0C157.684 0 177.079 18.7018 193.357 47.7936C209.634 76.8853 213.79 106.67 213.79 106.67Z" fill="#4169E1"/>
            <path d="M8.06984 92.1238L134.827 141.995C134.827 141.995 109.545 160.351 93.6134 188.75C77.6822 217.149 75.9506 249.358 75.9506 249.358C75.9506 249.358 29.8887 234.812 9.45517 184.248C-10.9783 133.683 8.06984 92.1238 8.06984 92.1238Z" fill="#4169E1"/>
            <path d="M105.042 302L239.418 249.358C239.418 249.358 284.441 233.427 305.221 185.287C326.001 137.147 307.299 92.1238 307.299 92.1238L173.616 144.42C173.616 144.42 128.695 158.619 107.12 208.837C85.5452 259.055 105.042 302 105.042 302Z" fill="#4169E1"/>
            <path d="M186.084 292.649L216.561 280.874C216.561 280.874 214.483 289.186 213.79 291.61C213.097 294.034 210.327 302 210.327 302L186.084 292.649Z" fill="#4169E1"/>
            <path d="M502.612 291V249.84L498.692 240.824V167.128C498.692 154.061 494.641 143.869 486.54 136.552C478.7 129.235 466.548 125.576 450.084 125.576C438.847 125.576 427.74 127.405 416.764 131.064C406.049 134.461 396.903 139.165 389.324 145.176L367.372 102.448C378.871 94.3467 392.721 88.0747 408.924 83.632C425.127 79.1893 441.591 76.968 458.316 76.968C490.46 76.968 515.417 84.5467 533.188 99.704C550.959 114.861 559.844 138.512 559.844 170.656V291H502.612ZM438.324 294.136C421.86 294.136 407.748 291.392 395.988 285.904C384.228 280.155 375.212 272.445 368.94 262.776C362.668 253.107 359.532 242.261 359.532 230.24C359.532 217.696 362.537 206.72 368.548 197.312C374.82 187.904 384.62 180.587 397.948 175.36C411.276 169.872 428.655 167.128 450.084 167.128H506.14V202.8H456.748C442.375 202.8 432.444 205.152 426.956 209.856C421.729 214.56 419.116 220.44 419.116 227.496C419.116 235.336 422.121 241.608 428.132 246.312C434.404 250.755 442.897 252.976 453.612 252.976C463.804 252.976 472.951 250.624 481.052 245.92C489.153 240.955 495.033 233.768 498.692 224.36L508.1 252.584C503.657 266.173 495.556 276.496 483.796 283.552C472.036 290.608 456.879 294.136 438.324 294.136Z" fill="#4169E1"/>
            <path d="M717.073 294.136C694.598 294.136 674.606 289.432 657.097 280.024C639.849 270.616 626.129 257.811 615.937 241.608C606.006 225.144 601.041 206.459 601.041 185.552C601.041 164.384 606.006 145.699 615.937 129.496C626.129 113.032 639.849 100.227 657.097 91.08C674.606 81.672 694.598 76.968 717.073 76.968C739.286 76.968 759.148 81.672 776.657 91.08C794.166 100.227 807.886 112.901 817.817 129.104C827.748 145.307 832.713 164.123 832.713 185.552C832.713 206.459 827.748 225.144 817.817 241.608C807.886 257.811 794.166 270.616 776.657 280.024C759.148 289.432 739.286 294.136 717.073 294.136ZM717.073 243.96C727.265 243.96 736.412 241.608 744.513 236.904C752.614 232.2 759.017 225.536 763.721 216.912C768.425 208.027 770.777 197.573 770.777 185.552C770.777 173.269 768.425 162.816 763.721 154.192C759.017 145.568 752.614 138.904 744.513 134.2C736.412 129.496 727.265 127.144 717.073 127.144C706.881 127.144 697.734 129.496 689.633 134.2C681.532 138.904 674.998 145.568 670.033 154.192C665.329 162.816 662.977 173.269 662.977 185.552C662.977 197.573 665.329 208.027 670.033 216.912C674.998 225.536 681.532 232.2 689.633 236.904C697.734 241.608 706.881 243.96 717.073 243.96Z" fill="#4169E1"/>
            <path d="M964.14 294.136C944.279 294.136 926.377 289.693 910.436 280.808C894.495 271.661 881.82 258.987 872.412 242.784C863.265 226.581 858.692 207.504 858.692 185.552C858.692 163.339 863.265 144.131 872.412 127.928C881.82 111.725 894.495 99.1813 910.436 90.296C926.377 81.4107 944.279 76.968 964.14 76.968C981.911 76.968 997.46 80.888 1010.79 88.728C1024.12 96.568 1034.44 108.459 1041.76 124.4C1049.07 140.341 1052.73 160.725 1052.73 185.552C1052.73 210.117 1049.2 230.501 1042.15 246.704C1035.09 262.645 1024.9 274.536 1011.57 282.376C998.505 290.216 982.695 294.136 964.14 294.136ZM974.724 243.96C984.655 243.96 993.671 241.608 1001.77 236.904C1009.87 232.2 1016.28 225.536 1020.98 216.912C1025.95 208.027 1028.43 197.573 1028.43 185.552C1028.43 173.269 1025.95 162.816 1020.98 154.192C1016.28 145.568 1009.87 138.904 1001.77 134.2C993.671 129.496 984.655 127.144 974.724 127.144C964.532 127.144 955.385 129.496 947.284 134.2C939.183 138.904 932.649 145.568 927.684 154.192C922.98 162.816 920.628 173.269 920.628 185.552C920.628 197.573 922.98 208.027 927.684 216.912C932.649 225.536 939.183 232.2 947.284 236.904C955.385 241.608 964.532 243.96 974.724 243.96ZM1030 291V247.88L1031.17 185.16L1027.25 122.832V0.135986H1088.4V291H1030Z" fill="#4169E1"/>
            <path d="M1249.67 294.136C1225.62 294.136 1204.46 289.432 1186.16 280.024C1168.13 270.616 1154.15 257.811 1144.22 241.608C1134.29 225.144 1129.32 206.459 1129.32 185.552C1129.32 164.384 1134.16 145.699 1143.83 129.496C1153.76 113.032 1167.22 100.227 1184.2 91.08C1201.19 81.672 1220.4 76.968 1241.83 76.968C1262.47 76.968 1281.03 81.4107 1297.49 90.296C1314.22 98.92 1327.41 111.464 1337.08 127.928C1346.75 144.131 1351.59 163.6 1351.59 186.336C1351.59 188.688 1351.46 191.432 1351.19 194.568C1350.93 197.443 1350.67 200.187 1350.41 202.8H1179.11V167.128H1318.27L1294.75 177.712C1294.75 166.736 1292.52 157.197 1288.08 149.096C1283.64 140.995 1277.5 134.723 1269.66 130.28C1261.82 125.576 1252.67 123.224 1242.22 123.224C1231.76 123.224 1222.49 125.576 1214.39 130.28C1206.55 134.723 1200.4 141.125 1195.96 149.488C1191.52 157.589 1189.3 167.259 1189.3 178.496V187.904C1189.3 199.403 1191.78 209.595 1196.75 218.48C1201.97 227.104 1209.16 233.768 1218.31 238.472C1227.71 242.915 1238.69 245.136 1251.23 245.136C1262.47 245.136 1272.27 243.437 1280.63 240.04C1289.26 236.643 1297.1 231.547 1304.15 224.752L1336.69 260.032C1327.02 271.008 1314.87 279.501 1300.23 285.512C1285.6 291.261 1268.74 294.136 1249.67 294.136Z" fill="#4169E1"/>
            <path d="M1463.25 294.136C1445.22 294.136 1427.84 292.045 1411.11 287.864C1394.65 283.421 1381.58 277.933 1371.91 271.4L1392.3 227.496C1401.97 233.507 1413.34 238.472 1426.4 242.392C1439.73 246.051 1452.8 247.88 1465.6 247.88C1479.72 247.88 1489.65 246.181 1495.4 242.784C1501.41 239.387 1504.41 234.683 1504.41 228.672C1504.41 223.707 1502.06 220.048 1497.35 217.696C1492.91 215.083 1486.9 213.123 1479.32 211.816C1471.74 210.509 1463.38 209.203 1454.23 207.896C1445.35 206.589 1436.33 204.891 1427.19 202.8C1418.04 200.448 1409.68 197.051 1402.1 192.608C1394.52 188.165 1388.38 182.155 1383.67 174.576C1379.23 166.997 1377.01 157.197 1377.01 145.176C1377.01 131.848 1380.8 120.088 1388.38 109.896C1396.22 99.704 1407.46 91.7333 1422.09 85.984C1436.73 79.9733 1454.24 76.968 1474.62 76.968C1488.99 76.968 1503.63 78.536 1518.52 81.672C1533.42 84.808 1545.83 89.3813 1555.76 95.392L1535.38 138.904C1525.19 132.893 1514.86 128.843 1504.41 126.752C1494.22 124.4 1484.29 123.224 1474.62 123.224C1461.03 123.224 1451.1 125.053 1444.83 128.712C1438.56 132.371 1435.42 137.075 1435.42 142.824C1435.42 148.051 1437.64 151.971 1442.08 154.584C1446.79 157.197 1452.93 159.288 1460.51 160.856C1468.09 162.424 1476.32 163.861 1485.2 165.168C1494.35 166.213 1503.5 167.912 1512.64 170.264C1521.79 172.616 1530.02 176.013 1537.34 180.456C1544.92 184.637 1551.06 190.517 1555.76 198.096C1560.47 205.413 1562.82 215.083 1562.82 227.104C1562.82 240.171 1558.9 251.8 1551.06 261.992C1543.22 271.923 1531.85 279.763 1516.95 285.512C1502.32 291.261 1484.42 294.136 1463.25 294.136Z" fill="#4169E1"/>
            <path d="M1689.53 294.136C1672.02 294.136 1656.34 290.739 1642.49 283.944C1628.9 277.149 1618.32 266.827 1610.74 252.976C1603.16 238.864 1599.37 220.963 1599.37 199.272V80.104H1660.53V190.256C1660.53 207.765 1664.18 220.701 1671.5 229.064C1679.08 237.165 1689.66 241.216 1703.25 241.216C1712.66 241.216 1721.02 239.256 1728.34 235.336C1735.66 231.155 1741.41 224.883 1745.59 216.52C1749.77 207.896 1751.86 197.181 1751.86 184.376V80.104H1813.01V291H1755V232.984L1765.58 249.84C1758.52 264.475 1748.07 275.581 1734.22 283.16C1720.63 290.477 1705.74 294.136 1689.53 294.136Z" fill="#4169E1"/>
        </svg>
    )
}

export default Logo;