import { createContext, useState } from "react";

export const ContentListsContext = createContext();

export const ContentListsProvider = ({ children }) => {
    const [ animes, setAnimes ] = useState([]);

    return (
        <ContentListsContext.Provider
            value={{
                animes, 
                setAnimes
            }}
        >
            {children}
        </ContentListsContext.Provider>
    )
}