import React, { useEffect, useState } from 'react'
import AoButton from '../../../components/buttons/Button';
import { Lock } from '@mui/icons-material';
import LazySettings from '../LazySettings';
import { useTranslation } from 'react-i18next';

export default function Privacy() {
    const { t } = useTranslation();
    const [ isLoading, setLoading ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
          setLoading(false);
        }, 100);
    }, [])

    if (isLoading) {
        return (
            <LazySettings />
        )
    }

    return (
        <>
            <AoButton 
                variant={"left"}
                icon={<Lock />}
                title={t('settings.nav.policy')}
                description={t('settings.nav.policy-desc')}
                type={"link"}
                to={"policy"}
                hasRightArrow={true}
            />{/* 
            <AoButton 
                variant={"left"}
                icon={<Lock />}
                title={t('settings.nav.terms')}
                description={t('settings.nav.terms-desc')}
                type={"link"}
                to={"information"}
                hasRightArrow={true}
            /> */}
            <div className="divider"></div>
            <div style={{ padding: '1rem .5rem'}}>Ningún video se encuentra alojado en nuestros servidores.</div>
        </>
    )
}
