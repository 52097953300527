import { Avatar, IconButton } from '@mui/material';
import React from 'react';
import './Buttons.scss';
import { AddAPhoto, Person } from '@mui/icons-material';

export default function AoIconButton({
  title, 
  content, 
  action, 
  variant,
  ref,
  ariaControls,
  ariaExpanded,
  ariaHaspopup,
  submit,
  className,
  type,
  style,
  avatar,
  alt
}) {

  if (type === "change-image") {
    return (
      <IconButton onClick={action} style={style} className='IconButton avatar'>
        <div className="change-image-layout">
          {avatar ?
            <>
              <Avatar 
                className='avatar image'
                src={avatar}
                alt={alt}
                sx={{ fontSize: '3.25rem'}}
              />
              <div className="icon hover">
                <AddAPhoto />
              </div>
            </>
          :
            <div className="icon">
              <Person />
            </div>
          }
        </div>
        {content}
      </IconButton>
    )
  }

  return (
    <IconButton 
      onClick={action} 
      title={title} 
      className={`IconButton ${variant} ${className}`}
      ref={ref}
      style={style}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-haspopup={ariaHaspopup}
      type={submit ? 'submit' : 'button'}
    >
      {content}
    </IconButton>
  )
}
