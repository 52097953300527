import React, { useEffect, useState } from 'react'
import AoButton from '../../../components/buttons/Button';
import { AdsClick } from '@mui/icons-material';
import LazySettings from '../LazySettings';
import SEO from '../../../services/seo';
import { useTranslation } from 'react-i18next';

export default function Ads() {
  const { t } = useTranslation();
  const [ isLoading, setLoading ] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 100);
  }, [])

  if (isLoading) {
    return (
      <LazySettings />
    )
  }

  return (
    <>  
      <SEO title={`${t('settings.nav.ads')}`} />
      <AoButton 
          variant={"left"}
          icon={<AdsClick />}
          title={"Cantidad"}
          description={"Elige la cantidad de anuncios que quieres ver."}
          type={"link"}
          to="language"
          hasRightArrow={true}
      /> 
    </>
  )
}
