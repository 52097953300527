import React from 'react'
import { ArrowForwardIos, CheckRounded, Share as ShareIcon, ContentCopy, Facebook, LinkedIn, QrCode, Telegram, Twitter, WhatsApp } from '@mui/icons-material'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel, Navigation } from 'swiper/modules';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { QRCodeSVG }  from 'qrcode.react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const shareLinks = [
    {
        icon: <Twitter />,
        name: "Twitter / X",
        url: "https://twitter.com/intent/tweet?url=",
    },
    {
        icon: <WhatsApp />,
        name: "WhatsApp",
        url: "https://api.whatsapp.com/send?text=",
    },
    {
        icon: <Facebook />,
        name: "Facebook",
        url: "https://www.facebook.com/sharer/sharer.php?u=",
    },
    {
        icon: <LinkedIn />,
        name: "LinkedIn",
        url: "https://www.linkedin.com/sharing/share-offsite/?url="
    },
    {
        icon: <Telegram />,
        name: "Telegram",
        url: "https://t.me/share/url?url="
    }
]

export default function Share({ link }) {
    const { t } = useTranslation();
    const [isCopy, setIsCopy] = React.useState(false);
    const [isQr, setIsQr] = React.useState(false);

    const url = `https://aodesu.com${link}`;

    return (
        <>
            <div className='share'>
                <div className="swiper-button button-prev">
                    <ArrowForwardIos style={{rotate: '180deg'}} />
                </div>
                <div className="swiper-container">
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={15}
                        navigation={{
                            nextEl: '.button-next',
                            prevEl: '.button-prev',
                            disabledClass: 'swiper-button-disabled',
                            clickable: true
                        }}
                        mousewheel={true}
                        modules={[Navigation, Mousewheel]}
                        observeParents={true}
                    >
                        <SwiperSlide className='swiper-btn'>
                            <CopyToClipboard text={url} onCopy={() => {setIsCopy(true)}}>
                                <button>
                                    <div className='icon'>
                                        {isCopy ? <CheckRounded /> : <ContentCopy />}
                                    </div>
                                    <div className='name'>{t('words.copy')}</div>
                                </button>
                            </CopyToClipboard>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-btn'>
                            <CopyToClipboard text={url} onCopy={async () => {
                                try {
                                    await navigator
                                        .share({
                                            url
                                        })
                                } catch (err) {
                                    console.log(err)
                                }
                            }}>
                                <button>
                                    <div className='icon'>
                                        <ShareIcon />
                                    </div>
                                    <div className='name'>{t('words.share')}</div>
                                </button>
                            </CopyToClipboard>
                        </SwiperSlide>
                        <SwiperSlide className='swiper-btn'>
                            <button onClick={() => {setIsQr(!isQr);}}>
                                <div className='icon'>
                                    {isQr ? <CheckRounded /> : <QrCode />}
                                </div>
                                <div className='name'>QR</div>
                            </button>
                        </SwiperSlide>
                        {shareLinks.map((doc, i) => (
                            <SwiperSlide key={i} className='swiper-btn'>
                                <Link to={`${doc.url}${url}`} target='_blank'  rel='noreferrer noopener'>
                                    <div className='icon'>{doc.icon}</div>
                                    <div className='name'>{doc.name}</div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className="swiper-button button-next">
                    <ArrowForwardIos />
                </div>
            </div>
            
            {isQr &&
                <div className='QR'>
                    <div>
                        <QRCodeSVG 
                            value={url} 
                            size={250} 
                            level='H'
                            bgColor='transparent'
                            fgColor='#4169e1'
                            imageSettings={{
                                src: "https://cdn.discordapp.com/attachments/1117660519235604511/1192249732668592200/image.png?ex=65c41397&is=65b19e97&hm=46000d913cd950261d69aaa5cef1c601b35c22bab26842908f91c8921240c19b&",
                                excavate: true,
                                height: 75,
                                width: 75,
                            }}
                        />
                    </div>
                </div>
            }
        </>
    )
}
