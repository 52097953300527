import React, { useContext, useEffect, useState } from 'react'
import LazySettings from '../LazySettings';
import AoButton from '../../../components/buttons/Button';
import { AuthContext } from '../../../context/Auth';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, firestore } from '../../../services/firebase/accounts';
import i18n from '../../../services/i18n';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';
import Modal from '../../../components/modals/Modal';
import SetBirthdate from '../../../components/modals/SetBirthdate';
import Input from '../../../components/form/Input';
import { doc, updateDoc } from 'firebase/firestore';
import { AlertsContext } from '../../../context/Alerts';

const ChangeNick = ({ close }) => {
    const { t } = useTranslation();
    const { user, setUser, getUserData } = useContext(AuthContext);
    const { addAlert } = useContext(AlertsContext);

    const [ nickname, setNickname ] = useState("");

    const [ disabled, setDisabled ] = useState(true);
    const [ loading, setLoading ] = useState(false);

    const handleUpload = (e) => {
        e.preventDefault();
        if (!disabled) {
            setLoading(true);
            const userRef = doc(firestore, `usuarios/${user.uid}`);
            updateDoc(userRef, {
                name: [user.name, nickname]
            }).then(() => {
                let userArr = user;
                user.displayName = nickname;
                setUser(userArr);
                addAlert(t('alert.all-good'), "success", "outlined", "auto");
                getUserData(auth.currentUser.uid, auth.currentUser);
                close();
            })
        }
    }

    useEffect(() => {
        if (nickname.length >= 4) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [nickname])

    return (
        <form className='form' onSubmit={handleUpload}>
            <div className={`form-layout ${loading && 'loading'}`}>
                <div style={{ padding: '1rem .5rem'}}>
                    <Input 
                        label
                        labelText={"Nuevo nicname"}
                        variant={1}
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        required
                    />
                </div>
            </div>
            <div className="buttons-layout">
                <AoButton 
                    title={t('words.cancel')}
                    variant={"outline"}
                    size="big"
                    action={close}
                />
                <AoButton 
                    title={t('words.confirm')}
                    variant={"special-2"}
                    size="big"
                    submit
                    loading={loading}
                    disabled={disabled}
                />
            </div>
        </form>
    )
}

export function Information() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [ isLoading, setLoading ] = useState(true);
    const [ selectedLanguage ] = useState(i18n.language);

    const [ birthdate, setBirthdate ] = useState(false);
    const [ birthdateState, setBirthdateState ] = useState(null);

    const [ changeNick, setChangeNick ] = useState(false);
    const [ changeNickState, setChangeNickState ] = useState(false);

    function openBirthDate(){
        setBirthdate(true);
        setTimeout(() => {
            setBirthdateState('show');
        }, 100);
    }

    function closeBirthDate(){
        setBirthdateState(null);
        setTimeout(() => {
            setBirthdate(false);
        }, 100);
    }

    function openNick(){
        setChangeNick(true);
        setTimeout(() => {
            setChangeNickState('show');
        }, 100);
    }

    function closeNick(){
        setChangeNickState(null);
        setTimeout(() => {
            setChangeNick(false);
        }, 100);
    }

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setLoading(false);
            }
        })
    }, [])

    if (isLoading) {
        return (
            <LazySettings />
        )
    }

    return (
        <>
            <SEO title={`${t('settings.nav.account')} - ${t('settings.nav.information')}`} />
            <AoButton 
                variant={"left"}
                title={t('words.username')}
                description={`@${user?.name}`}
                /* hasRightArrow={true} */
            />
            <AoButton 
                variant={"left"}
                title={t('words.nickname')}
                description={user?.displayName ? user?.displayName : t('words.no nickname')}
                action={openNick}
                hasRightArrow={true}
            />
            <AoButton 
                variant={"left"}
                title={t('words.phone number')}
                description={`${user?.phoneNumber ? t('words.yes') : t('words.no')}`}
                disabled={true}
            />
            <AoButton 
                variant={"left"}
                title={t('words.email')}
                description={`${user?.email}`}
            />
            <AoButton 
                variant={"left"}
                title={t('words.emailVerified')}
                disabled={true}
                description={`${user?.emailVerified ? t('words.yes') : t('words.yes')}`}
            />
            <AoButton 
                variant={"left"}
                title={t('words.date birth')}
                action={!user?.birthday ? openBirthDate : undefined}
                description={user?.birthday ? new Intl.DateTimeFormat(selectedLanguage, { dateStyle: 'long', timeZone: 'GMT'}).format(user?.birthday) : 'Agregar cumpleaños'}
            />
            <div className="divider"></div>
            <AoButton 
                variant="left"
                title={t('words.age')}
                description={user?.age}
            />
            <AoButton 
                variant={"left"}
                title={t('words.account creation')}
                description={new Intl.DateTimeFormat(selectedLanguage, { dateStyle: 'full', timeStyle: 'long'}).format(user?.metadata.createdAt)}
            />
            {birthdate &&
                <Modal
                    title={t('modal.birthdate.title')}
                    visible={birthdateState}
                    close={closeBirthDate}
                >
                    <SetBirthdate close={closeBirthDate} />
                </Modal>
            }
            {changeNick &&
                <Modal
                    title={t('modal.change-nickname.title')}
                    visible={changeNickState}
                    close={closeNick}
                >
                    <ChangeNick close={closeNick} />
                </Modal>
            }
        </>
    )
}
