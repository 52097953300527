import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function AdsTerra() {
    const { t } = useTranslation();
    const banner = useRef();
    const [ loading, setLoading ] = useState(true);

    const atOptions = {
        key: 'eabafebcc2837719a89fafa4c4f88f44',
        format: 'iframe',
        height: 90,
        width: 728,
        params: {},
    }

    useEffect(() => {
        if (loading) {
            if (banner.current && !banner.current.firstChild) {
                const conf = document.createElement('script')
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.src = `//sillinesswoollen.com/${atOptions.key}/invoke.js`
                conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`
    
                banner.current.append(conf)
                banner.current.append(script)
                setLoading(false);
            }
        }
    }, [banner])
    
    return (
        <div className='ads-horizontal'>
            <div className="ad-title">{t('words.ad')}</div>
            <div className="ad-body">
                <div className="no-ad">Aquí debia ir un anuncio :c</div>
                <div className='ad' ref={banner}></div>
            </div>
        </div>
    )
}
