import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { FreeMode, Navigation } from 'swiper/modules';
import { Button, useMediaQuery } from '@mui/material';
import BrowseCard from '../home/BrowseCard';
import AdsCard from '../home/AdsCard';

export default function SwiperHomeSections({ data, ads, episodes, id }) {    
    const arr = useMemo(() => Array.from(data).reverse(), [data]);

    const match = useMediaQuery('(min-width: 50em)')

    return (
        <>
        <div className='section-content'>
            <Button className={`button swiper-button button-prev button-prev-section-${id}`}>
                <ChevronLeft />
            </Button>
            <Swiper 
                slidesPerView={"auto"}
                navigation={{
                    nextEl: `.button-next-section-${id}`,
                    prevEl: `.button-prev-section-${id}`,
                    disabledClass: 'disabled',
                    clickable: true,
                }}
                freeMode={match ? false : true}
                modules={[Navigation, FreeMode]}
                className={`swiper ${episodes && 'wide'}`}
            >
                {!episodes &&
                    <SwiperSlide>
                        <AdsCard data={id} />
                    </SwiperSlide>
                }
                {arr.map((doc, i) => (
                    <SwiperSlide key={`${data.name}-${i}`}>
                        <BrowseCard data={doc} episodes={episodes} />
                    </SwiperSlide>
                ))}
            </Swiper>
            <Button className={`button swiper-button button-next button-next-section-${id}`}>
                <ChevronRight />
            </Button>
        </div>
        </>
    )
}
