import React, { useContext, useEffect, useState } from 'react'
import AoButton from '../buttons/Button'
import { useTranslation } from 'react-i18next'
import Input from '../form/Input';
import i18n from '../../services/i18n';
import { FormControl, MenuItem, Select } from '@mui/material';
import { AuthContext } from '../../context/Auth';
import { doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase/accounts';
import { AlertsContext } from '../../context/Alerts';

export default function SetBirthdate({ close }) {
    const { t } = useTranslation();
    const { user, setUser } = useContext(AuthContext);
    const { addAlert } = useContext(AlertsContext);
    const currentYear = new Date().getFullYear();
    const [ selectedDate, setSelectedDate ] = useState("0");
    const [ selectedMonth, setSelectedMonth ] = useState("0");
    const [ selectedYear, setSelectedYear ] = useState("0");
    const [ previewDate, setPreviewDate ] = useState(null);
    const [ finalDate, setFinalDate ] = useState(null);

    const [ disabled, setDisabled ] = useState(true);

    const [ loading, setLoading ] = useState(false);

    const handleDateChange = (e) => {
        let { value } = e.target;
        setSelectedDate(value);
        updateSelectedDate(value, selectedMonth, selectedYear);
    };

    const handleMonthChange = (e) => {
        let { value } = e.target;
        setSelectedMonth(value);
        updateSelectedDate(selectedDate, value, selectedYear);
    };

    const handleYearChange = (e) => {
        let { value } = e.target;
        setSelectedYear(value);
        updateSelectedDate(selectedDate, selectedMonth, value);
    };

    const updateSelectedDate = (date, month, year) => {
        if (date !== "0" && month !== "0" && year !== "0") {
            setDisabled(false);
            const formattedDate = new Date(year, month - 1, date);
            setPreviewDate(new Intl.DateTimeFormat(i18n.language, { dateStyle: 'full', timeZone: 'GMT'}).format(formattedDate));

            const dt = new Date(formattedDate);
            setFinalDate(dt.getTime())
        }
    };

    const renderDateOptions = () => {
        const dateOptions = [
          <MenuItem key={0} value={"0"} disabled>
            {t('form.date.day')}
          </MenuItem>,
        ];
    
        for (let i = 1; i <= 31; i++) {
          dateOptions.push(
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          );
        }
    
        return dateOptions;
    };

    const renderMothOptions = () => {
        const monthOptions = [
            <MenuItem key={0} value={"0"} disabled>
                {t('form.date.month')}
            </MenuItem>,
        ];

        for (let i = 1; i <= 12; i++) {
            monthOptions.push(
                <MenuItem key={i} value={i}>
                    {new Date(2000, i - 1, 1).toLocaleString(i18n.language, {
                        month: 'long'
                    })}
                </MenuItem>
            )
        }

        return monthOptions;
    }

    const renderYearOptions = () => {
        const yearOptions = [
          <MenuItem key={0} value={"0"} disabled>
          {t('form.date.year')}
          </MenuItem>,
        ];
    
        for (let i = currentYear; i >= 1900; i--) {
          yearOptions.push(
            <MenuItem key={i} value={i}>
              {i}
            </MenuItem>
          );
        }
    
        return yearOptions;
    };

    const uploadDate = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userRef = doc(firestore, `usuarios/${user.uid}/settings/account`);
        const userSnap = await getDoc(userRef);

        const success = () => {
            let userArr = user;

            function getAge(date){
                if (date) {
                    const today = new Date().getTime();
        
                    const diff = today - date;
                    const ageNumber = Math.floor(diff / (1000 * 60 * 60 * 24 * 365.25));
        
                    return ageNumber;
                }
            }

            userArr.birthday = finalDate;
            userArr.age = getAge(finalDate);

            setUser(userArr);
            addAlert(t('alert.all-good'), "success", "outlined", "auto");
            close();
        }

        if (userSnap.exists()) {
            const data = userSnap.data().data[0];
            data.birthday = finalDate;

            updateDoc(userRef, {
                data: [data]
            }).then(() => {
                success();
            })
        } else {
            setDoc(userRef, {
                data: [{birthday: finalDate}]
            }).then(() => {
                success();
            })
        }
    }

    return (
        <form className="form" onSubmit={uploadDate}>
            <div className={`form-layout ${loading && 'loading'}`}>
                <div style={{ padding: '1.5rem .5rem 0' }}>
                    <div className='flex flow-row gap-normal'>
                        <FormControl size='small' fullWidth>
                            <Select className='select' value={selectedDate} onChange={handleDateChange}>
                                {renderDateOptions()}
                            </Select>
                        </FormControl>
                        <FormControl size='small' fullWidth>
                            <Select className='select' value={selectedMonth} onChange={handleMonthChange}>
                                {renderMothOptions()}
                            </Select>
                        </FormControl>
                        <FormControl size='small' fullWidth>
                            <Select className='select' value={selectedYear} onChange={handleYearChange}>
                                {renderYearOptions()}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className="buttons-layout">
                    <div className='flex align-center' style={{flex: 1}}>{previewDate}</div>
                    <AoButton 
                        title={t('words.cancel')}
                        variant={"outline"}
                        size="big"
                        action={close}
                    />
                    <AoButton 
                        title={t('words.confirm')}
                        variant={"special-2"}
                        size="big"
                        submit
                        loading={loading}
                        disabled={disabled}
                    />
                </div>
            </div>
        </form>
  )
}
