import React, { useContext } from 'react'
import { SettingsContext } from '../../../context/Settings'
import { Button } from '@mui/material';
import AoButton from '../../../components/buttons/Button';
import { AutoMode, DarkMode, LightMode } from '@mui/icons-material';
import SEO from '../../../services/seo';
import { useTranslation } from 'react-i18next';

export default function Appearance({ zone }) {
  const { t } = useTranslation();
  const { theme, changeTheme } = useContext(SettingsContext);

  if (zone) {
    return (
      <div className='flex flow-column w100'>
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "sp" && 'active'}`}
          action={() => changeTheme('sp')}
          title="Preferencia del sistema"
          icon={<AutoMode />}
        />
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "light" && 'active'}`}
          action={() => changeTheme('light')}
          title="Claro"
          icon={<LightMode />}
        />
        <AoButton 
          variant={"theme"}
          type={"select"}
          className={`${theme === "dark" && 'active'}`}
          action={() => changeTheme('dark')}
          title="Oscuro"
          icon={<DarkMode />}
        />
      </div>
    )
  }

  return (
    <>
    <SEO title={`${t('settings.nav.preferences')} - ${t('settings.nav.appearance')}`} />
      <div className="settings-section">
        <h2 className='section-title'>Tema</h2>
        <div className="flex flow-row gap-bigger">
          <Button
            className={`button theme-selector ${theme === "sp" && 'active'}`}
            onClick={() => changeTheme('sp')}
          >
            <div className="preview sp">
              <div></div>
              <div></div>
            </div>
            <span className='theme-name'>Preferencia del sistema</span>
          </Button>
          <Button
            className={`button theme-selector ${theme === "light" && 'active'}`}
            onClick={() => changeTheme('light')}
          >
            <div className="preview light"></div>
            <span className='theme-name'>Claro</span>
          </Button>
          <Button
            className={`button theme-selector ${theme === "dark" && 'active'}`}
            onClick={() => changeTheme('dark')}
          >
            <div className="preview dark"></div>
            <span className='theme-name'>Oscuro</span>
          </Button>
        </div>
      </div>
    </>
  )
}
