import React, { useContext, useEffect, useState } from 'react';
import './Premium.scss';
import { useTranslation } from 'react-i18next';
import SEO from '../../services/seo';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ArrowDownward } from '@mui/icons-material';
import AoButton from '../../components/buttons/Button';
import PremiumLogo from '../../images/Premium';
import { BuyPremiumContext } from '../../context/BuyPremium';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../../services/firebase/accounts';
import { AuthContext } from '../../context/Auth';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const specialPremium = {
  "cover": "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fperks%2Freproductor.webp?alt=media&token=f5469001-6ae7-46da-8c9e-7f16c03eb22f",
  "type": "reproductor"
}

const data = [
  {
    "cover": "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fperks%2Flibraries.webp?alt=media&token=2acfcc57-75df-43bb-b91d-47987db55e71",
    "type": "libraries"
  },
  {
    "cover": "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fperks%2Fcustomize.webp?alt=media&token=9d4396c9-2bf5-43de-b6c7-4993df026c5c",
    "type": "customize"
  },
  {
    "cover": "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fperks%2Fno-ads.webp?alt=media&token=1eb83548-0e32-4288-b5f0-7ba249c43397",
    "type": "no-ads"
  }
]

const PremiumAdvantage = ({ doc, i }) => {
  const { t } = useTranslation();
  return (
    <div className={`perk ${i % 2 ? 'r' : 'l'}`}>
      <div className="perk-image">
        <img src={doc.cover} alt={t(`premium.${doc.type}.title`)} />
      </div>
      <div className="perk-text">
        <h3>{t(`premium.perks.${doc.type}.title`)}</h3>
        <p>{t(`premium.perks.${doc.type}.desc`)}</p>
      </div>
    </div>
  )
}

export default function PremiumInfo() {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const { openModal } = useContext(BuyPremiumContext);
  const [ plans, setPlans ] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const premiumBanner = "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fbanner.webp?alt=media&token=e694b951-fb28-4c38-9aa6-a3a78b2f687d";
  const alreadyBanner = "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Falready.webp?alt=media&token=0fe9e8b1-5bb8-4dd4-9cb2-6b65545af3f8";
  const plansBanner = "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/premium%2Fplans.webp?alt=media&token=cd3691d3-3b0b-4b5a-957c-fa1cce61c6d7";

  useEffect(() => {
    async function getPlans() {
      const plansRef = doc(firestore, `premium/plans`);
      const plansSnap = await getDoc(plansRef);

      setPlans(plansSnap.data().data);
    }

    if (user) {
      if (plans.length < 1) getPlans();
    }
  }, [user]);

  if (user === null) {
    return (
      <>
        <SEO title={t('SEO.premium.title')} description={t('SEO.premium.desc')} />
        <section className="premium-header">
          <div className="premium-header_body">
            <div className="progress">
            <CircularProgress className='progress-circular normal' />
            </div>
          </div>
        </section>
      </>
    )
  }

  if (user?.badges.includes('premium')) {
    return (
      <>
        <SEO title={t('SEO.premium.title')} description={t('SEO.premium.desc')} />
        <section className='premium-header'>
          <div className="premium-header_body">
            <div className="premium-header_body-data">
              <h1>{t('premium.already.title')}</h1>
              <AoButton 
                type={"link"}
                variant={"special-2 uppercase"}
                size={"big"}
                title={t('premium.already.btn')}
                to="/"
                style={{ width: 'max-content', margin: '0 auto'}}
              />
            </div>
          </div>
          <div className='premium-header_background'>
            <picture>
              <source src={premiumBanner} type='image/webp' />
              <LazyLoadImage 
                src={alreadyBanner}
                effect='blur'
              />
            </picture>
          </div>
        </section>
      </>
    )
  }

  return (
    <>
      <SEO title={t('SEO.premium.title')} description={t('SEO.premium.desc')} />
      <section className='premium-header'>
        <div className="premium-header_body">
          <div className="premium-header_body-data">
            <h1>{t('premium.seo-title')}</h1>
              <a className='link uppercase bold' href="#plans">{t('premium.compare-plans')} <ArrowDownward /></a>
          </div>
        </div>
        <div className='premium-header_background'>
          <picture>
            <source src={premiumBanner} type='image/webp' />
            <LazyLoadImage 
              src={premiumBanner}
              effect='blur'
            />
          </picture>
        </div>
      </section>
      <section className='premium-perks'>
        <div className='perks-layout'>
          <div className="perks">
            <h2 className="perks-title">{t('premium.perks.title')}</h2>
            <div className="perks-body">
              {data.map((doc, i) => (
                <PremiumAdvantage doc={doc} i={i} key={i} />
              ))}
            </div>
          </div>
        </div>
        <div className="perk-special">
          <div className="perk-special-thumbnail">
            <LazyLoadImage 
              src={specialPremium.cover}
              effect='blur'
            />
          </div>
          <div className="perk-special-data">
            <h3>{t(`premium.perks.${specialPremium.type}.title`)}</h3>
            <p>{t(`premium.perks.${specialPremium.type}.desc`)}</p>
          </div>
        </div>
      </section>
      <section id="plans" className='premium-plans'>
        <div className='plans_background'>
          <picture>
            <source src={plansBanner} type='image/webp' />
            <LazyLoadImage 
              src={plansBanner}
              effect='blur'
            />
          </picture>
        </div>
        <div className="premium-plans_layout">
          <div className="premium-plans_layout-body">
            {user ? 
              <>
                {plans.map((doc, i) => (
                  <div className="premium-plans_card" key={i}>
                    <div className="premium-plans_card-background">
                      <div className="icon">
                        <PremiumLogo />
                      </div>
                      <div className='bg' style={{backgroundImage: `url(${doc.banner})`}}></div>
                    </div>
                    <div className="premium-plans_card-header">
                      <h3 className="plan-name">{t(`premium.plans.${doc.name}.name`)}</h3>
                      <div className='plan-price'>
                        <span className="price">{doc.price}</span>
                        <span className="currency">{t(`${doc.currency}$`)}</span>
                        <span className="period">{`/${t(`premium.plans.period.${doc.period}`)}`}</span>
                      </div>
                      <div className='plan-buy'>
                        <AoButton 
                          variant={"special-2 outline uppercase"}
                          title={t('words.buy')}
                          action={() => openModal(doc)}
                        />
                      </div>
                      <div className="divider"></div>
                      <div className="premium-plans_card-footer">
                        <span>{t(`premium.plans.${doc.name}.desc`)}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </>
              :
              <>
                <AoButton 
                  title={t('auth.register')}
                  variant={"special-2"}
                  style={{ transform: 'scale(1.2)'}}
                  action={() => {
                    navigate('/auth/register');
                    sessionStorage.setItem('back-path', location.pathname);
                  }}
                  size={"big"}
                />
              </>
            }
          </div>
          <div className="disclaimer">
            <span>{t('premium.disclaimer')}</span>
          </div>
        </div>
      </section>
    </>
  )
}
