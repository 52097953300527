import { useContext, useEffect } from "react";
import { AuthContext } from "../../../../context/Auth";
import { ModalsContext } from "../../../../context/Modals";
import { BuyPremiumContext } from "../../../../context/BuyPremium";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../../../services/firebase/accounts";

const style = {"layout":"vertical"};

export const PayPalButtonWrapper = ({ plan, setSuccess, setPayState, setLoading }) => {
    const { user } = useContext(AuthContext);
    const { closePremiumExpired } = useContext(ModalsContext);
    const { setSuccessEndDate, OpenSuccess, CloseAll } = useContext(BuyPremiumContext);
    const [{ isPending }] = usePayPalScriptReducer();

    useEffect(() => {
        if (isPending) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isPending])

    return (
        <PayPalButtons 
            style={style}
            disabled={false}
            forceReRender={[style]}
            fundingSource={undefined}
            createOrder={async (data, actions) => {
                return actions.order
                    .create({
                        purchase_units: [
                            {
                                amount: {
                                    currency_code: plan.currency,
                                    value: plan.price,
                                },
                            },
                        ],
                    })
                    .then((orderId) => {
                        return orderId;
                    })
            }}
            onApprove={async function (data, actions) {
                return actions.order.capture().then(function () {
                    setLoading(true);
                    const roleRef = doc(firestore, `usuarios/${user.uid}/roles/premium`);
                    const now = new Date();
                    let expirationTime;

                    if (plan.period === 'month') expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, now.getUTCDate()));
                    if (plan.period === 'year') expirationTime = new Date(Date.UTC(now.getUTCFullYear() + 1, now.getUTCMonth(), now.getUTCDate()));

                    setDoc(roleRef, {
                        expire: [{
                            expire: expirationTime.getTime(),
                            tier: plan.id
                        }]
                    }).then(() => {
                        const userRef = doc(firestore, `usuarios/${user.uid}`);
                        
                        updateDoc(userRef, {
                            badges: arrayUnion('premium')
                        }).then(async () => {
                            const historyRef = doc(firestore, `usuarios/${user.uid}/settings/premium-history`);
                            const historySnap = await getDoc(historyRef);

                            let arrayData = {
                                name: plan.name,
                                period: plan.period,
                                price: plan.price,
                                currency: plan.currency,
                                tier: plan.id,
                                pay_method: 'PayPal',
                                startAt: now.getTime()
                            }

                            if (historySnap.exists()) {
                                updateDoc(historyRef, {
                                    history: arrayUnion(arrayData)
                                }).then(() => {
                                    setSuccess(expirationTime.getTime());
                                })
                            } else {
                                setDoc(historyRef, {
                                    history: [arrayData]
                                }).then(() => {
                                    setSuccess(expirationTime.getTime());
                                })
                            }
                        })
                    })
                }).catch(function (data, actions) {
                    console.log(data)
                })
            }}
            onError={function (data, actions) {
                console.log(data);
                console.log('error')
                setLoading(false);
                setPayState(false);
            }}
            onCancel={function(){
                console.log('cancelado')
                setLoading(false);
            }}
        />
    )
}