import React from 'react'

export default function Policy() {
  return (
    <div style={{ padding: '1rem'}}>
      POLÍTICA DE PRIVACIDAD <br/><br/>
      Nuestra política de privacidad establece los términos en que Aodesu usa y protege la información que es proporcionada por sus usuarios al momento de utilizar su sitio web. Cuando se le pide al usuario rellenar espacios con información se hace de manera opcional, y si el usuario ingresa datos de contacto, serán usados para comunicarnos con este mismo. Esta Política de privacidad puede estar sujeta a cambios en el futuro, por lo que recomendamos a los usuarios revisar continuamente este apartado para asegurarse que este de acuerdo con los posibles cambios a futuro.
      <br/><br/>
      Aodesu asumirá que se esta de acuerdo con la Política de Privacidad si se continua navegando en la web.
      <br/><br/>
      INFORMACIÓN QUE ES RECOGIDA <br/><br/>
      Nuestro sitio web podrá recoger información personal, que sera dada por el usuario al momento de iniciar sesión, por ejemplo: Nombre o Nick y dirección de correo electrónico. Esto se hara con el fin de tener un registro del público.
      <br/><br/>
      USO DE LA INFORMACIÓN RECOGIDA <br/><br/>
      Utilizamos la información proporcionada por el usuario para mejorar el servicio, ya sea con el registro de estos mismos o los reportes que están integrados en la web.
      <br/><br/>
      FORMULARIOS <br/><br/>
      Aodesu recogera información de los formularios proporcionados de manera opcional por los usuarios.
      <br/><br/>
      Esta información será utilizada para contactar a los usuarios por correo electrónico y ofrecer un servicio mas adaptado al usuario.
      <br/><br/>
      ALMACENAMIENTO LOCAL <br/><br/>
      El almacenamiento local se refiere a la información que podremos almacenar en su navegador y nada mas que su navegador, esta información será utilizada por la web app, para mejorar su conformidad con la web, gracias a la selección de por ejemplo: tema, idioma del buscador, reproductor e idioma de reproducción favorita, tus listas y la marcación de los capitulos vistos y no vistos, etc.
      <br/><br/>
      ENLACES A TERCEROS <br/><br/>
      Este sitio web puede contener enlaces a otros sitios que podrian ser de su interés. Una vez se de click en estos enlaces y abandone nuestra página, ya no tendremos control sobre al sitio al que es redirigido y por lo tanto, no somos responsables de los términos o privacidad ni de la protección de sus datos en estos sitios de terceros. Dichos sitios están sujetos a sus propias políticas de privacidad por lo cual es recomendable que las consulte para confirmar que usted está de acuerdo con estas.
      <br/><br/>
      CONTROL DE SU INFORMACIÓN PERSONAL <br/><br/>
      Esta compañia no venderá, cederá ni distribuirá la información personal que es recopilada sin su consentimiento, salvo que sea requerido por un juez con orden judicial. Aodesu se reserva el derecho de cambiar los términos de la presente Política de privacidad en cualquier momento, pero estará sujeto a dar el aviso a los usuarios.
    </div>
  )
}
