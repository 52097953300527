import React from 'react'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import AoButton from '../buttons/Button'
import { ArrowForwardIos, ChevronLeft, ChevronRight } from '@mui/icons-material'
import { Button } from '@mui/material'

export default function SwiperLayout({ children, name }) {
  return (
    <div className='swiper-layout'>
        <Button className={`button swiper-button button-prev-${name}`}>
            <ChevronLeft />
        </Button>
        <Swiper
            slidesPerView={"auto"}
            className='swiper'
            navigation={{
                nextEl: `.button-next-${name}`,
                prevEl: `.button-prev-${name}`,
                disabledClass: 'disabled',
                clickable: true,
            }}
            modules={[Pagination, Navigation]}
        >
            {children}
        </Swiper>
        <Button className={`button swiper-button button-next-${name}`}>
            <ChevronRight />
        </Button>
    </div>
  )
}
