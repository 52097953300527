import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import AoButton from '../buttons/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ModalsContext } from '../../context/Modals';
import { BuyPremiumContext } from '../../context/BuyPremium';

export default function OnlyPremium() {
    const { t } = useTranslation();
    const [ image, setImage ] = useState('');
    const { openModal } = useContext(BuyPremiumContext);
    const { closeOnlyPremium } = useContext(ModalsContext);

    useEffect(() => {
        let ignore = false;

        if (!ignore) {
            if (image === '') {
                fetch('https://api.waifu.pics/sfw/smug')
                .then((response) => response.json())
                .then((image) => setImage(image.url))
            }
        }

        return () => ignore = true;
    }, [])

    return (
        <div>
            <div className='flex flow-column gap-normal' style={{ padding: '.5rem'}}>
                <span>{t('modal.need-premium.desc')}</span>
                <div className='flex justify-center align-center gif' style={{ maxHeight: '35vh', borderRadius: '5px', overflow: 'hidden'}}>
                    <LazyLoadImage 
                        style={{ width: '100%'}}
                        src={image}
                        effect='blur'
                    />
                </div>
            </div>
            <div className="buttons-layout">
                <AoButton 
                    title={t('words.cancel')}
                    variant={"outlined"}
                    size={"big"}
                    action={closeOnlyPremium}
                />
                <AoButton 
                    title={t('words.buy premium')}
                    variant={"special-2"}
                    size={"big"}
                    action={() => {
                        closeOnlyPremium();
                        openModal(null);
                    }}
                />
            </div>
        </div>
    )
}
