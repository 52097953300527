import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './BrowseCard.scss';
import { Link } from 'react-router-dom';
import { ModalsContext } from '../../context/Modals';
import { Menu, useMediaQuery } from '@mui/material';
import AoButton from '../buttons/Button';
import { IosShare } from '@mui/icons-material';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AuthContext } from '../../context/Auth';
import { adsFirestore } from '../../services/firebase/ads';
import { collection, doc, getDocs, increment, query, updateDoc, where } from 'firebase/firestore';

export default function AdsCard({ data, type, episodes }) {
    const { t } = useTranslation();
    const { adsLength } = useContext(AuthContext);
    const { openAdModal } = useContext(ModalsContext);
    
    const [ imageLoaded, setImageLoaded ] = useState(false);
    const [ ad, setAd ] = useState(null);
    
    function openAd() {
        if (!ad) return;

        openAdModal(ad);
        const statisticsRef = doc(adsFirestore, `ads/${ad.id}/statistics/interactions`);

        updateDoc(statisticsRef, {
            ad_clicks: increment(1)
        })
    }

    useEffect(() => {
        function getAd(){
            function getRandom() {
                return Math.floor(Math.random() * adsLength);
            }

            function setImpression(id) {
                const statisticsRef = doc(adsFirestore, `ads/${id}/statistics/interactions`);
    
                updateDoc(statisticsRef, {
                    impresions: increment(1)
                })
            }

            async function getAndSetAd() {
                const int = getRandom();

                if (sessionStorage.getItem(`ad-ID-${int}`)) {
                    const storage = JSON.parse(sessionStorage.getItem(`ad-ID-${int}`));
                    setAd(storage);
                    setImpression(storage.id);
                } else {
                    const adRef = collection(adsFirestore, 'ads');
                    const q = query(adRef, where("query", "array-contains", `ID-${int}`));
    
                    const snapshot = await getDocs(q);
    
                    let arr = snapshot.docs[0].data().data[0];
                    arr.id = snapshot.docs[0].id;

                    setAd(arr);
                    
                    setImpression(arr.id);
                    sessionStorage.setItem(`ad-ID-${int}`, JSON.stringify(arr));
                }
            }

            getAndSetAd();
        }
        
        if (!adsLength) return;
        
        if (ad) return;

        getAd();
    }, [adsLength])

    if (!ad) {
        if (episodes) {
            return (
                <div className="browse-card wide lazy">
                    <div className="browse-card__poster">
                        <div className="figure-wrapper">
                            <div className="figure-wrapper__figure-sizer">
                                <div className="figure episode"></div>
                            </div>
                        </div>
                    </div>
                    <div className="browse-card__body">
                        <div className="browse-card__title"></div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className='browse-card lazy'>
                    <div className='browse-card__poster-wrapper'>
                        <div className="browse-card__poster">
                            <div className="figure-wrapper"></div>
                        </div>
                    </div>
                    <div className="browse-card__body">
                        <div className='browse-card__title'></div>
                    </div>
                </div>
            )
        }
    }

    return (
        <div role='button' onClick={openAd} className='browse-card'>
            <div className='browse-card__poster-wrapper'>
                <div className="browse-card__poster">
                    <div className="figure-wrapper">
                        <div className="tag">
                            {t('words.ad')}
                        </div>
                        <div className={`figure-wrapper__figure-sizer ${imageLoaded && 'loaded'}`}>
                            <figure className="figure">
                                <picture>
                                    <source srcSet={ad.images.tall} type='image/webp' />
                                    <img onLoad={() => setImageLoaded(true)} src={ad.images.tall} alt={`${ad.title} - aodesu`} />
                                </picture>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="browse-card__body">
                <h4 className='browse-card__title'>
                    <div className='browse-card__title-link'>
                        {ad.title}
                    </div>
                </h4>
                <div className="browse-card__footer">
                    <div className="tags-and-rating">
                        <span>{`@${ad.author}`}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
