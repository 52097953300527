import React from 'react';
import { AuthProvider } from './context/Auth';
import { AlertsProvider } from './context/Alerts';
import { SettingsProvider } from './context/Settings';
import { ModalsProvider } from './context/Modals';
import { ContentListsProvider } from './context/ContentLists';
import { BuyPremiumProvider } from './context/BuyPremium';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

function App({ children }) {
  return (
    <SettingsProvider>
      <AuthProvider>
        <BuyPremiumProvider>
          <PayPalScriptProvider
            options={{
              clientId: String(process.env.REACT_APP_PAYPAL_CLIENT_ID),
              components: 'buttons',
              currency: "USD",
            }}
          >
            <ModalsProvider>
              <AlertsProvider>
                <ContentListsProvider>
                  {children}
                </ContentListsProvider>
              </AlertsProvider>
            </ModalsProvider>
          </PayPalScriptProvider>
        </BuyPremiumProvider>
      </AuthProvider>
    </SettingsProvider>
  );
}

export default App;
