import React from 'react'
import { Helmet } from 'react-helmet-async'

export default function SEO({ title, description }) {
  return (
    <Helmet prioritizeSeoTags>
        <title>{title} - aodesu</title>
        <meta name="description" content={description} />

        <meta name="og:type" content="website" />
        <meta name="og:title" content={`${title} - aodesu`} />
        <meta name="og:description" content={description} />
        <meta name="og:site_name" content='aodesu' />
        { /* End Facebook tags */ }
        { /* Twitter tags */ }
        {/* <meta name="twitter:creator" content="@aodesu_anime" /> */}
        <meta name="twitter:title" content={`${title} - aodesu`} />
        <meta name="twitter:description" content={description} />
    </Helmet>
  )
}
