import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import './Genres.scss';
import getDBLang from '../../components/hooks/getDBLang';
import { doc, getDoc } from 'firebase/firestore';
import BrowseCard from '../../components/home/BrowseCard';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SEO from '../../services/seo';
import { ModalsContext } from '../../context/Modals';
import AoButton from '../../components/buttons/Button';
import { IosShare } from '@mui/icons-material';
import Modal from '../../components/modals/Modal';

const GenresList = ({ genre }) => {
    const { t } = useTranslation();
    const [ animes, setAnimes ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const now = new Date();
        const lang = getDBLang();

        async function getAnimes(cache) {
            import(`../../services/firebase/anime_${lang}`)
            .then(async (db) => {
                const genreRef = doc(db.animeFirestore, `genres/${genre}`);
                const genreSnap = await getDoc(genreRef);

                if (genreSnap.exists()){
                    const data = genreSnap.data().data;
                    setAnimes(data);

                    if (cache) {
                        const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 1));
    
                        var headers = new Headers();
                        headers.append('expire', expirationTime.getTime());
        
                        cache.put(`/genres/${genre}`, new Response (JSON.stringify(data), {
                            headers: headers
                        }));
                    }
                    setLoading(false);
                } else {
                    setLoading(false);
                }

            })
        }

        if (!loading) return;

        function isValid(response) {
            if (!response) return false;
            var fetched = response.headers.get('expire');
            if (fetched > now.getTime()) return true;
        }

        setTimeout(() => {
            if ('caches' in window) {
                caches.open(`/${lang}`)
                .then((cache) => {
                    cache.match(`/genres/${genre}`)
                    .then(async (response) => {
                        if (isValid(response)) {
                            var data = await response.json();
                            setAnimes(data);
                            setLoading(false);
                        } else {
                            getAnimes(cache);
                        }
                    })
                })
            }
        }, 500)

    }, [loading]);

    useEffect(() => {
        setLoading(true);
    }, [genre])

    if (loading) {
        return (
            <div className='genres-list'>
                {Array.from({ length: 12}).map((i, index) => (
                    <BrowseCard type="lazy" />
                ))}
            </div>
        )
    }

    return(
        <>
            {animes.length > 0 ?
                <div className='genres-list'>
                    {animes.map((doc, i) => (
                        <BrowseCard data={doc} key={i} />
                    ))}
                    </div>
                :
                <div className='flex justify-center'>
                    <div className="section-content">
                        <div className="section-wrapper">
                            <div className="section-wrapper__body">
                                <LazyLoadImage 
                                    src='https://i.ibb.co/fVRLKj9/clean.webp'
                                />
                                <h2>{t('error.home/no-more')}</h2>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default function Genres() {
    const { t } = useTranslation();
    const params = useParams();
    const { serieCard, serieCardState, serieCardData, closeSerieCard, serieCardType, openShare } = useContext(ModalsContext);

    return (
        <>
            <SEO title={`${t(`categories.genres.${params.genre}`)}`} />
            <div className='genres-container'>
                <div className="genres-title">
                    <h1>{t(`categories.genres.${params.genre}`)}</h1>
                </div>
                <div className="genres-body">
                    <div className="genres-body_data">
                        <GenresList genre={params.genre} />
                    </div>
                </div>
            </div>
            {serieCard &&
                <Modal 
                    variant={'user-menu'} 
                    title={serieCardType === 'episode'
                            ? `${t('words.shorts.season')}${serieCardData.info.season.id} ${t('words.shorts.episode')}${serieCardData.id} - ${serieCardData.info.season.name}` 
                            : `${serieCardData.name}`} 
                    close={closeSerieCard} 
                    visible={serieCardState}
                    >
                    <div className='header-menu' style={{ padding: '.5rem'}}>
                        {serieCardType === '' &&
                            <>
                                <AoButton 
                                icon={<IosShare />}
                                title={t('words.share')}
                                size={"big"}
                                action={() => {
                                    closeSerieCard();
                                    openShare(`/anime/${serieCardData.id}`);
                                }}
                                />
                            </>
                        }
                        {serieCardType === 'episode' &&
                            <div className='flex flow-column gap-normal'>
                                <AoButton 
                                icon={<IosShare />}
                                title={t('words.share episode')}
                                size={"big"}
                                action={() => {
                                    closeSerieCard();
                                    openShare(`/watch/${serieCardData.info.serie.id}-${serieCardData.info.season.id}-${serieCardData.id}`);
                                }}
                                />
                                <AoButton 
                                icon={<IosShare />}
                                title={t('words.share anime')}
                                size={"big"}
                                action={() => {
                                    closeSerieCard();
                                    openShare(`/anime/${serieCardData.info.serie.id}-${serieCardData.info.season.id}`);
                                }}
                                />
                            </div>
                        }
                    </div>
                </Modal>
            }
        </>
    )
}
