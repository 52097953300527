import React, { useEffect, useState } from 'react'
import Form from '../../components/form/Form'
import { useTranslation } from 'react-i18next'
import Input from '../../components/form/Input';
import AoButton from '../../components/buttons/Button';
import AuthMethods from '../../components/form/AuthMethods';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { auth, firestore } from '../../services/firebase/accounts';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import SEO from '../../services/seo';

export default function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ confirmPassword, setConfirmPassword ] = useState(''); 

  const [ disabled, setDisabled ] = useState(true);

  const [ isLoading, setLoading ] = useState(false);
  const [ localError, setLocalError ] = useState('');

  const [ error, setError ] = useState('');
  const [ formLoading, setFormLoading ] = useState(false);

  const register = () => {
    if (disabled === false) {
      setLoading(true);
      setFormLoading(true);
      createUserWithEmailAndPassword(auth, email, password)
        .then(async () => {
          const currentUser = auth.currentUser;
          const newUserRef = doc(firestore, `usuarios/${currentUser.uid}`);
          setDoc(newUserRef, {
            name: ["", ""],
            badges: ["private"]
          }).then(() => {
            sendEmailVerification(auth.currentUser);
            navigate('/auth/complete-auth');
          })
        })
        .catch((error) => {
          setError(error.code);
          setLoading(false);
          setFormLoading(false);
          setDisabled(true);
        })
    }
  }

  useEffect(() => {
    const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@!#$%&?._-]{8,30}$/
    setDisabled(true);
    if (email.length > 0) {
      if (email.match(validRegex)) {
        setLocalError(false);
        if (password.length > 0) {
          if (password.length > 7) {
            if (password.length > 30) {
              setLocalError('auth/password-too-long')
            } else {
              if (password.match(passwordRegex)) {
                if (confirmPassword.length > 0) {
                  if (password !== confirmPassword) {
                    setLocalError('auth/password-dont-match');
                  } else {
                    setLocalError(false);
                    setDisabled(false);
                  }
                } else {
                  setLocalError(false);
                }
              } else {
                setLocalError('auth/password-insecure');
              }
            }
          } else {
            setLocalError('auth/password-too-less');
          }
        } else {
          setLocalError(false);
        }
      } else {
        setLocalError('auth/invalid-email');
      }
    } else {
      setLocalError(false);
    }
  }, [email, password, confirmPassword])

  return (
    <>
      <SEO title={t('SEO.register.title')} description={t('SEO.register.desc')} />
      <Form
        type="auth"
        action={register}
        title={t('auth.register')}
        loading={formLoading}
        error={error}
        bg="https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/ao_register_image.webp?alt=media&token=9392b210-711d-4c35-a6d3-a1f97ed64d98"
      >
        <div className="flex flow-column gap-big">
          <Input 
            type="email"
            value={email}
            variant={1}
            placeholder={true}
            placeholderText={t('auth.email.placeholder')}
            onChange={(e) => setEmail(e.target.value)}
            name="email"
            label={true}
            labelText={t('auth.email.label')}
            required={true}
          />
          <Input 
            type="password"
            value={password}
            variant={1}
            placeholder={true}
            placeholderText={t('auth.password.placeholder')}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            label={true}
            labelText={t('auth.password.label')}
            required={true}
          />
          <Input 
            type="password"
            value={confirmPassword}
            variant={1}
            placeholder={true}
            placeholderText={t('auth.confirm password.placeholder')}
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="confirm-password"
            label={true}
            labelText={t('auth.confirm password.label')}
            required={true}
          />
        </div>
        {localError && <span className='font-style color-negative' style={{margin: '-1rem 0'}}>{t(`error.${localError}`)}</span>}
        <AoButton 
          variant="special-2"
          title={t('auth.register')}
          loading={isLoading}
          submit={true}
          disabled={disabled}
        />
        <div className="flex flow-column gap-small align-center">
          <span>{t('auth.has account')}</span>
          <AoButton 
            to="/auth/login"
            type="link"
            title={t('auth.login')}
          />
        </div>
        <AuthMethods 
          setFormError={setError}
          setFormLoading={setFormLoading}
        />
      </Form>
    </>
  )
}
