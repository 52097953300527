import React, { useContext, useEffect, useState } from 'react';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';
import { Button, CircularProgress, FormControl, IconButton, Menu, MenuItem, Select, Tooltip, useMediaQuery } from '@mui/material';
import AoButton from '../../components/buttons/Button';
import PullToRefresh from 'react-simple-pull-to-refresh';
import { AddCircleOutline, ArrowDownward, ChevronLeft, ChevronRight, Circle, IosShare, PlayArrow, Refresh, Replay, Sort, Visibility, VisibilityOff } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';
import i18n from '../../services/i18n';
import { doc, getDoc } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { ModalsContext } from '../../context/Modals';
import AdsTerra from '../../components/ads/AdsTerra';
import Comments from '../../components/actions/Comments';
import SwiperLayout from '../../components/swiper/SwiperLayout';
import { SwiperSlide } from 'swiper/react';
import './index.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Language from '../settings/preferences/Language';
import SEO from '../../services/seo';
import { SettingsContext } from '../../context/Settings';
import Modal from '../../components/modals/Modal';

const SeasonsLazy = () => {
    return(
        <div className="episode-layout-header lazy">
            <div className="seasons"></div>
            <div className="sort-episode"></div>
        </div>
    )
}

const EpisodesLazy = () => {
    return (
        <div className="episode-layout-body lazy">
            <div className="episode-list">
                {Array.from({ length: 12}, (_, index) => (
                    <div className="card" key={index}>
                        <div className="link">
                            <div className="card-layout">
                                <div className="thumbnail-layout"></div>
                                <div className="data-layout">
                                    <div className="data-body">
                                        <div className="episode-title"></div>
                                        <div className="episode-footer">
                                            <div className="tags"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

const Episode = ({ view, serie, episode, season, seasons }) => {
    const { t } = useTranslation();
    const { openShare } = useContext(ModalsContext);

    const match = useMediaQuery('(min-width: 50em)');
    
    const [ imageLoaded, setImageLoaded ] = useState(false);
    const [ contextMenu, setContextMenu ] = useState(null);
    const [ contextMenuState, setContextMenuState ] = useState(null);
    const [ viewed, setViewed ] = useState(null)

    const params = useParams();
    const id = params.id.split('-')[0];

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :null
        );
        setTimeout(() => {
            setContextMenuState('show');
        }, 100);
    }

    const handleClose = () => {
        setContextMenuState(null);
        setTimeout(() => {
            setContextMenu(null);
        }, 100);
    }

    const handleViewed = () => {
        handleClose();
        setTimeout(() => {
            const storage = localStorage.getItem(`watch-list-${serie}`);
            if (viewed === 'true') {
                console.log('1')
                const arr = JSON.parse(storage);
                const storageSize = Object.keys(JSON.parse(storage)).length;
                if (storageSize > 1) {
                    let newArr = arr;
                    delete newArr[`${season}-${episode.id}`];
                    
                    localStorage.setItem(`watch-list-${serie}`, JSON.stringify(newArr));
                    setViewed(false);
                } else {
                    localStorage.setItem(`watch-list-${serie}`, JSON.stringify({}));
                    setViewed(false);
                }
            } else {
                console.log('2')
                if (storage) {
                    let arr = JSON.parse(storage);
                    arr[`${season}-${episode.id}`] = 'true';
                    localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr));
                    console.log('2-1')
                    setViewed('true');
                } else {
                    let arr = {};
                    arr[`${season}-${episode.id}`] = 'true';
                    localStorage.setItem(`watch-list-${serie}`, JSON.stringify(arr))
                    console.log('2-2')
                    setViewed('true');
                }
            }
        }, 100)
    }
    const Duration = () => {
        const hours = Math.floor(episode.duration / 3600);
        const minutes = Math.floor((episode.duration % 3600) / 60);
        const seconds = episode.duration % 60;

        if (hours > 0) {
            if (seconds > 0){
                return `${hours}h ${minutes}m ${seconds}s`;
            }
            return `${hours}h ${minutes}m`;
        } else {
            if (seconds > 0) {
                return `${minutes}m ${seconds}s`;
            }
            return `${minutes}m`;
        }
    }

    useEffect(() => {
        if (view) {
            setViewed(JSON.parse(view)[`${season}-${episode.id}`])
        }
    }, [])

    return (
        <div className="card" onContextMenu={handleContextMenu}>
            <Link to={`/watch/${id}-${season}-${episode.id}`} className='link'>
                <div className="card-layout">
                    <div className='thumbnail-layout'>
                        <div className="thumbnail-sized">
                            <div className={`thumbnail ${imageLoaded && 'loaded'}`}>
                                <LazyLoadImage 
                                    onLoad={() => setImageLoaded(true)}
                                    src={episode.cover}
                                    alt={`${t('words.shorts.season')}${season} ${t('words.shorts.episode')}${episode.id} - ${episode.name}`}
                                />
                            </div>
                        </div>
                        {(seasons?.rating === "Rx" || seasons?.rating === "R+" || seasons?.rating === "R-17+") &&
                            <span className={`rating ${seasons?.rating}`}>
                                {seasons?.rating}
                            </span>
                        }
                        <span className='duration'>
                            {viewed ?
                                <>
                                    {viewed === 'partial' && t('words.continue-watching')}
                                    {viewed === 'true' && t('words.watched')}
                                </>
                                :
                                <Duration />
                            }
                        </span>
                        {viewed &&
                            <div className={`watched ${viewed}`}>
                                {viewed === 'partial' && 
                                <>
                                    <PlayArrow />
                                    <div className="border"></div>
                                </>
                                }
                                {viewed === 'true' && <Replay />}
                            </div>
                        }
                    </div>
                    <div className="data-layout">
                        <div className="data-body">
                            <h4 className="episode-title">
                                {`${t('words.shorts.season')}${season} ${t('words.shorts.episode')}${episode.id} - ${episode.name}`}
                            </h4>
                            <div className="episode-footer">
                                <div className="episode-footer-meta">
                                    <div className="tags">
                                        {episode.tags.includes('dub') && <span className='tag'>{t('categories.langs.dub')}</span>}
                                        {episode.tags.includes('sub') && <span className='tag'>{t('categories.langs.sub')}</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
            {match ?
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference='anchorPosition'
                    transitionDuration={0}
                    className='lf-menu'
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <AoButton 
                        icon={viewed === 'true' ? <VisibilityOff /> : <Visibility />} 
                        title={t(`modal.episode.viewed.${viewed === 'true' ? 'true' : 'false'}`)}
                        action={handleViewed}
                    />
                    <AoButton 
                        icon={<IosShare />}
                        title={t('words.share episode')}
                        action={() => {openShare(`/watch/${id}-${season}-${episode.id}`);handleClose();}}
                    />
                </Menu>
                :
                <>
                    {contextMenu !== null &&
                        <Modal
                            variant={"user-menu"}
                            visible={contextMenuState}
                            close={handleClose}
                            title={`${t('words.shorts.season')}${season} ${t('words.shorts.episode')}${episode.id} - ${episode.name}`}
                        >
                            <div className="header-menu" style={{ padding: '.5rem'}}>
                                <AoButton 
                                    icon={viewed === 'true' ? <VisibilityOff /> : <Visibility />} 
                                    title={t(`modal.episode.viewed.${viewed === 'true' ? 'true' : 'false'}`)}
                                    size={"big"}
                                    action={handleViewed}
                                />
                                <AoButton 
                                    icon={<IosShare />}
                                    title={t('words.share episode')}
                                    size={"big"}
                                    action={() => {openShare(`/watch/${id}-${season}-${episode.id}`);handleClose();}}
                                />
                            </div>
                        </Modal>
                    }
                </>
            }
        </div>
    )
}

function Caps({ serie, setCurrentSeasonMAL, getMusicVideos, onContextMenu, setRating, setMAL, setSeeMore }){
    const { t } = useTranslation();
    const params = useParams();
    const [ season, setSeason ] = useState(1);
    const [ seasons, setSeasons ] = useState([]);
    const [ filtered, setFiltered ] = useState('ascending');

    const [ episodes, setEpisodes ] = useState([]);

    const [ loadingSeasons, setLoadingSeasons ] = useState(true);
    const [ loadingCaps, setLoadingCaps ] = useState(true);

    const handleSeason = (event) => {
        setLoadingCaps(true);
        setSeason(event.target.value);
        setCurrentSeasonMAL(seasons.find(x => x.id === event.target.value.toString()).MAL);
        getMusicVideos(seasons.find(x => x.id === event.target.value.toString()).MAL);
        setSeeMore(false);
        setMAL([]);
        setRating(seasons[season - 1].rating);
    }

    const handleFilter = (event) => {
        setFiltered(event.target.value);
        setLoadingCaps(true);
        setTimeout(() => {
            setLoadingCaps(false);
        }, 200)
    }

    const handleRefresh = (type) => {
        if (type) {
            setLoadingCaps(true);
            setTimeout(() => {
                getCaps(true);
            }, 1000)
            return;
        }

        return new Promise(res => {
            setLoadingCaps(true);
            setTimeout(() => {
                getCaps(res);
            }, 1000);
        })
    }

    function isValid(response) {
        const now = new Date();
        if (!response) return false;
        var fetched = response.headers.get('expire');
        if (fetched > now.getTime()) return true;
    }

    async function getSeasons(){
        const id = params.id.split('-')[0];
        const now = new Date();

        async function getData(db, cache) {
            const seasonsRef = doc(db.animeFirestore, `animes/${id}/seasons/info`);
            const seasonsSnap = await getDoc(seasonsRef);

            if (seasonsSnap.exists()) {
                const data = seasonsSnap.data().data;

                setSeasons(data);
                setCurrentSeasonMAL(data[season - 1].MAL);
                getMusicVideos(data[season - 1].MAL);
                setRating(data[season - 1].rating);
                setLoadingSeasons(false);
                
                if (cache) {
                    const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 3, now.getUTCDate()));
                    
                    var headers = new Headers();
                    headers.append('expire', expirationTime.getTime());

                    cache.put(`/${id}/seasons`, new Response (JSON.stringify(data), {
                        headers: headers
                    }))
                }
            } 
        }

        let lang = i18n.language;

        if (i18n.language === "es" || i18n.language === "en") {
            lang = i18n.language;
        } else {
            lang = "en";
        }

        if ('caches' in window) {
            caches.open(`animes_${lang}`)
            .then((cache) => {
                cache.match(`/${id}/seasons`)
                .then(async (response) => {
                    if (isValid(response)) {
                        var data = await response.json();
                        setSeasons(data);
                        setCurrentSeasonMAL(data[season - 1].MAL);
                        getMusicVideos(data[season - 1].MAL);
                        setRating(data[season - 1].rating);
                        setLoadingSeasons(false);
                    } else {
                        import(`../../services/firebase/anime_${lang}`)
                        .then((db) => {
                            getData(db, cache);
                        })
                    }
                })
            })
        } else {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                getData(db);
            })
        }
    }
    
    function getCaps(res){
        const id = params.id.split('-')[0];
        const now = new Date();

        async function getData(db, cache) {
            const episodesRef = doc(db.animeFirestore, `animes/${id}/seasons/${season}`);
            const episodesSnap = await getDoc(episodesRef);

            if (episodesSnap.exists()) {
                const data = episodesSnap.data().episodes_list;

                data.sort(function(a, b) {
                    return a.id - b.id
                })

                setEpisodes(data);
                setLoadingCaps(false);
                
                if (cache) {
                    const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 7));
                    
                    var headers = new Headers();
                    headers.append('expire', expirationTime.getTime());

                    cache.put(`/${id}/seasons/${season}`, new Response (JSON.stringify(data), {
                        headers: headers
                    }));
                }
            } else {
                setLoadingCaps(false);
                setEpisodes(null);
            } 

            if (res === true) return;

            if (res) {
                res();
            }
        }

        let lang = i18n.language;

        if (i18n.language === "es" || i18n.language === "en") {
            lang = i18n.language;
        } else {
            lang = "en";
        }

        if ('caches' in window) {
            caches.open(`animes_${lang}`)
            .then((cache) => {
                cache.match(`/${id}/seasons/${season}`)
                .then(async (response) => {
                    if (res) {
                        import(`../../services/firebase/anime_${lang}`)
                        .then((db) => {
                            getData(db, cache);
                        })
                    } else {
                        if (isValid(response)) {
                            var data = await response.json();
                            setEpisodes(data);
                            setLoadingCaps(false);
                        } else {
                            import(`../../services/firebase/anime_${lang}`)
                            .then((db) => {
                                getData(db, cache);
                            })
                        }
                    }
                })
            })
        } else {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                getData(db);
            })
        }
        
    }

    useEffect(()=>{
        if(params.id.split("-")[1]===undefined){
            setSeason(1);
        }else{
            setSeason(Number(params.id.split("-")[1]));
        }
    },[params.id])  

    useEffect(() => {
        if (loadingCaps) {
            setTimeout(() => {
                getCaps();
            }, 1000);
        }
    }, [loadingCaps])

    useEffect(() => {
        if (loadingSeasons) {
            getSeasons();
        }
    }, [loadingSeasons])

    const sortSeasons = {
        ascending: { method: (a, b) => (a.id - b.id)}
    }

    const sortCaps = {
        ascending: { method: (a, b) => (a.id - b.id)},
        descending: { method: (a, b) => (b.id - a.id)},
    }

    return (
        <PullToRefresh
            pullingContent={<div className='pull-to-refresh'><ArrowDownward /></div>}
            refreshingContent={<div className='pull-to-refresh'><CircularProgress /></div>}
            onRefresh={handleRefresh}
            pullDownThreshold={80}
            maxPullDownDistance={80}
            resistance={2}
        >
            <div className="bg" onContextMenu={onContextMenu}></div>
            <h2 className='hide'>{t('words.episodes')}</h2>
            <div className='episode-layout'>
                {loadingSeasons ? 
                    <SeasonsLazy />
                :
                    <div className="episode-layout-header">
                        <div className="bg" onContextMenu={onContextMenu}></div>
                        <div className="seasons">
                            <FormControl size='small' fullWidth>
                                <Select
                                    className='select'
                                    value={season}
                                    onChange={handleSeason}
                                >
                                    {seasons.sort(sortSeasons['ascending'].method).map((doc, i) => (
                                        <MenuItem className='button' key={i} value={doc.id}>
                                            {`[${t('words.shorts.season')}${doc.id}] ${doc.name}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Tooltip title={t('words.refresh')}>
                                <IconButton onClick={() => handleRefresh(true)} className='IconButton'>
                                    <Refresh />
                                </IconButton>
                            </Tooltip>
                        </div>
                        <div className="sort-episode">
                            <FormControl size='small' fullWidth>
                                <Sort className='sort-icon '/>
                                <Select
                                    value={filtered}
                                    onChange={handleFilter}
                                    className='select'
                                >
                                    <MenuItem className='button' value={'ascending'}>De Menor a Mayor</MenuItem>
                                    <MenuItem className='button' value={'descending'}>De Mayor a Menor</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                }
                    {loadingCaps ?
                        <EpisodesLazy />
                        :
                        <div className="episode-layout-body">
                            {episodes ?
                            <>
                                {episodes.length === 0 ?
                                <div className="episode-msg">
                                    <h2>{t('error.caps/no-caps')}</h2>
                                </div>
                                :
                                <div className="episode-list">
                                    {episodes.sort(sortCaps[filtered].method).map((episode, i) => (
                                        <Episode 
                                            key={i} 
                                            episode={episode} 
                                            serie={serie}
                                            view={localStorage.getItem(`watch-list-${serie}`)}
                                            season={season}
                                            seasons={seasons[season - 1]}
                                        />
                                    ))}
                                </div>
                                }
                            </>
                            :
                            <div className='episode-msg'>
                                <h2>{t('error.caps/not-available-caps')}</h2>
                            </div>
                            }
                        </div>
                    }
                {loadingSeasons ? 
                    <div className='episode-layout-footer'>
                        <div className="button"></div>
                        <div className="button"></div>
                    </div>
                :
                    <>
                        {seasons.length > 1 &&
                        <div className="episode-layout-footer">
                            <Button 
                                className={`button font-style color-base size-big ${seasons[season - 2] ? 'xd' : 'disabled'}`}
                                onClick={() => {
                                    if (seasons[season - 2]){
                                        setSeason(seasons[season - 2].id);
                                        setCurrentSeasonMAL(seasons[season - 2].MAL);
                                        setSeeMore(false);
                                        setMAL([]);
                                        setLoadingCaps(true);
                                    }
                                }}
                            >
                                <div className="button-icon"><ChevronLeft /></div>
                                <div className="button-title">Temporada anterior</div>
                            </Button>
                            <Button 
                                className={`button font-style color-base size-big ${seasons[season] ? '' : 'disabled'}`}
                                onClick={() => {
                                    if (seasons[season]){
                                        setSeason(seasons[season].id);
                                        setCurrentSeasonMAL(seasons[season].MAL);
                                        setSeeMore(false);
                                        setMAL([]);
                                        setLoadingCaps(true);
                                    }
                                }}
                            >
                                <div className="button-title">Siguiente temporada</div>
                                <div className="button-icon"><ChevronRight /></div>
                            </Button>
                        </div>
                        }
                        
                    </>
                }
            </div>
        </PullToRefresh>
    )
}

function UserActions({ data }) {
    const params = useParams();
    const { t } = useTranslation();
    const { theme } = useContext(SettingsContext);
    const [ active, setActive ] = useState(0);
    const [ loadingComments, setLoadingComments ] = useState(false);

    useEffect(() => {
        setLoadingComments(true);
        setTimeout(() => {
            setLoadingComments(false);
        }, 100)
    }, [theme]);

    return (
        <div className="user-actions">
            <div className="flex flow-row navigation-buttons">
                <Button
                    className={`button tabs ${active === 0 && 'active'}`}
                    onClick={() => setActive(0)}
                >
                    <span className='text'>{t('words.comments')}</span>
                </Button>
                <Button
                    className={`button tabs ${active === 1 && 'active'}`}
                    disabled={true}
                    onClick={() => setActive(1)}
                >
                    <span className='text'>Rating</span>
                </Button>
            </div>
            <div>
                {active === 0  && 
                    <LazyLoadComponent>
                        {!loadingComments &&
                            <Comments 
                                id={params.id.split('-')[0]}
                                name={data.name}
                            />
                        }
                    </LazyLoadComponent>
                }
            </div>
        </div>
    )
}

export default function AnimeId() {
    const params = useParams();
    const { t } = useTranslation();
    const { openEmbed, openShare } = useContext(ModalsContext);
    const [ seeMore, setSeeMore ] = useState(false);

    const [ tallCover, setTallCover ] = useState('');
    const [ wideCover, setWideCover ] = useState('');

    const [ data, setData ] = useState([]);
    const [ rating, setRating ] = useState('');
    const [ currentSeasonMAL, setCurrentSeasonMAL ] = useState('');
    const [ MAL, setMAL ] = useState([]);
    const [ musicVideos, setMusicVideos ] = useState([]);

    const [ loading, setLoading ] = useState(true);
    const [ noExist, setNoExist ] = useState(false);

    const [ contextMenu, setContextMenu ] = useState(null);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                :null
        );
    }

    const handleClose = () => {
        setContextMenu(null);
    }

    async function getMALData(id){
        fetch(`https://api.jikan.moe/v4/anime/${id}`)
        .then((response) => response.json())
        .then((doc) => {setMAL(doc.data);setSeeMore(true)})
    }

    async function getMusicVideos(id){
        setCurrentSeasonMAL(id);
        fetch(`https://api.jikan.moe/v4/anime/${id}/videos`)
        .then((response) => response.json())
        .then((doc) => {
            if (doc.data) {
                if(doc.data.music_videos) setMusicVideos(doc.data.music_videos)
            }
        })
    }

    function setImages(images) {
        setTallCover(images.tall);
        setWideCover(images.wide);
    }

    async function getAnime(db, cache) {
        const id = params.id.split('-')[0];
        const now = new Date();
        const animeRef = doc(db.animeFirestore, `animes/${id}`);
        const animeSnap = await getDoc(animeRef);

        if (animeSnap.exists()) {
            const data = animeSnap.data().info[0];
            
            setData(data);
            setImages(data.images);
            setLoading(false);
            setNoExist(false);
            
            if (cache) {
                const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 6, now.getUTCDate()));

                var headers = new Headers();
                headers.append('expire', expirationTime.getTime());

                cache.put(`/${id}`, new Response (JSON.stringify(data), {
                    headers: headers
                }));
            }
        } else {
            setNoExist(true);
        }
    }

    function setDB() {
        const id = params.id.split('-')[0];
        const now = new Date();
        function isValid(response) {
            if (!response) return false;
            var fetched = response.headers.get('expire');
            if (fetched > now.getTime()) return true;
        }

        let lang = i18n.language;
            
        if (i18n.language === "es" || i18n.language === "en") {
            lang = i18n.language;
        } else {
            lang = "en";
        }

        if ('caches' in window) {
            caches.open(`animes_${lang}`)
            .then((cache) => {
                cache.match(`/${id}`)
                .then(async (response) => {
                    if (isValid(response)) {
                        var data = await response.json();
                        setData(data);
                        setImages(data.images);
                        setLoading(false);
                        setNoExist(false);
                    } else {
                        import(`../../services/firebase/anime_${lang}`)
                        .then((db) => {
                            getAnime(db, cache);
                        })
                    }
                })
            })
        } else {
            import(`../../services/firebase/anime_${lang}`)
            .then((db) => {
                getAnime(db);
            })
        }
    }

    useEffect(() => {
        if (loading) {
            setDB();
        }
    }, [loading]);

    if (noExist) {
        return(
            <div className='anime-landing'>
                <div className="anime-landing-header">
                    <div className="anime-landing-header-layout not-found">
                        <div className="background">
                            <div className="image">
                                <LazyLoadImage 
                                    src="https://firebasestorage.googleapis.com/v0/b/aodesu-en.appspot.com/o/animes%2F404-background.webp?alt=media&token=9baed4ea-36a1-4aeb-b385-8bea8ba0ea24"
                                    alt={`${data.name} - aodesu`}
                                />
                            </div>
                        </div>
                        <div className="body">
                            <div className="cover square">
                                <LazyLoadImage 
                                    src='https://firebasestorage.googleapis.com/v0/b/aodesu-en.appspot.com/o/animes%2F404-cover.webp?alt=media&token=fb4f080d-f929-47ae-aa81-6dee4e7eb44f'
                                />
                            </div>
                            <div className="title">
                                <h1>No pudimos encontrar nada.</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="anime-landing-body">
                    <div className="anime-landing-body-layout not-found">
                            <div className="info">
                                <h1>No pudimos encontrar nada.</h1>
                                <h3>¿Qué te parece si intentamos con otro idioma?</h3>
                                <Language 
                                    zone={true}
                                    refresh={setDB}    
                                />
                            </div>
                    </div>
                </div>
            </div>
        )
    }

    if (loading) {
        return (
            <div className='anime-landing'>
                <div className="anime-landing-header">
                    <div className="anime-landing-header-layout">
                        <div className="background"></div>
                        <div className="body">
                            <div className="cover tall"></div>
                            <div className="cover wide"></div>
                            <div className="title">
                                <div className="h1"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="anime-landing-body">
                    <div className="anime-landing-body-layout">
                        <div className="info">
                            <div className="more-info"></div>
                        </div>
                        <div className="caps">
                            <div className="episode-layout">
                                <SeasonsLazy />
                                <EpisodesLazy />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <SEO title={data.name} description={data.synopsis} />
            <div className='anime-landing' >
                <div className="bg" onContextMenu={handleContextMenu}></div>
                <div className="anime-landing-header" onContextMenu={handleContextMenu}>
                    <div className="anime-landing-header-layout">
                        <div className="background">
                            <div className="image">
                                <img 
                                    src={wideCover}
                                    alt={`${data.name} - aodesu`}
                                />
                            </div>
                        </div>
                        <div className="body">
                            <button className="cover tall">
                                <LazyLoadImage 
                                    src={tallCover}
                                    alt={`${data.name} - aodesu`}
                                    effect='blur'
                                />
                            </button>
                            <button className="cover wide">
                                <LazyLoadImage 
                                    src={wideCover}
                                    alt={`${data.name} - aodesu`}
                                    effect='blur'
                                />
                            </button>
                            <div className="title">
                                <h1>{data.name}</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="anime-landing-body">
                    <div className="anime-landing-body-layout">
                        <div className="info" onContextMenu={handleContextMenu}>
                            <h1>{data.name}</h1>
                            {seeMore ? 
                                <div className="more-info active">
                                    <div className="tags tags-and-rating">
                                        {rating !== null &&
                                            <>
                                                <span className={`rating ${rating}`}>{t(`words.ratings.${rating}`)}</span>
                                                <Circle sx={{fontSize: '.3rem'}} />
                                            </>
                                        }
                                        {data.tags.map((tag, i) => (
                                            <span key={i}>{t(`categories.langs.${tag}-long`)}</span>
                                        ))}
                                    </div>
                                    <p>{data.synopsis}</p>
                                    {data.categories.length > 0 &&
                                        <div className="categories">
                                            {data.categories.map((doc, i) => (
                                                <AoButton 
                                                    key={i}
                                                    type={"link"}
                                                    to={`/genres/${doc}`}
                                                    title={t(`categories.genres.${doc}`)}
                                                    variant={"outline rounded"}
                                                />
                                            ))}
                                        </div>
                                    }
                                    <div className="divider"></div>
                                    <div className="info">
                                        <div className='section'>
                                            <h4>{t('words.rating')}</h4>
                                            <p>{MAL?.rating}</p>
                                        </div>
                                        <div className='section'>
                                            <h4>{t('words.studios')}</h4>
                                            <div>
                                                {MAL?.studios.map((studio, i) => (
                                                    <p key={i}>{studio.name}</p>
                                                ))}
                                            </div>
                                        </div>
                                        <div className='section'>
                                            <h4>{t('words.type')}</h4>
                                            <p>{MAL?.type}</p>
                                        </div>
                                        <div className="section">
                                            <h4>{t('words.year')}</h4>
                                            <p>{MAL?.year}</p>
                                        </div>
                                        <div className='section'>
                                            <h4>{t('words.aired')}</h4>
                                            <p>
                                                {MAL?.aired.prop.from &&
                                                    <span>
                                                        {`${t('words.from')} `}
                                                        <span className='font-style color-base'>{new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long'}).format(new Date(`${MAL?.aired.prop.from.month}-${MAL?.aired.prop.from.day}-${MAL?.aired.prop.from.year}`).getTime())}</span>
                                                    </span>
                                                }   
                                                {MAL?.aired.prop.to.day !== null &&
                                                    <span>
                                                        {` ${t('words.to')} `}
                                                        <span className="font-style color-base">{new Intl.DateTimeFormat(i18n.language, { dateStyle: 'long'}).format(new Date(`${MAL?.aired.prop.to.month}-${MAL?.aired.prop.to.day}-${MAL?.aired.prop.to.year}`).getTime())}</span>
                                                    </span>
                                                }
                                                
                                            </p>
                                        </div>
                                    </div>
                                    <div className="divider"></div>
                                    <Button onClick={() => setSeeMore(false)} className='button show-less highlight'>{t('words.fewer details')}</Button>
                                </div>
                            :
                                <Button 
                                    className='button highlight more-info' 
                                    onClick={() => {
                                        if (JSON.stringify(MAL) === "[]") {
                                            getMALData(currentSeasonMAL)
                                        } else {
                                            setTimeout(() => {
                                                setSeeMore(true);
                                            }, 0)
                                        }
                                    }}
                                >
                                    <div className="tags tags-and-rating">
                                        {rating !== null &&
                                            <>
                                                <span className={`rating ${rating}`}>{rating}</span>
                                                <Circle sx={{fontSize: '.3625rem'}} />
                                            </>
                                        }
                                        {data.tags.map((tag, i) => (
                                            <span key={i}>{t(`categories.langs.${tag}`)}</span>
                                        ))}
                                    </div>
                                    <p>{data.synopsis}</p>
                                    <div className="divider"></div>
                                    <div className="button show-more">{t('words.more details')}</div>
                                </Button>
                            }
                        </div>
                        <Caps 
                            serie={params.id.split('-')[0]}
                            onContextMenu={handleContextMenu} 
                            setCurrentSeasonMAL={setCurrentSeasonMAL} 
                            setRating={setRating}
                            setMAL={setMAL}
                            getMusicVideos={getMusicVideos}
                            setSeeMore={setSeeMore}
                        />
                        <div className="divider"></div>
                        <AdsTerra />
                        {musicVideos && 
                        <>
                            {musicVideos.length > 0 &&
                                <div className="music-videos">
                                    <h2>{t('words.music videos')}</h2>
                                    <div className="videos-layout">
                                        <SwiperLayout
                                            name={"music-videos"}
                                        >
                                            {musicVideos.map((doc, i) => (
                                                <SwiperSlide
                                                    key={i}
                                                >
                                                    <Button 
                                                    className='button video-card' 
                                                    onClick={() => openEmbed(doc.video.embed_url)}
                                                    >
                                                        <div className="image-layout">
                                                            <LazyLoadImage 
                                                                src={doc.video.images.medium_image_url}
                                                                alt={`${doc.meta.title} - ${doc.meta.author}`}
                                                                effect='blur'
                                                            />
                                                        </div>
                                                        <div className='data-body'>
                                                            <h3>{doc.title}</h3>
                                                            {doc.meta.title && doc.meta.author &&
                                                                <div className='video-footer'>
                                                                    <h4>{doc.meta.title} - {doc.meta.author}</h4>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Button>
                                                </SwiperSlide>
                                            ))}
                                        </SwiperLayout>
                                    </div>
                                </div>
                            }
                        </>
                        }
                        <UserActions data={data} />
                    </div>
                </div>
                <Menu
                    open={contextMenu !== null}
                    onClose={handleClose}
                    anchorReference='anchorPosition'
                    className='lf-menu'
                    transitionDuration={0}
                    anchorPosition={
                        contextMenu !== null
                            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                            : undefined
                    }
                >
                    <AoButton 
                        icon={<AddCircleOutline />}
                        title={t('words.add library')}
                    />
                    <AoButton 
                        icon={<IosShare />}
                        title={t('words.share anime')}
                        action={() => {openShare(`/anime/${params.id}/share`);handleClose();}}
                    />
                </Menu>
            </div>
        </>
  )
}
