import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';
import getDBLang from '../hooks/getDBLang';
import { doc, getDoc } from 'firebase/firestore';
import { MoreHoriz, Replay } from '@mui/icons-material';

const EpisodeButton = ({ type, data, serie, season, title }) => {
    const { t } = useTranslation();
    const [ episodeData, setEpisodeData ] = useState(data || null);
    const [ loadingEpisode, setLoadingEpisode ] = useState(true);
    const [ viewed, setViewed ] = useState(false);

    const Duration = () => {
        const hours = Math.floor(episodeData.duration / 3600);
        const minutes = Math.floor((episodeData.duration % 3600) / 60);
        const seconds = episodeData.duration % 60;

        if (hours > 0) {
            return `${hours}h ${minutes}m ${seconds}s`;
        } else {
            return `${minutes}m ${seconds}s`;
        }
    }

    useEffect(() => {
        if (type === "season") {
            const storage = localStorage.getItem(`watch-list-${serie}`);

            if (storage) {
                if (storage) {
                    setViewed(JSON.parse(storage)[`${season}-${episodeData.id}`])
                }
            }
        } else {
            const storage = localStorage.getItem(`watch-list-${serie}`);

            if (storage) {
                setViewed(JSON.parse(storage)[`${season}-${data.id}`])
            }
        }
    })

    useEffect(() => {
        if (type === "season") {
            if (episodeData === null) {
                const now = new Date();
                const lang = getDBLang();

                function isValid(response) {
                    if (!response) return false;
                    var fetched = response.headers.get('expire');
                    if (fetched > now.getTime()) return true;
                }

                async function getCaps(db, cache) {
                    const animeRef = doc(db.animeFirestore, `animes/${serie}/seasons/${season}`);
                    const animeSnap = await getDoc(animeRef);
        
                    if (animeSnap.exists()) {
                        const data = animeSnap.data().episodes_list;
                        const sorted = data.sort((a,b) => a.id > b.id ? 1 : -1);
                        
                        if (title === "next-season") {
                            setEpisodeData(sorted[0]);
                        } else {
                            setEpisodeData(sorted[sorted.length - 1]);
                        }

                        setLoadingEpisode(false);
                        
                        if (cache) {
                            const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + 7));
        
                            var headers = new Headers();
                            headers.append('expire', expirationTime.getTime());
            
                            cache.put(`/${serie}/seasons/${season}`, new Response (JSON.stringify(data), {
                                headers: headers
                            }));
                        }
                    } else {
                        setEpisodeData(undefined);
                    }
                }

                if ('caches' in window) {
                    caches.open(`animes_${lang}`)
                    .then((cache) => {
                        cache.match(`/${serie}/seasons/${season}`)
                        .then(async (response) => {
                            if (isValid(response)) {
                                var data = await response.json();
                                const sorted = data.sort((a,b) => a.id > b.id ? 1 : -1);
                                
                                if (title === "next-season") {
                                    setEpisodeData(sorted[0]);
                                } else {
                                    setEpisodeData(sorted[sorted.length - 1]);
                                }
                                setLoadingEpisode(false);
                            } else {
                                import(`../../services/firebase/anime_${lang}`)
                                .then((db) => {
                                    getCaps(db, cache);
                                })
                            }
                        })
                    })
                } else {
                    import(`../../services/firebase/anime_${lang}`)
                    .then((db) => {
                        getCaps(db);
                    })
                }
            }
        }
    }, [])

    if (type === "season") { 
        if (loadingEpisode) {
            return;
        } else {  
            if (episodeData) {
                return (
                    <div className="change-episode-body">
                        <Link className='change-episode-body-title'>{t(`words.episode.${title}`)}</Link>
                        <div className="card">
                            <Link to={`/watch/${serie}-${season}-${episodeData.id}`} className='link'>
                                <div className="card-layout">
                                    <div className="thumbnail-layout">
                                        <div className="thumbnail-sized">
                                            <div className="thumbnail">
                                                <LazyLoadImage 
                                                    src={episodeData.cover}
                                                />
                                            </div>
                                        </div>
                                        <span className='duration'>
                                            {viewed ?
                                                    <>
                                                        {viewed === 'true' && t('words.watched')}
                                                    </>
                                                    :
                                                    <Duration />
                                                }
                                            </span>
                                            {viewed &&
                                                <div className={`watched ${viewed}`}>
                                                    {viewed === 'partial' && 
                                                    <>
                                                        <MoreHoriz />
                                                        <div className="border"></div>
                                                    </>
                                                    }
                                                    {viewed === 'true' && <Replay />}
                                                </div>
                                            }
                                    </div>
                                    <div className="data-layout">
                                        <data className="data-body">
                                            <h4 className='episode-title'>{`${t('words.shorts.episode')}${episodeData.id} - ${episodeData.name}`}</h4>
                                            <div className="episode-footer">
                                                <div className="episode-footer-meta">
                                                    <div className="tags">
                                                        {episodeData.tags.map((tag, i) => (
                                                            <span className='tag' key={i}>{t(`categories.langs.${tag}`)}</span>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </data>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                )
            } else {
                return;
            }
        }
    }

    return (
        <div className="change-episode-body">
            <Link className='change-episode-body-title'>{t(`words.episode.${title}`)}</Link>
            <div className="card">
                <Link to={`/watch/${serie}-${season}-${data.id}`} className='link'>
                    <div className="card-layout">
                        <div className="thumbnail-layout">
                            <div className="thumbnail-sized">
                                <div className="thumbnail">
                                    <LazyLoadImage 
                                        src={data.cover}
                                    />
                                </div>
                            </div>
                            <span className='duration'>
                                {viewed ?
                                    <>
                                        {viewed === 'true' && t('words.watched')}
                                    </>
                                    :
                                    <Duration />
                                }
                            </span>
                            {viewed &&
                                <div className={`watched ${viewed}`}>
                                    {viewed === 'partial' && 
                                    <>
                                        <MoreHoriz />
                                        <div className="border"></div>
                                    </>
                                    }
                                    {viewed === 'true' && <Replay />}
                                </div>
                            }
                        </div>
                        <div className="data-layout">
                            <data className="data-body">
                                <h4 className='episode-title'>{`${t('words.shorts.episode')}${data.id} - ${data.name}`}</h4>
                                <div className="episode-footer">
                                    <div className="episode-footer-meta">
                                        <div className="tags">
                                            {data.tags.map((tag, i) => (
                                                <span className='tag' key={i}>{t(`categories.langs.${tag}`)}</span>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </data>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    )
}

export default function ChangeEpisode({ serie, episodes, currentIndex, seasons, currentSeason }) {
    const [ next, setNext ] = useState(null);
    const [ previous, setPrevious ] = useState(null);

    useEffect(() => {
        if (episodes) {
            setNext(episodes[currentIndex + 1]);
            setPrevious(episodes[currentIndex - 1]);
        }
    })

    if (seasons) {
        if ( next !== null || previous !== null ) {
            if (next === undefined && previous === undefined ) {
                if (seasons[currentSeason - 1] !== undefined || seasons[currentSeason + 1] !== undefined) {
                    return (
                        <div className="data-sidebar">
                            <div className="change-episode-layout">
                                {seasons[currentSeason + 1] &&
                                    <EpisodeButton title="next-season" serie={serie} type={"season"} season={seasons[currentSeason + 1].id} />
                                }
                                {seasons[currentSeason - 1] &&
                                    <EpisodeButton title="prev-season" serie={serie} type={"season"} season={seasons[currentSeason - 1].id} />
                                }
                            </div>
                        </div>
                    )
                }
            } else {
                return (
                    <div className="data-sidebar">
                        <div className='change-episode-layout'>
                            {next ? 
                                <EpisodeButton title="next" data={next} serie={serie} season={seasons[currentSeason].id} />
                                :
                                <>
                                    {seasons[currentSeason + 1] &&
                                        <EpisodeButton title="next-season" serie={serie} type={"season"} season={seasons[currentSeason + 1].id} />
                                    }
                                </>
                            }
                            {previous ?
                                <EpisodeButton title="prev" data={previous} serie={serie} season={seasons[currentSeason].id} />
                                :
                                <>
                                    {seasons[currentSeason - 1] &&
                                        <EpisodeButton title="prev-season" serie={serie} type={"season"} season={seasons[currentSeason - 1].id} />
                                    }
                                </>
                            }
                        </div>
                    </div>
                )
            }
        }
    }
}
