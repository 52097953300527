import React, { useState } from 'react'
import i18n from '../../../services/i18n'
import AoButton from '../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';

export const langs = [
    {
        code: 'es',
        flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fes.webp?alt=media&token=f46b1ffa-63e7-4fa3-8ab8-5fe96b776e15",
        name: "Español"
    },
    {
        code: 'en',
        flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fen.webp?alt=media&token=611e31ad-76bb-4819-bac8-28d3c0430010",
        name: "English"
    },
]


/* {
    code: 'ja',
    flag: "https://firebasestorage.googleapis.com/v0/b/aodesu.appspot.com/o/langs%2Fjp.webp?alt=media&token=69b2fe88-8ed7-4e43-bb1d-f8833e0421db",
    name: "日本語"
} */

export default function Language({ zone, refresh }) {
    const { t } = useTranslation();
    const [ selectedLanguage, setSelectedLanguage ] = useState(i18n.language);

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
        setSelectedLanguage(value);
    }
  return (
    <>
        {!zone && <SEO title={`${t('settings.nav.preferences')} - ${t('settings.nav.language')}`} />}
        <div className='language settings-section'>
            {!zone && <span>{t('lang.select-lang')}</span>}
            {langs.map((lang, i) => (
                <AoButton 
                    key={i}
                    variant={"lang"}
                    type={"select"}
                    className={selectedLanguage === lang.code && 'active'}
                    action={() => {
                        changeLanguage(lang.code);
                        if (zone) {
                            refresh();
                        }
                    }}
                    title={lang.name}
                    langCode={lang.code}
                    flag={lang.flag}
                />
            ))}
        </div>
    </>
  )
}
