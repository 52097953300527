import { createContext, useState } from "react";

export const BuyPremiumContext = createContext();

export const BuyPremiumProvider = ({ children }) => {
    const [ modal, setModal ] = useState(false);
    const [ modalState, setModalState ] = useState(null);
    const [ plan, setPlan ] = useState(null);
    const [ loading, setLoading ] = useState(true);

    const selectPlan = (plan) => {
        setLoading(true);

        setTimeout(() => {
            if (plan) {
                setPlan(plan);
                setLoading(false);
                return;
            }
    
            return setPlan(false);
        }, 500)
    }

    const openModal = (plan) => {
        setModal(true);
        setLoading(true);
        setTimeout(() => {
            setModalState('show');
            selectPlan(plan);
        }, 100)
    }

    const closeModal = () => {
        setModalState(null);
        setTimeout(() => {
            setModal(false);
            setPlan(null);
            setLoading(true);
        }, 100);
    };

    return (
        <BuyPremiumContext.Provider
            value={{
                modal,
                modalState,
                plan,
                loading,
                setLoading,
                selectPlan,
                openModal,
                closeModal
            }}
        >
            {children}
        </BuyPremiumContext.Provider>
    )
}