import { DiscussionEmbed } from 'disqus-react';
import React from 'react';
import { useLocation } from 'react-router-dom';
import i18n from '../../services/i18n';

export default function Comments({ id, name }) {
    const location = useLocation();

    const getLang = () => {
        if (i18n.language === 'es') return 'es-MX';

        return 'en';
    }

    return (
        <div className="comments">
            <div className="comment-action">
                <DiscussionEmbed 
                    shortname='aodesu'
                    config={
                        {
                            url: `https://aodesu.com${location.pathname}`,
                            identifier: id,
                            title: `aodesu | ${name}`,
                            language: getLang()
                        }
                    }
                />
            </div>
        </div>
    )
}
