import React, { useContext, useEffect, useState } from 'react'
import Form from '../../../components/form/Form'
import Input from '../../../components/form/Input'
import { AuthContext } from '../../../context/Auth'
import AoButton from '../../../components/buttons/Button';
import { useTranslation } from 'react-i18next';
import SEO from '../../../services/seo';
import { DiscussionEmbed } from 'disqus-react';
import i18n from '../../../services/i18n';
import { SettingsContext } from '../../../context/Settings';

export default function Request() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const { theme } = useContext(SettingsContext);

    const [ title, setTitle ] = useState('');

    const [ sending, setSending ] = useState(false);
    const [ send, setSend ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);

    const [ requestType, setRequestType ] = useState('private');
    const [ loadingPublic, setLoadingPublic ] = useState(false);

    const handleSend = () => {
        setSending(true);
        const content = {
            "embeds": [{
              "title": `¡Anime recomendado!`,
              "description": `## ${title}`,
              "color": 0x1ed760,
              "fields": [
                  {
                      "name": "Usuario",
                      "value": user.name,
                      "inline": true
                  },
                  {
                      "name": "Correo",
                      "value": user.email,
                      "inline": true
                  },
                  {
                      "name": "uid",
                      "value": user.uid,
                      "inline": false
                  }
              ],
              "thumbnail": {
                "url": `${user.photoURL}`,
                "height": 0,
                "width": 0
              }
            }]
          }

        fetch(process.env.REACT_APP_DISCORD_REQUEST_WEBHOOK, {
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSending(false);
            setSend(true);
            setTitle('');
        }).catch((err) => {
            setSending(false);
        })
    }

    useEffect(() => {
        if (title.length >= 3) {
            setDisabled(false);
            return;
        }

        setDisabled(true);
    }, [title])

    useEffect(() => {
        setLoadingPublic(true);
        setTimeout(() => {
            setLoadingPublic(false);
        }, 100)
    }, [theme])

    if (send){
        return(
            <div className='settings-message'>
                <h2>{t('words.thanks')}</h2>
                <div className="buttons">
                    <AoButton 
                        title={"Enviar otra"}
                        variant={"outline"}
                        action={() => {
                            setSend(false);
                        }}
                    />
                </div>
            </div>
        )
    }
    
    return (
        <>
            <SEO title={`${t('settings.nav.community')} - ${t('settings.nav.request')}`} />
            <div className='flex flow-row sticky-buttons'>
                <AoButton 
                    title={t('settings.nav.request-private')}
                    variant={"tabs-2"}
                    className={requestType === 'private' && 'active'}
                    action={() => setRequestType('private')}
                />
                <AoButton 
                    title={t('settings.nav.request-public')}
                    variant={"tabs-2"}
                    className={requestType === 'public' && 'active'}
                    action={() => setRequestType('public')}
                />
            </div>
            {requestType === "private" &&
                <>
                     <div 
                        className='font-style color-subdued' 
                        style={{
                            margin: '.5rem',
                        }}
                    >{t('settings.nav.request-private-desc')}</div>
                    <Form
                        loading={sending}
                        type={"send"}
                        action={handleSend}
                        disabled={disabled}
                    >
                        <div className="flex flow-column gap-big" style={{marginBottom: '1rem'}}>
                            <Input 
                                type="text"
                                value={title}
                                variant={1}
                                label={true}
                                onChange={(e) => setTitle(e.target.value)}
                                labelText={`*${t('words.anime-title')}`}
                            />
                        </div>
                        <AoButton 
                            title={t('words.send')}
                            variant={"special-2"}
                            loading={sending}
                            submit={true}
                            disabled={disabled}
                        />
                    </Form>
                </>
            }
            {requestType === "public" &&
                <>
                    {!loadingPublic &&
                        <DiscussionEmbed 
                            shortname='aodesu-community'
                            config={
                                {
                                    url: `https://aodesu-community.com/${window.location.pathname}`,
                                    identifier: t('settings.nav.request'),
                                    title: `${t('settings.nav.request-public')} - aodesu`,
                                    language: i18n.language
                                }
                            }
                        />
                    }
                </>
            }
        </>
    )
}
