import { Skeleton } from '@mui/material'
import React from 'react'

export default function LazySettings() {
  return (
    <div className='lazy-settings'
    >
      <Skeleton variant='rectangular' animation={false} height={"3.5rem"} />
      <Skeleton variant='rectangular' animation={false} height={"3.5rem"} />
      <Skeleton variant='rectangular' animation={false} height={"3.5rem"} />
      <Skeleton variant='rectangular' animation={false} height={"3.5rem"} />
    </div>
  )
}
