import React, { useContext } from 'react'
import { AuthContext } from '../../context/Auth'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Instagram, Twitter, YouTube } from '@mui/icons-material';
import Logo from '../../images/Logo';

export default function Footer() {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);

    return (
        <div className='footer'>
            <div className="footer-layout">
                <div className='footer-section'>
                    <h4>{t('footer.connect-us')}</h4>
                    <ul>
                        {/* <li>
                            <Link className='link'><YouTube /> Youtube</Link>
                        </li> */}
                        <li>
                            <Link to={"https://twitter.com/aodesu_es"} target='_blank' className='link'><Twitter /> Twitter/X</Link>
                        </li>
                        <li>
                            <Link to="https://www.instagram.com/aodesu_es/" target='_blank' className='link'>
                                <Instagram />
                                Instagram
                            </Link>
                        </li>
                        <li>
                            <Link to={"https://discord.gg/JaqX9nuXxX"} target='_blank' className='link'>
                                <svg xmlns="http://www.w3.org/2000/svg" className='discord' fill='currentColor' viewBox="0 0 127.14 96.36">
                                    <g>
                                        <g>
                                            <g>
                                                <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                                Discord
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className='footer-section'>
                    <h4>aodesu</h4>
                    <ul>
                        {/* <li><Link className='link'>Acerca</Link></li> */}
                        {/* <li><Link>Términos de uso</Link></li> */}
                        <li><Link className='link' to="/settings/privacy/policy">{t('settings.nav.policy')}</Link></li>
                    </ul>
                </div>
                {!user &&
                    <div className='footer-section'>
                        <h4>{t('footer.account')}</h4>
                        <ul>
                            <li><Link className='link' to="/auth/register">{t('header.user-menu.register.title')}</Link></li>
                            <li><Link className='link' to="/auth/login">{t('header.user-menu.login.title')}</Link></li>
                        </ul>
                    </div>
                }
            </div>
            <div className="brands">
                <Logo />
            </div>
            <div className="disclaimer">
                <span>&copy; aodesu 2024</span>
                <span>{t('disclaimers.videos/no-host')}</span>
            </div>
        </div>
    )
}
