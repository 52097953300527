import { CircularProgress, useMediaQuery } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import getDBLang from '../../components/hooks/getDBLang';
import { ContentListsContext } from '../../context/ContentLists';
import { doc, getDoc } from 'firebase/firestore';
import { Link, useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';
import AoButton from '../../components/buttons/Button';
import Input from '../../components/form/Input';
import Form from '../../components/form/Form';
import { AuthContext } from '../../context/Auth';

const Result = ({ index, data }) => {
    const { t } = useTranslation();
    return (
        <Link to={`/anime/${data.id}`} className={`result-card result-prior-${index}`}>
            <div className="result-card__thumbnail">
                <LazyLoadImage
                    src={index === 1 || index === 2 ? data.images.tall : data.images.wide}
                    alt={data.name}
                    effect='blur'
                />
            </div>
            <div className="result-card__body">
                <h4 className="result-card__body-title">{data.name}</h4>
                <div className="tags-and-rating">
                    {data.tags.map((tag, i) => (
                        <span key={i}>{t(`categories.langs.${tag}`)}</span>
                    ))}
                </div>
            </div>
        </Link>
    )
}

const Suggest = () => {
    const { t } = useTranslation();
    const { user } = useContext(AuthContext);
    const [ suggest, setSuggest ] = useState('');

    const [ send, setSend ] = useState(false);
    const [ sending, setSending ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);

    const sendTicket = () => {
        setSending(true);
        const content = {
            "embeds": [{
                "title": "Anime sugerido",
                "description": `## ${suggest}`,
                "color": 0xF1C40F,
                "fields": [
                    {
                        "name": "Recomendado",
                        "value": suggest,
                        "inline": false
                    },
                    {
                        "name": "Usuario",
                        "value": user ? user.name : 'Sin usuario',
                        "inline": true
                    },
                    {
                        "name": "Correo",
                        "value": user ? user.email : 'Sin usuario',
                        "inline": true
                    },
                    {
                        "name": "Usuario (uid)",
                        "value": user ? user.uid : 'Sin usuario',
                        "inline": false
                    }
                ],
                "thumbnail": {
                    "url":  user ? user.photoURL : "https://cdn.discordapp.com/attachments/1117660519235604511/1128999002554249297/anime_suggest.jpg",
                    "height": 0,
                    "width:": 0
                }
            }]
        }
        fetch(process.env.REACT_APP_DISCORD_ANIME_SUGGEST_WEBHOOK,{
            method: 'POST',
            body: JSON.stringify(content),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(() => {
            setSending(false);
            setSend(true);
            
        }).catch(() => {
            setSending(false);
            setSend(false);
        })
    }

    useEffect(() => {
        if (suggest.length > 3) {
            setDisabled(false);
        } else {
            setDisabled(true)
        }
    }, [suggest])

    return (
        <div className='search__results-error'>
            {send ?
                 <div className="search__results-error-msg">
                    <span>{t('words.thanks')}</span>
                </div>
            :
            <>
                <div className="search__results-error-msg">
                    <span>{t('error.search/404-1')}</span>
                    <span>{t('error.search/404-2')}</span>
                </div>
                <Form
                    loading={sending}
                    action={sendTicket}
                    disabled={disabled}
                >
                    <Input
                        type={"text"}
                        variant={1}
                        value={suggest}
                        onChange={(e) => setSuggest(e.target.value)}
                        placeholder={true}
                        placeholderText={t('form.search.error.404.placeholder')}
                        label={true}
                        labelText={`*${t('form.search.error.404.label')}`}
                        required
                    />
                    <AoButton 
                        loading={sending}
                        variant={"special-2"}
                        title={t('words.suggest')}
                        submit={true}
                        disabled={disabled}
                    />
                </Form>
            </>
            }
        </div>
    )
}

export default function Results({ type, searching, setSearching }) {
    const { t } = useTranslation();
    const params = useParams();
    const { animes, setAnimes } = useContext(ContentListsContext);
    const [ currentAnimes, setCurrentAnimes ] = useState([]);
    const [ filtered, setFiltered ] = useState([]);
    const [ filteredOthers, setFilteredOthers ] = useState([]);
    const [ fetched, setFetched ] = useState(false);
    const windowSizeOne = useMediaQuery('(min-width: 41.5em)');
    const windowSizeTwo = useMediaQuery('(min-width: 50em)');
    const windowSizeThree = useMediaQuery('(min-width: 60em)');

    useEffect(() => {
        if (params.search) {
            const search = params.search.toLowerCase();
            let numberRelevant = 3;
            let numberOthers = 4;

            if (windowSizeThree) {
                numberRelevant = 7;
                numberOthers = 8;
            } else if (windowSizeTwo) {
                numberRelevant = 6;
                numberOthers = 7;
            } else if (windowSizeOne) {
                numberRelevant = 5;
                numberOthers = 6;
            }

            const currentFiltered = currentAnimes.filter(data => data.name.toLowerCase().includes(search))
                                                 .sort((a, b) => {
                                                    if (a.name.toLowerCase().startsWith(search) && !b.name.toLowerCase().startsWith(search)) {
                                                        return -1;
                                                    } else if (!a.name.toLowerCase().startsWith(search) && b.name.toLowerCase().startsWith(search)) {
                                                        return 1;
                                                    } else {
                                                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                                                    }
                                                 })
                                                 .slice(0, numberRelevant)

            const currentOthers = currentAnimes.filter(data => data.name_alternative && data.name_alternative.toLowerCase().includes(search))
                                                .sort((a, b) => {
                                                    if (a.name_alternative.toLowerCase().startsWith(search) && !b.name_alternative.toLowerCase().startsWith(search)) {
                                                        return -1;
                                                    } else if (!a.name_alternative.toLowerCase().startsWith(search) && b.name_alternative.toLowerCase().startsWith(search)) {
                                                        return 1;
                                                    } else {
                                                        return a.name_alternative.toLowerCase() > b.name_alternative.toLowerCase() ? 1 : -1;
                                                    }
                                                })
                                                .slice(0, numberOthers)
            if (type === "relevant") {
                setFiltered(currentFiltered);
                setFilteredOthers(currentOthers.length)
            }
            if (type === "others") {
                setFiltered(currentFiltered.length);
                setFilteredOthers(currentOthers)
            }
            setSearching(false);
        }
    }, [params.search, fetched])

    useEffect(() => {
        let ignore = false;
        let lang = getDBLang();

        function verifyData() {
            let session = sessionStorage.getItem(`anime-list`);
            if (session) {
                let data = JSON.parse(session);

                if (data.find((doc) => doc.type === lang)) {
                    setAnimes(data);
                    setCurrentAnimes(data.find((doc) => doc.type === lang).data);
                    setFetched(true);
                } else {
                    import(`../../services/firebase/anime_${lang}`)
                    .then(async (db) => {
                        const animesRef = doc(db.animeFirestore,`search/animes`);
                        const animesSnap = await getDoc(animesRef);

                        const serverData = animesSnap.data().list;

                        const formattedData = {
                            type: lang,
                            data: serverData
                        }

                        data.push(formattedData);

                        setAnimes(data);
                        setCurrentAnimes(data.find((doc) => doc.type === lang).data)
                        sessionStorage.setItem(`anime-list`, JSON.stringify(data));
                        setFetched(true);
                    })
                }
            } else {
                import(`../../services/firebase/anime_${lang}`)
                .then(async (db) => {
                    const animesRef = doc(db.animeFirestore,`search/animes`);
                    const animesSnap = await getDoc(animesRef);

                    const data = animesSnap.data().list;

                    const formattedData = [
                        {
                            type: lang,
                            data: data
                        }
                    ]

                    setAnimes(formattedData);
                    setCurrentAnimes(formattedData[0].data);
                    sessionStorage.setItem(`anime-list`, JSON.stringify(formattedData));
                    setFetched(true);
                })
            }
        }

        if (!ignore) {
            if (!fetched) {

                if (animes.length < 1) {
                    verifyData();
                } else {
                    if (animes.find((doc) => doc.type === lang)){
                        setCurrentAnimes(animes.find((doc) => doc.type === lang).data)
                        setFetched(true);
                    } else {
                        verifyData();
                    }
                }
            }
        }
        return () => ignore = true;
    }, [searching]);

    if (!fetched || searching) {
        if (type === "relevant") {
            return (
                <div className='progress' style={{marginTop: '1rem'}}>
                    <CircularProgress className='progress-circular' sx={{width: '10vmin !important', height: '10vmin !important'}} />
                </div>
            )
        }
        return;
    }

    if (type === "others" && filteredOthers.length < 1) {
        return;
    }

    if (type === "relevant") {
        if (filtered.length < 1 && filteredOthers > 0){
            return;
        }
    }
    

    return (
        <div className='search__layout'>
            <h3 className='search__layout-title'>
                {type === "others" ?
                    <>
                        {filtered < 1 ?
                            t(`results.relevant`)
                            :
                            t(`results.${type}`)
                        }
                    </>
                    :
                    t(`results.${type}`)
                }
            </h3>
            <div className={`search__results-layout ${type}`}>
                {type === "relevant" &&
                    <>
                        {filtered.length < 1 ?
                            <Suggest />
                            :
                            <div className={`search__results-body ${filtered.length < 3 ? `has-${filtered.length}` : ''}`}>
                                {filtered.map((data, i) => (
                                    <Result key={i} index={i} data={data} />
                                ))}
                            </div>
                        }
                    </>
                }
                {type === "others" &&
                    <>
                        {filteredOthers.length < 1 ?
                            null
                            :
                            <div className={`search__results-body ${filteredOthers.length < 3 ? `has-${filteredOthers.length}` : ''}`}>
                                {filteredOthers.map((data, i) => (
                                    <Result key={i} index={filtered < 1 ? i : null} data={data} />
                                ))}
                            </div>
                        }
                    </>
                }
            </div>
        </div>
    )
}
