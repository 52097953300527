import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import langEs from '../locales/es.json';
import langEn from '../locales/en.json';
import langJp from '../locales/ja.json';

const resources = {
    es: {
        translation: langEs
    },
    en: {
        translation: langEn
    },
    ja: {
        translation: langJp
    }
};

const options = {
    convertDetectedLanguage: (lng) => lng.split("-")[0]
}

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: resources,
        detection: options,
        debug: true,
        fallbackLng: 'es',
        interpolation: {
            escapeValue: false,
        },
        ns: "translation",
        defaultNS: "translation",

    });

export default i18n;