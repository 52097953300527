import React from 'react'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export default function Form({ children, disabled, type, action, title, bg, bgPlaceholder, loading, error }) {
    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (disabled) {
            return;
        } else {
            action();
        }
    }

  return (
    <div className={`form ${type}`}>
        <div className={`form-layout ${loading && 'loading'}`}>
            {error && 
                <div className="form-error">
                    {t(`error.${error}`)}
                </div>
            }
            {bg &&
                <div className="form-background">
                    <picture>
                        <source srcSet={bg} type='image' />
                        <LazyLoadImage 
                            effect='blur'
                            src={bg}
                            alt=""
                        />
                    </picture>
                </div>
            }
            
            <form onSubmit={handleSubmit} className="form-body">
                <div className="form-seo">
                    <h1>{title}</h1>
                </div>
                {children}
            </form>
        </div>
    </div>
  )
}
